import React, {useEffect, useState} from 'react';
import login_bg from '../../img/login_bg.webp'
import Login from '../../components/Login'
import {Button, Checkbox, Divider, Form, Input} from 'antd';
import {
    GoogleOutlined,
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {ToastContainer} from "react-toastify";

type RequiredMark = boolean | 'optional';

export default function LoginView() {
    const {t} = useTranslation();

    useEffect(() => {

        const searchParams = new URLSearchParams(window.location.search);
        const paramValue = searchParams.get('pay');
        const register = document.getElementById("register");
        const registerPay = document.getElementById("register_pay");

        if (register && registerPay) {
            if (paramValue) {
                console.log("vers pay");
                register.style.display = "none";
                registerPay.style.display = "";
            } else {
                register.style.display = "";
                registerPay.style.display = "none";
            }
        }
    }, []);


    const onFinish = (values: any) => {
        Login(values.email, values.password);
        // showToastMessage()
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };


    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional');

    const onRequiredTypeChange = ({requiredMarkValue}: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    }

    const colorLoginText = "#09154C"
    const colorLoginButtonBg = "#7ED957"
    const colorLoginGoogleBtnBg = "rgba(0,0,0,0.0)"
    const colorLoginGoogleBtn = "#D93025"





    return (
        <div className={"Login"} style={{
            position: 'relative',
            backgroundImage: `url(${login_bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}>
            <ToastContainer/>
            <div style={{margin: '40px auto', width: '300px', position: 'relative', color: colorLoginText}}>
                <div style={{backgroundColor: 'white', borderRadius: '10px', padding: '20px'}}>
                    <h1>{t('Se Connecter')}</h1>
                    <br/>
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{requiredMarkValue: requiredMark}}
                        onValuesChange={onRequiredTypeChange}
                        requiredMark={requiredMark}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item style={{color: colorLoginText}} label="Email" required
                                   tooltip={t('Ce champ est requis')} name="email">
                            <Input placeholder={t('Adresse Email')}/>
                        </Form.Item>
                        <Form.Item style={{color: colorLoginText}} label={t('Mot de Passe')} required
                                   tooltip={t('Ce champ est requis')} name="password">
                            <Input.Password placeholder={t('Mot de passe')} type="email"/>
                        </Form.Item>
                        <Form.Item name="remember" valuePropName="checked">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox style={{color: colorLoginText, fontSize: '12px'}}>{t('Se souvenir de moi')}</Checkbox>
                                <a style={{float: 'right', color: colorLoginText, fontSize: '12px'}} href={"forgotten-password"}><b>{t('Mot de passe oublié ?')}</b></a>
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" style={{
                                color: colorLoginText,
                                backgroundColor: colorLoginButtonBg,
                                width: '100%',
                                height: '50px'
                            }} type="primary">{t('Connexion')}</Button>
                        </Form.Item>
                    </Form>
                    <div style={{textAlign: 'center'}}>
                        <p style={{display: 'inline-block'}}>{t('Vous êtes nouveau ?')}</p>
                        <a style={{display: 'inline-block', marginLeft: '10px', color: colorLoginText,}} href={"register"} id={'register'}><b>{t('Créer un compte')}</b></a>
                        <a style={{display: 'none', marginLeft: '10px', color: colorLoginText,}} href={'register?pay=true'} id={'register_pay'}><b>{t('Créer un compte')}</b></a>
                    </div>
                    <br/>
                    {/*<Divider>ou</Divider>*/}
                    {/*<Button style={{*/}
                    {/*    border: '1px solid #D93025',*/}
                    {/*    backgroundColor: colorLoginGoogleBtnBg,*/}
                    {/*    width: '100%',*/}
                    {/*    height: '50px'*/}
                    {/*}}><GoogleOutlined style={{color: colorLoginGoogleBtn,}}/></Button>*/}
                </div>
            </div>
        </div>
    );
}

