import {Button, Card, Col, Input, MenuProps, Pagination, Rate, Row, Select, Space, Table} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {getProductsAllPublished} from "../../Products/Products";

interface Product {
    id: number;
    name: string;
    image: string;
    category: string;
    rating: number;
    price: number;
    price_promo: number;
}

const {Option} = Select;

interface Option {
    value: string | number;
    label: string;
    children?: Option[];
}

export default function Wishlist() {
    const [productsPerPage, setProductsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [productData, setProductData] = useState<Product[]>([]);
    const [dataFetched, setDataFetched] = useState(false);

    const totalPages = Math.ceil(productData.length / productsPerPage);
    type MenuItem = Required<MenuProps>['items'][number];
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleProductsPerPageChange = (value: number) => {
        setProductsPerPage(value);
        setCurrentPage(1); // Reset to the first page
    };

    function getRandomNumber(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        const fetchProductInfos = async () => {
            const products = await getProductsAllPublished(); // Assuming you have a function to fetch products
            try {
                let product_infomartions: Product[] = [];
                products.forEach((product: any) => {
                    let product_img = product.image.split("/");
                    product_img = "https://beta.captainchercheur.com/media/public/products/" + product_img[product_img.length - 1];
                    product_infomartions.push({
                        id: product.id,
                        name: product.product.product_name,
                        image: product_img,
                        category: product.category,
                        rating: getRandomNumber(3, 5),
                        price: product.product.price,
                        price_promo: product.product.price_promo,
                    });
                });
                setProductData(product_infomartions);
            } catch (error) {
                console.log("No product data found");
            }
            setDataFetched(true);
        };

        if (!dataFetched) {
            fetchProductInfos();
        }
    }, [dataFetched]);

    const startIndex = (currentPage - 1) * productsPerPage;
    const visibleProducts = productData.slice(startIndex, startIndex + productsPerPage);

    const [chunkSize, setChunkSize] = useState(5);
    useEffect(() => {
        const updateChunkSize = () => {
            if (window.innerWidth < 768) {
                setChunkSize(2);
            } else if (window.innerWidth < 1100) {
                setChunkSize(3);
            } else if (window.innerWidth < 1500) {
                setChunkSize(4);
            } else {
                setChunkSize(5);
            }
        };

        updateChunkSize(); // Call the function once to set the initial chunkSize

        // Add a listener to update the chunkSize when the window is resized
        window.addEventListener("resize", updateChunkSize);

        // Clean up the listener when the component is unmounted
        return () => {
            window.removeEventListener("resize", updateChunkSize);
        };
    }, []);

    const colorCarouselText = "#09154C"
    const colorCarouselBtn = "#626974"
    const colorPromoBg = "#7ED957"

    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{color: colorHeaderText}}>
            {windowWidth < 768 ? (
                <div
                    style={{
                        margin: '0px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#F3F5F6",
                        borderRadius: '15px'
                    }}>
                    <h1 style={{fontSize: '24px'}}>Liste de souhaits</h1>
                    {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    <div>
                        <Row style={{width: '100%', justifyContent: 'center', marginTop: '15px'}}>
                            {visibleProducts.map((product) => (
                                <Col key={product.id}
                                     style={{width: chunkSize === 2 ? "50%" : "33%", marginBottom: '10px'}}>
                                    <Card
                                        hoverable
                                        className="product-card"
                                        style={{
                                            width: chunkSize === 2 ? "125px" : "150px",
                                            maxWidth: chunkSize === 2 ? "125px" : "150px",
                                            margin: "auto",
                                            height: chunkSize === 2 ? "250px" : "250px",
                                        }}
                                    >
                                        <img
                                            src={product.image}
                                            alt={product.name}
                                            className="product-image"
                                            style={{
                                                width: chunkSize === 2 ? "75px" : "100px",
                                                height: chunkSize === 2 ? "75px" : "100px",
                                                margin: "0 auto 15px",
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        />
                                        <div className="product-name">
                                            <div>
                                                <p style={{
                                                    color: colorCarouselBtn,
                                                    fontSize: chunkSize === 2 ? "10px" : "10px"
                                                }}>{product.category}</p>
                                                <p style={{
                                                    fontSize: chunkSize === 2 ? "12px" : "12px",
                                                    height: chunkSize === 2 ? "40px" : "40px",
                                                    overflow: 'hidden',  // Add this line to hide overflowing text
                                                    display: '-webkit-box', // Use the -webkit-box display to control the number of lines
                                                    WebkitLineClamp: 2, // Set the number of lines to display
                                                    WebkitBoxOrient: 'vertical', // Set the orientation to vertical
                                                    textOverflow: 'ellipsis', // Add this line to display ellipsis (...) for overflow
                                                }}>{product.name}</p>
                                                <Row style={{height: chunkSize === 2 ? "25px" : "25px", display: "none"}}>
                                                    <Col flex={1}>
                                                        <Rate allowHalf disabled defaultValue={product.rating} style={{
                                                            fontSize: chunkSize === 2 ? "8px" : "8px",
                                                            height: chunkSize === 2 ? "25px" : "25px",
                                                            display: 'inline-block',
                                                            color: colorPromoBg
                                                        }}/>
                                                    </Col>
                                                    <Col flex={1}>
                                                        <p style={{
                                                            fontSize: '10px',
                                                            display: "none",
                                                            marginLeft: '10px',
                                                            color: colorCarouselBtn
                                                        }}>Avis clients</p>
                                                    </Col>
                                                </Row>
                                                <Row style={{height: chunkSize === 2 ? "25px" : "25px",}}>
                                                    <p style={{display: 'inline-block'}}>{product.price_promo} €</p>
                                                    <p style={{
                                                        textDecorationLine: 'line-through',
                                                        display: 'inline-block',
                                                        marginLeft: '10px',
                                                        color: colorCarouselBtn,
                                                        fontSize: '12px',
                                                        position: 'relative',

                                                        top: '2px'
                                                    }}>{product.price} €</p>
                                                </Row>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                            <div style={{
                                display: 'grid',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '10px auto',
                                width: '100%'
                            }}>
                                <Select defaultValue={productsPerPage}
                                        onChange={handleProductsPerPageChange}
                                        style={{marginBottom: '15px'}}
                                >
                                    <Option value={10}>10 produits par page</Option>
                                    <Option value={20}>20 produits par page</Option>
                                    <Option value={50}>50 produits par page</Option>
                                    <Option value={100}>100 produits par page</Option>
                                </Select>
                                <Pagination
                                    current={currentPage}
                                    total={productData.length}
                                    pageSize={productsPerPage}
                                    onChange={handlePageChange}
                                    showSizeChanger={false}
                                    size="small"
                                />
                            </div>
                        </Row>
                    </div>
                </div>
            ) : windowWidth >= 768 && windowWidth < 1100 ? (
                <div
                    style={{
                        margin: '0px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#F3F5F6",
                        borderRadius: '15px'
                    }}>
                    <div style={{margin: '15px'}}>
                        <h1 style={{fontSize: '24px'}}>Liste de souhaits</h1>
                        {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    </div>
                    <Row style={{width: '100%', justifyContent: 'center', marginTop: '15px'}}>
                        {visibleProducts.map((product) => (
                            <Col key={product.id}
                                 style={{width: chunkSize === 3 ? "33.3%" : "25%", marginBottom: '10px'}}>
                                <Card
                                    hoverable
                                    className="product-card"
                                    style={{
                                        width: chunkSize === 3 ? "150px" : "150px",
                                        maxWidth: chunkSize === 3 ? "150px" : "150px",
                                        margin: "auto",
                                        height: chunkSize === 3 ? "250px" : "250px",
                                    }}
                                >
                                    <img
                                        src={product.image}
                                        alt={product.name}
                                        className="product-image"
                                        style={{
                                            width: chunkSize === 3 ? "100px" : "100px",
                                            height: chunkSize === 3 ? "100px" : "100px",
                                            margin: "0 auto 15px",
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    />
                                    <div className="product-name">
                                        <div>
                                            <p style={{
                                                color: colorCarouselBtn,
                                                fontSize: chunkSize === 3 ? "10px" : "10px"
                                            }}>{product.category}</p>
                                            <p style={{
                                                fontSize: chunkSize === 3 ? "12px" : "12px",
                                                height: chunkSize === 3 ? "40px" : "40px",
                                                overflow: 'hidden',  // Add this line to hide overflowing text
                                                display: '-webkit-box', // Use the -webkit-box display to control the number of lines
                                                WebkitLineClamp: 2, // Set the number of lines to display
                                                WebkitBoxOrient: 'vertical', // Set the orientation to vertical
                                                textOverflow: 'ellipsis', // Add this line to display ellipsis (...) for overflow
                                            }}>{product.name}</p>
                                            <Row style={{height: chunkSize === 3 ? "25px" : "25px", display: "none"}}>
                                                <Col flex={1}>
                                                    <Rate allowHalf disabled defaultValue={product.rating} style={{
                                                        fontSize: chunkSize === 3 ? "8px" : "8px",
                                                        height: chunkSize === 3 ? "25px" : "25px",
                                                        display: 'inline-block',
                                                        color: colorPromoBg
                                                    }}/>
                                                </Col>
                                                <Col flex={1}>
                                                    <p style={{
                                                        fontSize: '10px',
                                                        display: "none",
                                                        marginLeft: '10px',
                                                        color: colorCarouselBtn
                                                    }}>Avis clients</p>
                                                </Col>
                                            </Row>
                                            <Row style={{height: chunkSize === 3 ? "25px" : "25px",}}>
                                                <p style={{display: 'inline-block'}}>{product.price_promo} €</p>
                                                <p style={{
                                                    textDecorationLine: 'line-through',
                                                    display: 'inline-block',
                                                    marginLeft: '10px',
                                                    color: colorCarouselBtn,
                                                    fontSize: '12px',
                                                    position: 'relative',

                                                    top: '2px'
                                                }}>{product.price} €</p>
                                            </Row>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '10px auto',
                            width: '100%'
                        }}>
                            <Select defaultValue={productsPerPage}
                                    onChange={handleProductsPerPageChange}
                                    style={{marginRight: '15px'}}
                            >
                                <Option value={10}>10 produits par page</Option>
                                <Option value={20}>20 produits par page</Option>
                                <Option value={50}>50 produits par page</Option>
                                <Option value={100}>100 produits par page</Option>
                            </Select>
                            <Pagination
                                current={currentPage}
                                total={productData.length}
                                pageSize={productsPerPage}
                                onChange={handlePageChange}
                                showSizeChanger={false}
                            />
                        </div>
                    </Row>
                </div>
            ) : (
                <div>
                    <div style={{margin: '15px'}}>
                        <h1 style={{fontSize: '24px'}}>Liste de souhaits</h1>
                        {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    </div>
                    <Row>
                        {visibleProducts.map((product) => (
                            <Col key={product.id} style={{width: chunkSize === 4 ? "25%" : "20%",}}>
                                <Card
                                    hoverable
                                    className="product-card"
                                    style={{
                                        width: chunkSize === 4 ? "150px" : "100%",
                                        maxWidth: chunkSize === 4 ? "150px" : "250px",
                                        margin: "0 auto 15px",
                                        height: chunkSize === 4 ? "250px" : "350px",
                                    }}
                                >
                                    <img
                                        src={product.image}
                                        alt={product.name}
                                        className="product-image"
                                        style={{
                                            width: chunkSize === 4 ? "100px" : "150px",
                                            height: chunkSize === 4 ? "100px" : "150px",
                                            objectFit: "cover",
                                            margin: "0 auto 15px",
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    />
                                    <div className="product-name">
                                        <p style={{
                                            color: colorCarouselBtn,
                                            fontSize: chunkSize === 4 ? "10px" : "14px"
                                        }}>{product.category}</p>
                                        <p style={{
                                            fontSize: chunkSize === 4 ? "12px" : "16px",
                                            height: chunkSize === 4 ? "40px" : "55px",
                                            overflow: 'hidden',  // Add this line to hide overflowing text
                                            display: '-webkit-box', // Use the -webkit-box display to control the number of lines
                                            WebkitLineClamp: 2, // Set the number of lines to display
                                            WebkitBoxOrient: 'vertical', // Set the orientation to vertical
                                            textOverflow: 'ellipsis', // Add this line to display ellipsis (...) for overflow
                                        }}>{product.name}</p>
                                        <Row style={{height: chunkSize === 4 ? "25px" : "40px",}}>
                                            <Col flex={1}>
                                                <Rate allowHalf disabled defaultValue={product.rating}
                                                      style={{
                                                          fontSize: chunkSize === 4 ? "8px" : "12px",
                                                          height: chunkSize === 4 ? "25px" : "55px",
                                                          display: 'inline-block',
                                                          color: colorPromoBg
                                                      }}/>
                                            </Col>
                                            <Col flex={1}>
                                                <p style={{
                                                    fontSize: '10px',
                                                    display: chunkSize === 4 ? "none" : "inline-block",
                                                    marginLeft: '10px',
                                                    color: colorCarouselBtn
                                                }}>Avis clients</p>
                                            </Col>
                                        </Row>
                                        <Row style={{height: chunkSize === 4 ? "25px" : "40px",}}>
                                            <p style={{display: 'inline-block'}}>{product.price_promo} €</p>
                                            <p style={{
                                                textDecorationLine: 'line-through',
                                                display: 'inline-block',
                                                marginLeft: '10px',
                                                color: colorCarouselBtn,
                                                fontSize: '12px',
                                                position: 'relative',

                                                top: '2px'
                                            }}>{product.price} €</p>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '10px auto',
                            width: '100%'
                        }}>
                            <Select defaultValue={productsPerPage}
                                    onChange={handleProductsPerPageChange}>
                                <Option value={10}>10 produits par page</Option>
                                <Option value={20}>20 produits par page</Option>
                                <Option value={50}>50 produits par page</Option>
                                <Option value={100}>100 produits par page</Option>
                            </Select>
                            <Pagination
                                current={currentPage}
                                total={productData.length}
                                pageSize={productsPerPage}
                                onChange={handlePageChange}
                                showSizeChanger={false}
                            />
                        </div>
                    </Row>
                </div>
            )
            }
        </div>
    );
}