import { Button, Checkbox, Divider, Row } from "antd";
import {useEffect, useState} from "react";

export default function CloseAccount() {
    const colorProductBtn = "#7ED957";
    const colorHeaderText = "#09154C";

    // Use useState to keep track of checkbox states
    const [checkboxValues, setCheckboxValues] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
    });

    // Function to update checkbox states
    const handleCheckboxChange = (name: string, value: boolean) => {
        setCheckboxValues({ ...checkboxValues, [name]: value });
    };

    // Check if all checkboxes are checked
    const allCheckboxesChecked = checkboxValues.checkbox1 && checkboxValues.checkbox2 && checkboxValues.checkbox3;

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{ color: colorHeaderText }}>
            {windowWidth < 768 ? (
                <div>
                    <h3>Demande de suppression de compte</h3>
                    <p style={{marginTop: '10px'}}>En acceptant les conditions de suppression de compte et cliquant sur le bouton, vous aurez 30 jours pour revenir sur votre choix et annuler la demande.</p>
                    <p style={{marginTop: '10px'}}>Dans le cas où aucune action n'est entreprise passée ces 30 jours, toutes données vous concernant seront supprimées de nos serveurs.</p>
                    <Divider />
                    <Row style={{ marginBottom: '15px' }}>
                        <Checkbox value={1} onChange={(e) => handleCheckboxChange('checkbox1', e.target.checked)}>
                            J'accepte que mon compte soit encore actif pendant 30 jours pour revenir sur ma décision.
                        </Checkbox>
                    </Row>
                    <Row style={{ marginBottom: '15px' }}>
                        <Checkbox value={2} onChange={(e) => handleCheckboxChange('checkbox2', e.target.checked)}>
                            J'accepte que toutes les données me concernant soient supprimées passé ce délai des 30 jours.
                        </Checkbox>
                    </Row>
                    <Row style={{ marginBottom: '15px' }}>
                        <Checkbox value={3} onChange={(e) => handleCheckboxChange('checkbox3', e.target.checked)}>
                            J'accepte de quitter / supprimer mon entreprise lors de la suppression de mon compte.
                        </Checkbox>
                    </Row>
                    {/* Disable the button if all checkboxes are not checked */}
                    <Button
                        disabled={!allCheckboxesChecked}
                        style={{
                            backgroundColor: allCheckboxesChecked ? colorProductBtn : "#E5E5E5",
                            color: allCheckboxesChecked ? colorHeaderText : "#BFBFBF",
                            marginLeft: '-10px'
                        }}
                    >
                        Supprimer mon compte
                    </Button>
                </div>
                ) : (
                    <div>
                        <h3>Demande de suppression de compte</h3>
                        <p style={{marginTop: '10px'}}>En acceptant les conditions de suppression de compte et cliquant sur le bouton, vous aurez 30 jours pour revenir sur votre choix et annuler la demande.</p>
                        <p style={{marginTop: '10px'}}>Dans le cas où aucune action n'est entreprise passée ces 30 jours, toutes données vous concernant seront supprimées de nos serveurs.</p>
                        <Divider />
                        <Row style={{ marginBottom: '15px' }}>
                            <Checkbox value={1} onChange={(e) => handleCheckboxChange('checkbox1', e.target.checked)}>
                                J'accepte que mon compte soit encore actif pendant 30 jours pour revenir sur ma décision.
                            </Checkbox>
                        </Row>
                        <Row style={{ marginBottom: '15px' }}>
                            <Checkbox value={2} onChange={(e) => handleCheckboxChange('checkbox2', e.target.checked)}>
                                J'accepte que toutes les données me concernant soient supprimées passé ce délai des 30 jours.
                            </Checkbox>
                        </Row>
                        <Row style={{ marginBottom: '15px' }}>
                            <Checkbox value={3} onChange={(e) => handleCheckboxChange('checkbox3', e.target.checked)}>
                                J'accepte de quitter / supprimer mon entreprise lors de la suppression de mon compte.
                            </Checkbox>
                        </Row>
                        {/* Disable the button if all checkboxes are not checked */}
                        <Button
                            disabled={!allCheckboxesChecked}
                            style={{
                                backgroundColor: allCheckboxesChecked ? colorProductBtn : "#E5E5E5",
                                color: allCheckboxesChecked ? colorHeaderText : "#BFBFBF",
                            }}
                        >
                            Supprimer mon compte
                        </Button>
                    </div>
                )
            }
        </div>
    );
}
