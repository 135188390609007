import fetchAPI from "../Fetch";
// import {GetExpeditionCart} from "../Cart";

export interface Adresse {
    id: number,
    user_id: number,
    firstname: string,
    lastname: string,
    address: string,
    additional_address: string | null,
    city: string,
    country: string,
    country_code: string,
    zipcode: string,
    phone_number: string,
    region: string,
    company_id: number | null,
    zone_id: number,
    address_type: AddressType[],
    relais : string | null
}

export interface AddressType {
    id: number,
    address_type: string
}

async function getAddresses() {
    let user_data = await fetchAPI('users/' + localStorage.getItem('user_id'), "GET", true, true);
    if (user_data.status !== 401) {
        if (user_data.response.user !== undefined) {
            user_data = user_data.response.user;
        } else {
            return null;
        }
        if(user_data.user_address.length >= 1){
            return user_data.user_address
        } else {
            return null;
        }
    }
}
async function getAddressesByUserId(user_id:number) {
    let user_data = await fetchAPI('users/' + user_id, "GET", true, true);
    if (user_data.status !== 401) {
        if (user_data.response.user !== undefined) {
            user_data = user_data.response.user;
        } else {
            return null;
        }
        if(user_data.user_address.length >= 1){
            return user_data.user_address
        } else {
            return null;
        }
    }
}

async function getUserAddress(address_id:number):Promise<Adresse> {
    const addresses = await fetchAPI('addresses/' + address_id, "GET", true, true);
    return addresses.response as Adresse
}

async function createAddress(userAddress:any) {
    // console.log(userAddress)
    // if (userAddress.societe){userAddress.address=userAddress.societe+" : "+userAddress.address}
    let createAddress = await fetchAPI('addresses', "POST", true, true, {
        address_type : [1,2],
        city: userAddress.city,
        country: userAddress.country,
        firstname: userAddress.firstname,
        lastname: userAddress.lastname,
        address : userAddress.address ,
        additional_address : userAddress.additional_address,
        zipcode: userAddress.zipcode,
        phone: userAddress.phone,
        region: userAddress.region
    })
    // console.log(await createAddress.response.id)
    // console.log(await GetExpeditionCart(await createAddress.response.id));
    return createAddress.response;
}


async function createShippingAddress(userAddress:any) {
    // console.log(userAddress)
    // if (userAddress.societe){userAddress.address=userAddress.societe+" : "+userAddress.address}
    let createAddress = await fetchAPI('addresses', "POST", true, true, {
        address_type : [3],
        city: userAddress.city,
        country: userAddress.country,
        firstname: userAddress.firstname,
        lastname: userAddress.lastname,
        address : userAddress.address ,
        additional_address : userAddress.additional_address,
        relais : userAddress.relais,
        zipcode: userAddress.zipcode,
        phone: userAddress.phone,
        region: userAddress.region
    })

    // console.log(await createAddress.response)
    // console.log(await createAddress.response.id)
    // console.log(await GetExpeditionCart(await createAddress.response.id));
    return createAddress.response;
}

async function createDeliveryAddress(userAddress:any) {
    // console.log(userAddress)
    // if (userAddress.societe){userAddress.address=userAddress.societe+" : "+userAddress.address}
    let createAddress = await fetchAPI('addresses', "POST", true, true, {
        address_type : [2],
        city: userAddress.city,
        country: userAddress.country,
        firstname: userAddress.firstname,
        lastname: userAddress.lastname,
        address : userAddress.address ,
        additional_address : userAddress.additional_address,
        zipcode: userAddress.zipcode,
        phone: userAddress.phone,
        region: userAddress.region,
        relais : userAddress.relais,
    })
    // console.log(await createAddress.response.id)
    // console.log(await GetExpeditionCart(await createAddress.response.id));
    return createAddress.response;
}


async function createBillingAddress(userAddress:any) {
    // console.log(userAddress)
    // if (userAddress.societe){userAddress.address=userAddress.societe+" : "+userAddress.address}
    let createAddress = await fetchAPI('addresses', "POST", true, true, {
        address_type : [1],
        city: userAddress.city,
        country: userAddress.country,
        firstname: userAddress.firstname,
        lastname: userAddress.lastname,
        address : userAddress.address ,
        additional_address : userAddress.additional_address,
        zipcode: userAddress.zipcode,
        phone: userAddress.phone,
        region: userAddress.region
    })
    // console.log(await createAddress.response.id)
    // console.log(await GetExpeditionCart(await createAddress.response.id));
    return createAddress.response;

}

async function modifyAddress(userAddress:any,idAddress:number) {
    // console.log(userAddress)
    let updateAddress = await fetchAPI('addresses/'+idAddress, "PUT", true, true, {
        firstname: userAddress.firstname,
        lastname: userAddress.lastname,
        address : userAddress.address ,
        additional_address : userAddress.additional_address ? userAddress.additional_address : "",
        city: userAddress.city,
        country: userAddress.country,
        zipcode: userAddress.zipcode,
        phone: userAddress.phone,
        region: userAddress.region
    })
    console.log(updateAddress.response);
    return updateAddress;

}

async function deleteAddress(idAddress:number) {

    let deleteAddress = await fetchAPI('addresses/'+idAddress, "DELETE", true, true);
    console.log(deleteAddress.response);
    return deleteAddress;

}



// TODO : Créer fields societe pour client dans adresses

export { getAddresses, createAddress, getUserAddress ,createShippingAddress,createBillingAddress,createDeliveryAddress,modifyAddress,deleteAddress,getAddressesByUserId}