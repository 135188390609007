import React, { useEffect, useState } from 'react';
import KPIPage from './KPIPage';

interface Order {
    DATE: string;
    'N° commande': number;
    NOM: string;
    EMAIL: string;
    REFERENCE: string;
    DESIGNATION: string;
    'QUANTITÉ': number;
    'PRIX DE VENTE U HT': number;
    'PRIX DE VENTE TOTAL HT': number;
    'COMMISSION HT': number;
    'TVA 20% SUR LA COMMISSION': number;
    'COMMISSION TTC': number;
    'SOLDE RECUPERABLE HT': number;
    'SOLDE RECUPERABLE TTC': number;
}

const KPI: React.FC = () => {
    const [orders, setOrders] = useState<Order[]>([]);

    // useEffect(() => {
    //     fetch('http://localhost:3000/kpi.json')
    //         .then(response => {
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             return response.json();
    //         })
    //         .then(data => {
    //             setOrders(data);
    //         })
    //         .catch(error => {
    //             console.error('There was a problem fetching the data:', error);
    //         });
    // }, []);

    return (
        <div>
            <KPIPage />
        </div>
    );
}

export default KPI;
