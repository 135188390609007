import {Card, Space, Image, Typography, Row, Col, Avatar} from 'antd';
import {
    ArrowLeftOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditOutlined,
    EllipsisOutlined, QuestionCircleOutlined, RightOutlined,
    SettingOutlined, UserOutlined
} from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { getAllOrdersByModificationDate, Order } from "../../Order";
import { getUserById, UserInterface } from "../../User";
import { getProductByID } from "../../Products/Products";
import { getExpeditionPriceById } from "../../Expedition";
import { getChronoLabelByOrderId, getInvoiceByOrderId } from "../../Media";
import { getTransactionDetails } from "../../MangoPay";
import { useLocation, useNavigate} from "react-router-dom";
const { Title, Text } = Typography;


interface dataSourceInterface {
    key: string,
    id: string,
    buyerName: string,
    create_date: string,
    update_date: string,
    state: string,
    buyerEmail: string,
    vendorName: string,
    priceTotal: number,
    productName: string,
    invoice: string[] | undefined,
    chrono: string[] | undefined,
    details: string | null,
    vendorId: number[],
    order_invoices_generated: boolean
}

export default function TransactionView() {
    const [ordersInfos, setOrdersInfos] = useState({} as Order[]);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);
    const [searchString, setSearchString] = useState("");

    const [isActive, setIsActive] = useState(true); // New state to track component activity


    useEffect(() => {
        const fetchOrdersInfos = async () => {
            const orders = await getAllOrdersByModificationDate();

            setOrdersInfos(orders);
            setDataFetched(true);
            let newOption: dataSourceInterface;
            try {
                let mappedOptions: dataSourceInterface[] = [];
                for (const order of orders) {
                    if (!isActive) continue; // Check if the component is still active

                    const buyerName = await getUserById(order.buyer_id);
                    if (buyerName) {
                        let vendorName = {} as UserInterface;
                        let vendorNames = "";
                        let productName = "";
                        let expeditionPrice = 0;
                        let priceTotal = 0;

                        let have_vdm_backorder_product = false;
                        const vendor_ids = [];
                        for (const subOrder of order.sub_orders) {
                            const product = await getProductByID(subOrder.product_id);
                            productName = product.product_name;
                            if (product.backorder && product.vendor_id === 2432) {
                                console.log("vdm avec backorder pour cette commande : ", order.id);
                                have_vdm_backorder_product = true;
                            }
                            const vendorinfo = await getUserById(product.vendor_id);
                            if (vendorinfo && !vendorNames.includes(vendorinfo.firstname + " " + vendorinfo.lastname)) {
                                vendorNames += vendorinfo.firstname + " " + vendorinfo.lastname + ", ";
                            }
                            vendor_ids.push(product.vendor_id);
                            priceTotal += subOrder.product_price_TTC;
                            expeditionPrice = await getExpeditionPriceById(subOrder.expedition_id) * 1.2;
                        }
                        if (order.promotion) {
                            priceTotal = (priceTotal) * (1 - (order.promotion.value / 100));
                        }
                        if (order.sub_orders[0].state_id !== 7) {
                            let order_invoices_generated = false;

                            let invoice = [""];
                            const invoice_result = await getInvoiceByOrderId(order.id);
                            if (invoice_result.length > 0) {
                                invoice_result.forEach(invoice_res => {
                                    invoice.push("https://beta.captainchercheur.com/media/public/invoice/" + invoice_res.id);
                                });
                            }
                            let chrono = [""];

                            const chrono_result = await getChronoLabelByOrderId(order.id);
                            if (chrono_result.length > 0) {
                                chrono_result.forEach(chrono_res => {
                                    chrono.push("https://beta.captainchercheur.com/media/public/pdf/" + chrono_res.id);
                                });
                            }

                            if (invoice_result.length > 0 && chrono_result.length > 0) {
                                order_invoices_generated = true;
                            }

                            let order_with_vdm_backorder_product = "/no";
                            if (have_vdm_backorder_product) {
                                order_with_vdm_backorder_product = "/yes";
                            }
                            const dateFormatter = new Intl.DateTimeFormat('fr-FR', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            });
                            newOption = {
                                key: order.id as unknown as string,
                                id: order.id as unknown as string + order_with_vdm_backorder_product as unknown as string,
                                buyerName: buyerName.firstname + " " + buyerName.lastname,
                                buyerEmail: buyerName.user_email,
                                create_date: dateFormatter.format(new Date(order.creation_date * 1000)),
                                update_date: dateFormatter.format(new Date(order.modification_date * 1000)),
                                state: order.sub_orders[0].state_id as unknown as string,
                                vendorName: (vendorNames as unknown as string === "Xavier CADOR, ") ? "Fairbanks" : vendorNames as unknown as string,
                                productName: productName,
                                priceTotal: (expeditionPrice + priceTotal).toFixed(2) as unknown as number,
                                invoice: invoice,
                                chrono: chrono,
                                details: await getTransactionDetails(order.id).then(transaction => {
                                    return transaction;
                                }),
                                vendorId: vendor_ids,
                                order_invoices_generated: order_invoices_generated
                            };
                            console.log(newOption.details);

                            mappedOptions.push(newOption);

                            // Update the state immediately with the new option
                            setOptions(() => [...mappedOptions]);
                        }
                    }
                }
            } catch (error) {
                console.log(error, "No user data found");
            }
        };

        if (!dataFetched && isActive) {
            fetchOrdersInfos();
        }
    }, [dataFetched, isActive]);

    const navigate = useNavigate();
    const handleNavigate = () => {
        window.location.replace("/dashboard")
    };
    return (
        <div>
            <div style={{
                padding: '22px',
                background: '#fff',
                borderRadius: '8px',
                marginBottom: '30px',
                boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)'
            }}
                 onClick={handleNavigate} >
                <Row align="middle">
                    <Col>
                        <ArrowLeftOutlined style={{fontSize: '23px', color: '#ccd2dd'}} onPointerEnterCapture={undefined}
                                       onPointerLeaveCapture={undefined}/>
                    </Col>
                    <Col flex="auto" style={{paddingLeft: '20px'}}>
                        <Title level={4} style={{marginBottom: '0', fontWeight: 'bold'}}>Mes transactions</Title>
                    </Col>
                </Row>
            </div>
            {options.map(option => (
                <Card key={option.id} style={{minWidth: 300, marginBottom: "20px"}}>

                    <p style={{fontSize: '12px', textAlign: 'left', paddingBottom: '10px'}}>Acheté
                        le {option.update_date}</p>
                    <Card.Meta
                        avatar={
                            option.details === "Success"
                                ? <CheckCircleOutlined style={{color: 'green', fontSize: '32px'}}
                                                       onPointerEnterCapture={undefined}
                                                       onPointerLeaveCapture={undefined}/>
                                : !option.details
                                    ? <QuestionCircleOutlined style={{color: 'orange', fontSize: '32px'}}
                                                              onPointerEnterCapture={undefined}
                                                              onPointerLeaveCapture={undefined}/>
                                    : <CloseCircleOutlined style={{color: 'red', fontSize: '32px'}}
                                                           onPointerEnterCapture={undefined}
                                                           onPointerLeaveCapture={undefined}/>

                        }
                        title={option.buyerName + " - " + (option.id).split("/")[0]}
                        description={
                            <>
                                <p>Buyer Email: {option.buyerEmail}</p>
                                <p>Vendor Name: {option.vendorName}</p>
                                <p>Product Name: {option.productName}</p>
                                <p>Price Total: <span style={{fontWeight: 'bold'}}>{option.priceTotal}</span></p>
                                {option.details !== "Success" && option.details && (
                                    <p>Details: {option.details}</p>
                                )}
                            </>
                        }
                    />
                </Card>
            ))}
        </div>
    );
}
