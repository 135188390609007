import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

function PopupMessages(prop:string) {
    if(prop === "user_created"){
        // return toast.success("Successfully created account!");
        return toast.success('Compte créé avec succès !');
    } else if(prop === "error"){
        // return toast.warn("One of the fields are empty or wrong, please try again");
        return toast.warn('L\'un des champs est vide ou erroné, veuillez réessayer');
    }
    else if(prop === "user_already_exists"){
        // return toast.warn("This account already exists, please login");
        return toast.warn('Ce compte existe déjà, veuillez vous connecter');
    }
    else if(prop === "passwords_dont_match"){
        // return toast.warn("The passwords do not match, please try again");
        return toast.warn('Les mots de passe ne correspondent pas, veuillez réessayer');
    }
    else if(prop === "invalid_email"){
        // return toast.warn("Email is invalid, please try again");
        return toast.warn('L\'email n\'est pas valide, veuillez réessayer');
    }
    else if(prop === "user_does_not_exists"){
        // return toast.warn("User not found, please try again");
        return toast.warn('Utilisateur introuvable, veuillez réessayer');
    }
    else if(prop === "user_logged_in"){
        // return toast.success("User logged in");
        return toast.success('Utilisateur connecté');
    }
    else if(prop === "under_18"){
        return toast.warn('Vous devez accepter les conditions pour créer un compte');
    }
    else if(prop === "Unauthorized access. L'utilisateur avec les informations d'identification fournies est introuvable"){
        // return toast.success("User logged in");
        return toast.warn('Accès non-autorisé. Vos informations d\'identification sont invalides ');
    }else {
        return toast.error(prop);
    }
}

export default PopupMessages;