import React, {useEffect, useState} from 'react';
import {Carousel, Col, Row} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTruckFast, faHeadset, faCreditCard, faShieldAlt} from '@fortawesome/free-solid-svg-icons'
import {
    SafetyCertificateOutlined
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
//
export default function SliderBottom() {
    const {t} = useTranslation();

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {   windowWidth < 450 ? (
                    <div>
                    <Carousel style={{backgroundColor: '#7ed957'}} autoplay dots={false}>
                    <div>
                        <Row style={{width: '90%', margin : 'auto', height : '100px' }}>
                            <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FontAwesomeIcon icon={faTruckFast} size={"3x"} />
                            </Col>
                            <Col style={{ margin: 'auto'}}>
                                <p style={{fontSize: '16px'}}><b>{t('Livraison')}</b></p>
                                <p style={{fontSize: '16px'}}>{t('Chronopost sous 24h – 48h')}</p>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row style={{width: '90%', margin : 'auto', height : '100px' }}>
                            <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FontAwesomeIcon icon={faShieldAlt} size={"3x"} />
                            </Col>
                            <Col style={{margin: 'auto'}}>
                                <p style={{fontSize: '16px'}}><b>{t('Sécurité')}</b></p>
                                <p style={{fontSize: '16px'}}>{t('Paiement avec 3D Secure')}</p>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row style={{width: '90%', margin : 'auto', height : '100px' }}>
                            <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FontAwesomeIcon icon={faHeadset} size={"3x"} />
                            </Col>
                            <Col  style={{margin: 'auto'}}>
                                <p style={{ fontSize: '16px'}}><b>{t('S.A.V.')}</b></p>
                                <p style={{fontSize: '16px'}}>contact@poseidonshop.fr</p>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row style={{width: '90%', margin : 'auto', height : '100px' }}>
                            <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FontAwesomeIcon icon={faCreditCard} size={"3x"} />
                            </Col>
                            <Col style={{ margin: 'auto'}}>
                                <p style={{marginBottom: '15px', fontSize: '16px'}}><b>{t('Facilité de Paiement')}</b></p>
                                <p style={{fontSize: '16px'}}>4 fois sans frais avec PayPal</p>
                            </Col>
                        </Row>
                    </div>
                </Carousel>
                    </div>
                ) : windowWidth >= 450 && windowWidth < 600 ? (
                    <div>
                    <Carousel style={{backgroundColor: '#7ed957'}} autoplay dots={false}>
                    <div>
                        <Row style={{width: '90%', margin : 'auto', height : '100px' }}>
                            <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FontAwesomeIcon icon={faTruckFast} size={"3x"} />
                            </Col>
                            <Col  style={{ margin: 'auto'}}>
                                <p style={{fontSize: '16px'}}><b>{t('Livraison')}</b></p>
                                <p style={{fontSize: '16px'}}>{t('Expédié et livré par Chronopost sous 24h – 48h')}</p>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row style={{width: '80%', margin : 'auto', height : '100px' }}>
                            <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FontAwesomeIcon icon={faShieldAlt} size={"3x"} />
                            </Col>
                            <Col  style={{margin: 'auto'}}>
                                <p style={{fontSize: '16px'}}><b>{t('Sécurité')}</b></p>
                                <p style={{fontSize: '16px'}}>{t('Paiement Sécurisé avec 3D Secure')}</p>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row style={{width: '80%', margin : 'auto', height : '100px' }}>
                            <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FontAwesomeIcon icon={faHeadset} size={"3x"} />
                            </Col>
                            <Col  style={{margin: 'auto'}}>
                                <p style={{ fontSize: '16px'}}><b>{t('S.A.V.')}</b></p>
                                <p style={{fontSize: '16px'}}>contact@poseidonshop.fr</p>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row style={{width: '80%', margin : 'auto', height : '100px' }}>
                            <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <FontAwesomeIcon icon={faCreditCard} size={"3x"} />
                            </Col>
                            <Col  style={{ margin: 'auto'}}>
                                <p style={{marginBottom: '15px', fontSize: '16px'}}><b>{t('Facilité de Paiement')}</b></p>
                                <p style={{fontSize: '16px'}}>Payez en 4 fois sans frais avec PayPal</p>
                            </Col>
                        </Row>
                    </div>
                </Carousel>
            </div>
                ) : windowWidth >= 600 && windowWidth < 750 ? (
                    <div>
                    <Carousel style={{backgroundColor: '#7ed957'}} autoplay dots={false}>
                        <div>
                            <Row style={{width: '90%', margin : 'auto', height : '100px' }}>
                                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={faTruckFast} size={"3x"} />
                                </Col>
                                <Col  style={{ margin: 'auto'}}>
                                    <p style={{fontSize: '16px'}}><b>{t('Livraison')}</b></p>
                                    <p style={{fontSize: '16px'}}>{t('Expédié et livré par Chronopost sous 24h – 48h')}</p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row style={{width: '65%', margin : 'auto', height : '100px' }}>
                                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={faShieldAlt} size={"3x"} />
                                </Col>
                                <Col  style={{margin: 'auto'}}>
                                    <p style={{fontSize: '16px'}}><b>{t('Sécurité')}</b></p>
                                    <p style={{fontSize: '16px'}}>{t('Paiement Sécurisé avec 3D Secure')}</p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row style={{width: '65%', margin : 'auto', height : '100px' }}>
                                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={faHeadset} size={"3x"} />
                                </Col>
                                <Col  style={{margin: 'auto'}}>
                                    <p style={{ fontSize: '16px'}}><b>{t('S.A.V.')}</b></p>
                                    <p style={{fontSize: '16px'}}>contact@poseidonshop.fr</p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row style={{width: '65%', margin : 'auto', height : '100px' }}>
                                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={faCreditCard} size={"3x"} />
                                </Col>
                                <Col  style={{ margin: 'auto'}}>
                                    <p style={{marginBottom: '15px', fontSize: '16px'}}><b>{t('Facilité de Paiement')}</b></p>
                                    <p style={{fontSize: '16px'}}>Payez en 4 fois sans frais avec PayPal</p>
                                </Col>
                            </Row>
                        </div>
                    </Carousel>
                </div>
                ) : windowWidth >= 750 && windowWidth < 900 ? (
                    <div>
                        <Carousel style={{backgroundColor: '#7ed957'}} autoplay dots={false}>
                            <div>
                                <Row style={{width: '65%', margin : 'auto', height : '100px' }}>
                                    <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <FontAwesomeIcon icon={faTruckFast} size={"3x"} />
                                    </Col>
                                    <Col  style={{ margin: 'auto'}}>
                                        <p style={{fontSize: '16px'}}><b>{t('Livraison')}</b></p>
                                        <p style={{fontSize: '16px'}}>{t('Expédié et livré par Chronopost sous 24h – 48h')}</p>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row style={{width: '50%', margin : 'auto', height : '100px' }}>
                                    <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <FontAwesomeIcon icon={faShieldAlt} size={"3x"} />
                                    </Col>
                                    <Col  style={{margin: 'auto'}}>
                                        <p style={{fontSize: '16px'}}><b>{t('Sécurité')}</b></p>
                                        <p style={{fontSize: '16px'}}>{t('Paiement Sécurisé avec 3D Secure')}</p>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row style={{width: '50%', margin : 'auto', height : '100px' }}>
                                    <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <FontAwesomeIcon icon={faHeadset} size={"3x"} />
                                    </Col>
                                    <Col  style={{margin: 'auto'}}>
                                        <p style={{ fontSize: '16px'}}><b>{t('S.A.V.')}</b></p>
                                        <p style={{fontSize: '16px'}}>contact@poseidonshop.fr</p>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row style={{width: '50%', margin : 'auto', height : '100px' }}>
                                    <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <FontAwesomeIcon icon={faCreditCard} size={"3x"} />
                                    </Col>
                                    <Col  style={{ margin: 'auto'}}>
                                        <p style={{marginBottom: '15px', fontSize: '16px'}}><b>{t('Facilité de Paiement')}</b></p>
                                        <p style={{fontSize: '16px'}}>Payez en 4 fois sans frais avec PayPal</p>
                                    </Col>
                                </Row>
                            </div>
                        </Carousel>
                    </div>
                ) : windowWidth >= 900 && windowWidth <1100 ? (
                    <div>
                    <Carousel style={{backgroundColor: '#7ed957'}} autoplay dots={false}>
                        <div>
                            <Row style={{width: '50%', margin : 'auto', height : '100px' }}>
                                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={faTruckFast} size={"3x"} />
                                </Col>
                                <Col  style={{ margin: 'auto'}}>
                                    <p style={{fontSize: '16px'}}><b>{t('Livraison')}</b></p>
                                    <p style={{fontSize: '16px'}}>{t('Expédié et livré par Chronopost sous 24h – 48h')}</p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row style={{width: '50%', margin : 'auto', height : '100px' }}>
                                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={faShieldAlt} size={"3x"} />
                                </Col>
                                <Col  style={{margin: 'auto'}}>
                                    <p style={{fontSize: '16px'}}><b>{t('Sécurité')}</b></p>
                                    <p style={{fontSize: '16px'}}>{t('Paiement Sécurisé avec 3D Secure')}</p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row style={{width: '50%', margin : 'auto', height : '100px' }}>
                                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={faHeadset} size={"3x"} />
                                </Col>
                                <Col  style={{margin: 'auto'}}>
                                    <p style={{ fontSize: '16px'}}><b>{t('S.A.V.')}</b></p>
                                    <p style={{fontSize: '16px'}}>contact@poseidonshop.fr</p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row style={{width: '50%', margin : 'auto', height : '100px' }}>
                                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={faCreditCard} size={"3x"} />
                                </Col>
                                <Col  style={{ margin: 'auto'}}>
                                    <p style={{marginBottom: '15px', fontSize: '16px'}}><b>{t('Facilité de Paiement')}</b></p>
                                    <p style={{fontSize: '16px'}}>Payez en 4 fois sans frais avec PayPal</p>
                                </Col>
                            </Row>
                        </div>
                    </Carousel>
                </div>
                ) : (
                    <div>
                        <Carousel style={{backgroundColor: '#7ed957'}} autoplay dots={false}>
                        <div>
                            <Row style={{width: '50%', margin : 'auto', height : '100px' }}>
                                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={faTruckFast} size={"3x"} />
                                </Col>
                                <Col  style={{ margin: 'auto'}}>
                                    <p style={{fontSize: '16px'}}><b>{t('Livraison')}</b></p>
                                    <p style={{fontSize: '16px'}}>{t('Expédié et livré par Chronopost sous 24h – 48h')}</p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row style={{width: '35%', margin : 'auto', height : '100px' }}>
                                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={faShieldAlt} size={"3x"} />
                                </Col>
                                <Col  style={{margin: 'auto'}}>
                                    <p style={{fontSize: '16px'}}><b>{t('Sécurité')}</b></p>
                                    <p style={{fontSize: '16px'}}>{t('Paiement Sécurisé avec 3D Secure')}</p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row style={{width: '35%', margin : 'auto', height : '100px' }}>
                                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={faHeadset} size={"3x"} />
                                </Col>
                                <Col  style={{margin: 'auto'}}>
                                    <p style={{ fontSize: '16px'}}><b>{t('S.A.V.')}</b></p>
                                    <p style={{fontSize: '16px'}}>contact@poseidonshop.fr</p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row style={{width: '35%', margin : 'auto', height : '100px' }}>
                                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <FontAwesomeIcon icon={faCreditCard} size={"3x"} />
                                </Col>
                                <Col  style={{ margin: 'auto'}}>
                                    <p style={{marginBottom: '15px', fontSize: '16px'}}><b>{t('Facilité de Paiement')}</b></p>
                                    <p style={{fontSize: '16px'}}>Payez en 4 fois sans frais avec PayPal</p>
                                </Col>
                            </Row>
                        </div>
                    </Carousel>
                    </div>
                )
            }

        </div>
    )
}