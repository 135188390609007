import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

// Lazy-loaded components
const HomeView = React.lazy(() => import('./pages/Home'));
const LoginView = React.lazy(() => import('./pages/Connexion/login'));
const RegisterView = React.lazy(() => import('./pages/Connexion/register'));
const ForgottenPasswordView = React.lazy(() => import('./pages/Connexion/forgotten-password'));
const Client = React.lazy(() => import('./pages/Dashboard/Client'));
const ProductsDisplay = React.lazy(() => import('./pages/Shop'));
const UniqueView = React.lazy(() => import('./pages/Shop/unique'));
const CartView = React.lazy(() => import('./pages/Cart/cart'));
const InfoView = React.lazy(() => import('./pages/Cart/infos'));
const ValidatedView = React.lazy(() => import('./pages/Cart/validated'));
const DeliveryView = React.lazy(() => import('./pages/Cart/delivery'));
const Logout = React.lazy(() => import('./components/Misc/Logout'));
const ImportInfos = React.lazy(() => import('./pages/importInfos'));
const Contact = React.lazy(() => import('./components/Misc/Contact'));
const LegalMentions = React.lazy(() => import('./components/Misc/LegalMentions'));
const CGU = React.lazy(() => import('./components/Misc/CGU'));
const CGV = React.lazy(() => import('./components/Misc/CGV'));
const Confidentialite = React.lazy(() => import('./components/Misc/Confidentialite'));
const Page404 = React.lazy(() => import('./components/Misc/404'));
const XML = React.lazy(() => import('./pages/GoogleMerchant'));
const UniqueOrderAdmin = React.lazy(() => import('./components/Dashboard/Admin/uniqueOrder'));
const UniqueOrderVendor = React.lazy(() => import('./components/Dashboard/Vendor/uniqueOrderVendor'));
const ViewOrder = React.lazy(() => import('./components/Dashboard/Vendor/viewOrder'));
const ViewInfosmyOrder = React.lazy(() => import('./components/Dashboard/Client/myOrderInfos'));
const UnsubscribeView = React.lazy(() => import('./components/Misc/unsubscribe'));

const Router: React.FC = () => {
        // Setting affiliate code if present in URL
        const urlParams = new URLSearchParams(window.location.search);
        const affiliate = urlParams.get("affiliate");
        if (affiliate) {
                localStorage.setItem('promotionCode', affiliate);
        }

        return (
            <Routes>
                    <Route path="/" element={<Suspense fallback={<div></div>}><HomeView /></Suspense>} />
                    <Route path="/login" element={<Suspense fallback={<div></div>}><LoginView /></Suspense>} />
                    <Route path="/register" element={<Suspense fallback={<div></div>}><RegisterView /></Suspense>} />
                    <Route path="/forgotten-password" element={<Suspense fallback={<div></div>}><ForgottenPasswordView /></Suspense>} />
                    <Route path="/dashboard" element={<Suspense fallback={<div></div>}><Client /></Suspense>} />
                    <Route path="/shop" element={<Suspense fallback={<div></div>}><ProductsDisplay /></Suspense>} />
                    <Route path="/shop/:slug" element={<Suspense fallback={<div></div>}><UniqueView /></Suspense>} />
                    <Route path="/boutique-2" element={<Suspense fallback={<div></div>}><ProductsDisplay /></Suspense>} />
                    <Route path="/boutique" element={<Suspense fallback={<div></div>}><ProductsDisplay /></Suspense>} />
                    <Route path="/cart" element={<Suspense fallback={<div></div>}><CartView /></Suspense>} />
                    <Route path="/pay" element={<Suspense fallback={<div></div>}><InfoView /></Suspense>} />
                    <Route path="/validated" element={<Suspense fallback={<div></div>}><ValidatedView /></Suspense>} />
                    <Route path="/delivery" element={<Suspense fallback={<div></div>}><DeliveryView /></Suspense>} />
                    <Route path="/product/:slug" element={<Suspense fallback={<div></div>}><UniqueView /></Suspense>} />
                    <Route path="/boutique/:slug" element={<Suspense fallback={<div></div>}><UniqueView /></Suspense>} />
                    <Route path="/logout" element={<Suspense fallback={<div></div>}><Logout /></Suspense>} />
                    <Route path="/importClient" element={<Suspense fallback={<div></div>}><ImportInfos /></Suspense>} />
                    <Route path="/contact" element={<Suspense fallback={<div></div>}><Contact /></Suspense>} />
                    <Route path="/mentions-legales" element={<Suspense fallback={<div></div>}><LegalMentions /></Suspense>} />
                    <Route path="/cgv" element={<Suspense fallback={<div></div>}><CGU /></Suspense>} />
                    <Route path="/cgu" element={<Suspense fallback={<div></div>}><CGV /></Suspense>} />
                    <Route path="/politique-de-confidentialite" element={<Suspense fallback={<div></div>}><Confidentialite /></Suspense>} />
                    <Route path="/xml" element={<Suspense fallback={<div></div>}><XML /></Suspense>} />
                    <Route path="/uniqueorder/:order_id" element={<Suspense fallback={<div></div>}><UniqueOrderAdmin /></Suspense>} />
                    <Route path="/uniqueordervendor/:order_id" element={<Suspense fallback={<div></div>}><UniqueOrderVendor /></Suspense>} />
                    <Route path="/viewOrder/:order_id" element={<Suspense fallback={<div></div>}><ViewOrder /></Suspense>} />
                    <Route path="/viewOrderInfos/:order_id" element={<Suspense fallback={<div></div>}><ViewInfosmyOrder /></Suspense>} />
                    <Route path="/UnsubscribeView/:email" element={<Suspense fallback={<div></div>}><UnsubscribeView /></Suspense>} />
                    <Route path="*" element={<Suspense fallback={<div></div>}><Page404 /></Suspense>} />
            </Routes>
        );
};

export default Router;
