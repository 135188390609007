import fetchAPI from "../Fetch";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export default async function ForgottenPassword(user_email: string) {
    let get_token = await (fetchAPI(`users/sendpassword/` + user_email, 'POST', false, true));

    if (get_token.status === 200) {
        toast.success('Vous allez recevoir un mail pour changer votre mot de passe');
    } else {
        toast.error('Une erreur est survenue, veuillez réessayer plus tard');

    }

}
export async function ResetPassword(access_token: string, user_password: string) {
    let get_token = await (fetchAPI(`users/password/`+ access_token, 'POST', false, true, {
        user_pass: user_password
    }));

    if (get_token.status === 200) {
        toast.success('Mot de passe changé avec succès');
    } else {
        toast.error('Une erreur est survenue, veuillez réessayer plus tard');

    }
}


export async function ResetPasswordWithOld(user_id: number, old_user_password : string , user_password: string) {
    let reset_password = await (fetchAPI(`users/update_password`, 'POST', false, true, {
        user_id : user_id,
        old_user_pass : old_user_password,
        user_pass: user_password
    }));

    console.log("response",reset_password)

    if (reset_password.status === 200) {
        toast.success('Mot de passe changé avec succès');
    }

    else {
        toast.error('Une erreur est survenue, veuillez réessayer plus tard');

    }

}