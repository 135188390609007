import {Button, Col, Input, Row, Select, Space, Table, Pagination, InputNumber} from "antd";
import React, {useEffect, useState} from "react";
import {EditOutlined, SearchOutlined, DeleteOutlined} from "@ant-design/icons";
import {getUserById, User, UserInterface} from "../../User";
import {getProductByID, getVendorProduct, GlobalProduct, ProductInterface} from "../../Products/Products";
import {deleteOrder, getMyOrders, getUserOrders, Order} from "../../Order";
import {getExpeditionPriceById} from "../../Expedition";
import {useTranslation} from "react-i18next";

interface dataSourceInterface {
    key: string,
    id: string,
    date: string,
    state:string,
    priceTotal: number,
    productName: string
}

export default function Commands_does() {
    const {t} = useTranslation();
    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"

    const [ordersInfos, setOrdersInfos] = useState({} as Order[]);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);

    useEffect(() => {
        const fetchOrdersInfos = async () => {
            const orders = await getMyOrders();
            console.log(orders)
            setOrdersInfos(orders)
            setDataFetched(true);
            let newOption: dataSourceInterface;
            try {
                let mappedOptions:dataSourceInterface[] = [];
                for (const order of orders) {

                    let productName = ""
                    let expeditionPrice = 0
                    let priceTotal = 0;
                    for (const subOrder of order.sub_orders) {
                        const product = await getProductByID(subOrder.product_id)
                        productName = product.product_name
                        priceTotal += subOrder.product_price_TTC
                        expeditionPrice = await getExpeditionPriceById(subOrder.expedition_id) * 1.2
                    }
                    if (order.sub_orders[0].state_id !== 7) {
                        newOption = {
                            key: order.id as unknown as string,
                            id: order.id as unknown as string,

                            date: new Date(order.creation_date * 1000).toDateString(),
                            state: order.sub_orders[0].state_id as unknown as string,
                            productName: productName,
                            priceTotal: (expeditionPrice + priceTotal).toFixed(2) as unknown as number,
                        };
                        mappedOptions.push(newOption);

                        // Update the state immediately with the new option
                        setOptions(() => [...mappedOptions]);

                    }

                }
            } catch (error) {
                console.log(error , "No user data found");
            }
        };
        if (!dataFetched) {
            fetchOrdersInfos();
        }
    }, [dataFetched]);

    const onChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    const dataSource = options

    const columns = [
        {
            title: t('ID'),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: t('Produit(s) commandé(s)'),
            dataIndex: 'productName',
            key: 'productName',
            width: '15%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text+"..."}</span>
        },
        {
            title: t('Date'),
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            sorter: (a: dataSourceInterface, b: dataSourceInterface) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA.getTime() - dateB.getTime();
            },
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: t('Status'),
            dataIndex: 'state',
            key: 'state',
            width: '15%',
            render: (text: any, record: any) => {
                if (record.state === 1) {
                    return <span style={{
                        color: '#00A3FF',
                        backgroundColor: '#F1FAFF',
                        padding: '5px',
                        border: '1px solid #DCF2E6',
                        borderRadius: '5px'
                    }}>{t('En attente de paiement')}</span>;
                } else if (record.state === 2) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Paiement réussi')}</span>;
                } else if (record.state === 3) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('En cours de préparation chez le vendeur')}</span>;
                } else if (record.state === 8) {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Paiement refusé')}</span>;
                } else {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{text}</span>;
                }
            },
        },

        {
            title: t('Total'),
            dataIndex: 'priceTotal',
            key: 'priceTotal',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: t('Action'),
            key: 'action',
            width: '10%',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    <Button style={{margin: '5px',backgroundColor: colorProductBtn, color: colorHeaderText}}  onClick={() => {window.location.pathname = "/viewOrderInfos/"+record.id}}>
                        {t('Détails')}
                    </Button>
                </div>
            )
        },
    ];


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const totalItems = dataSource.length;
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;

    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (current: any, size: any) => {
        setItemsPerPage(size);
    };

    const customPaginationStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };


    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{color: colorHeaderText}}>
            {windowWidth < 768 ? (
                <div
                    style={{
                        margin: '0px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#F3F5F6",
                        borderRadius: '15px'
                    }}>
                    <h1 style={{fontSize: '24px'}}>{t('Mes Achats')}</h1>
                    {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    <div>
                        <h3 style={{
                            marginTop: '15px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            textAlign: 'center'
                        }}>Pour garantir une expérience agréable lors de l'utilisation de nos services, nous ne pouvons
                            afficher ces informations sur votre appareil.</h3>
                        <h3 style={{
                            marginTop: '15px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            textAlign: 'center'
                        }}>Nous nous excusons pour la gêne occasionnée.</h3>
                    </div>
                </div>
            ) : windowWidth >= 768 && windowWidth < 1100 ? (
                <div
                    style={{
                        margin: '0px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#F3F5F6",
                        borderRadius: '15px'
                    }}>
                    <div style={{margin: '15px'}}>
                        <h1 style={{fontSize: '24px'}}>{t('Mes Achats')}</h1>
                        {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    </div>
                    <div style={{
                        marginTop: '20px',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #FFFFFF',
                        borderRadius: '5px',
                        width: '95%',
                        margin: '20px auto'
                    }}>
                        <div style={{padding: '20px'}}>
                            <Row>
                                <Col flex={2}>
                                    <Space.Compact style={{width: '95%'}}>
                                        <Input name='search' placeholder="Rechercher" style={{width: '100%'}}/>
                                        <Button type="primary" style={{
                                            color: colorHeaderText, backgroundColor:
                                            colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                        }}><SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                    </Space.Compact>
                                </Col>
                                <Col flex={2}>
                                    <Select
                                        showSearch
                                        placeholder="Statut"
                                        optionFilterProp="children"
                                        style={{width: '95%'}}
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        // filterOption={(input, option) =>
                                        //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        // }
                                        // options={[
                                        //     {
                                        //         value: '1',
                                        //         label: 'Statut 1',
                                        //         name: 'status1'
                                        //     },
                                        //     {
                                        //         value: '2',
                                        //         label: 'Statut 2',
                                        //         name: 'status2'
                                        //     },
                                        //     {
                                        //         value: '3',
                                        //         label: 'Statut 3',
                                        //         name: 'status3'
                                        //     },
                                        // ]}
                                    />
                                </Col>
                                {/*<Col flex={1} style={{justifyContent: 'end', marginRight: '20px', display: 'flex'}}>*/}
                                {/*    <Button style={{backgroundColor: colorHearderSearch, color: colorHeaderText}}>*/}
                                {/*        Exporter*/}
                                {/*    </Button>*/}
                                {/*</Col>*/}
                                {/*<Col flex={1} style={{display: 'contents'}}>*/}
                                {/*    <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}>*/}
                                {/*        Nouvelle Commande*/}
                                {/*    </Button>*/}
                                {/*</Col>*/}
                            </Row>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <style>
                                {`
                          .custom-table tbody {
                            height: 20px;
                            overflow-y: auto;
                          }
                          
                          /* Remove default padding on the table and related elements */
                          .custom-table .ant-table-thead tr th,
                          .custom-table .ant-table-tbody tr td {
                            padding: 5px;
                          }
                        `}
                            </style>
                            <Table
                                dataSource={dataSource.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
                                columns={columns}
                                bordered
                                pagination={false}
                                className="custom-table"
                                style={{margin: '0 20px'}}
                                scroll={{y: 500}} // Set the desired height here (e.g., 400px)
                            />
                        </div>
                        <div style={{margin: '20px'}}>
                            <style>
                                {`
                          .customPaginationStyle {
                             display: flex;
                             justify-content: center;
                             align-items: center;
                          }
                          
                          .customPaginationStyle : hover {
                             background-color : #000000
                          }
                        `}
                            </style>
                            <Pagination
                                total={totalItems}
                                current={currentPage}
                                showSizeChanger
                                onShowSizeChange={handleItemsPerPageChange}
                                onChange={handlePageChange}
                                showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} produits`}
                                pageSize={itemsPerPage}
                                defaultCurrent={1}
                                style={customPaginationStyle}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div style={{margin: '15px'}}>
                        <h1 style={{fontSize: '24px'}}>{t('Mes Achats')}</h1>
                        {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    </div>
                    <div style={{
                        marginTop: '20px',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #FFFFFF',
                        borderRadius: '5px',
                        width: '95%',
                        margin: '20px auto'
                    }}>
                        <div style={{padding: '20px'}}>
                            <Row>
                                <Col flex={2}>
                                    <Space.Compact style={{width: '95%'}}>
                                        <Input name='search' placeholder="Rechercher" style={{width: '100%'}}/>
                                        <Button type="primary" style={{
                                            color: colorHeaderText, backgroundColor:
                                            colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                        }}><SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                    </Space.Compact>
                                </Col>
                                <Col flex={2}>
                                    <Select
                                        showSearch
                                        placeholder="Statut"
                                        optionFilterProp="children"
                                        style={{width: '95%'}}
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        // filterOption={(input, option) =>
                                        //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        // }
                                        // options={[
                                        //     {
                                        //         value: '1',
                                        //         label: 'Statut 1',
                                        //         name: 'status1'
                                        //     },
                                        //     {
                                        //         value: '2',
                                        //         label: 'Statut 2',
                                        //         name: 'status2'
                                        //     },
                                        //     {
                                        //         value: '3',
                                        //         label: 'Statut 3',
                                        //         name: 'status3'
                                        //     },
                                        // ]}
                                    />
                                </Col>
                                {/*<Col flex={1} style={{justifyContent: 'end', marginRight: '20px', display: 'flex'}}>*/}
                                {/*    <Button style={{backgroundColor: colorHearderSearch, color: colorHeaderText}}>*/}
                                {/*        Exporter*/}
                                {/*    </Button>*/}
                                {/*</Col>*/}
                                {/*<Col flex={1} style={{display: 'contents'}}>*/}
                                {/*    <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}>*/}
                                {/*        Nouvelle Commande*/}
                                {/*    </Button>*/}
                                {/*</Col>*/}
                            </Row>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <style>
                                {`
                          .custom-table tbody {
                            height: 20px;
                            overflow-y: auto;
                          }
                          
                          /* Remove default padding on the table and related elements */
                          .custom-table .ant-table-thead tr th,
                          .custom-table .ant-table-tbody tr td {
                            padding: 5px;
                          }
                        `}
                            </style>
                            <Table
                                dataSource={dataSource.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
                                columns={columns}
                                bordered
                                pagination={false}
                                className="custom-table"
                                style={{margin: '0 20px'}}
                                scroll={{y: 500}} // Set the desired height here (e.g., 400px)
                            />
                        </div>
                        <div style={{margin: '20px'}}>
                            <style>
                                {`
                          .customPaginationStyle {
                             display: flex;
                             justify-content: center;
                             align-items: center;
                          }
                          
                          .customPaginationStyle : hover {
                             background-color : #000000
                          }
                        `}
                            </style>
                            <Pagination
                                total={totalItems}
                                current={currentPage}
                                showSizeChanger
                                onShowSizeChange={handleItemsPerPageChange}
                                onChange={handlePageChange}
                                showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} produits`}
                                pageSize={itemsPerPage}
                                defaultCurrent={1}
                                style={customPaginationStyle}
                            />
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    );
}