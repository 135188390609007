import fetchAPI from "../Fetch";
import Registration from "../Register";
import {toast} from "react-toastify";
import {pay} from "../../pages/Cart/pay";

export interface UserInterface {
    birthdate: number;
    firstname: string;
    lastname: string;
    user_id: number;
    user_email: string;
    user_addresses: [UserAddress];
    MangoPayUser: MangoPayUser[];
}

export interface UserInterfaceRayan {
    birthdate: number;
    firstname: string;
    lastname: string;
    user_id: number;
    user_email: string;
    user_addresses: [UserAddress];
    roles: [Roles]
}

export interface UpdateUserInfosInterfaceRayan {
    birthdate: number;
    firstname: string;
    lastname: string;
    user_id: number;
    user_email: string;
}

export interface Roles {
    id: number;
    label: string;
}

export interface MangoPayUser {
    mangopay_id: string,
    wallet_id: string
}

export interface UserAddress {
    id: number;
    address: string;
    additional_address: string | null;
    city: string;
    country: string;
    country_code: string;
    zipcode: string;
    phone_number: string;
    zone_id: number;
    company_id: number | null;
    zone: string | number | null;
    company: string | number | null;
    address_type: AddressType[];
    region: string;
    firstname: string;
    lastname: string;
    relais: string | number | null
}

export interface AddressType {
    id: number;
    address_type: string;
}

function filterAddress(address: any): UserAddress {
    return {
        id: address.id,
        address: address.address,
        additional_address: address.additional_address,
        city: address.city,
        country: address.country,
        country_code: address.country_code,
        zipcode: address.zipcode,
        phone_number: address.phone_number,
        zone_id: address.zone_id,
        company_id: address.company_id,
        zone: address.zone,
        company: address.company,
        address_type: address.address_type,
        region: address.region,
        firstname: address.firstname,
        lastname: address.lastname,
        relais: address.relais
    };
}

export interface VendorInterface {

    id: number,
    user_email: string,
    user_registered: boolean,
    firstname: string,
    lastname: string,
    birthdate: number,
    deleting: number | null,
    company_id: number | null

}

async function User(): Promise<Promise<UserInterface> | null> {

    let user_data = await fetchAPI('users/' + localStorage.getItem('user_id'), "GET", true, true);
    // console.log(localStorage)
    if (user_data.status !== 401) {
        if (user_data.response.user !== undefined) {
            user_data = user_data.response.user;
        } else {
            return null;
        }
        return {
            birthdate: user_data.birthdate,
            firstname: user_data.firstname,
            lastname: user_data.lastname,
            user_id: user_data.id,
            user_email: user_data.user_email,
            user_addresses: user_data.user_address.map(filterAddress),
            MangoPayUser: user_data.MangoPayUser
        }
    } else {
        return null;
    }
}

async function UserRayan(): Promise<Promise<UpdateUserInfosInterfaceRayan> | null> {

    let user_data = await fetchAPI('users/' + localStorage.getItem('user_id'), "GET", true, true);
    if (user_data.status !== 401) {
        if (user_data.response.user !== undefined) {
            user_data = user_data.response.user;
        } else {
            return null;
        }
        return {
            birthdate: user_data.birthdate,
            firstname: user_data.firstname,
            lastname: user_data.lastname,
            user_id: user_data.id,
            user_email: user_data.user_email
        }
    } else {
        return null;
    }
}

async function SendUserGoogleID(ga_session_id: string, user_id: number) {

    let user_data = await fetchAPI('auth/ga_session_id', "POST", false, true, {
        ga_session_id: String(ga_session_id),
        user_id: user_id
    });
     console.log(user_data)
}

function generateRandomPassword(length: number): string {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }

    return password;
}

async function createUserFromWordpress() {
    const options = {method: 'GET'};

    const response = await fetch('https://pro.captainchercheur.com/Functions/json_client_info.php', options)
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(err => console.error(err));

    response.forEach((clientData: any) => {
        Registration(clientData.billing_first_name, clientData.billing_last_name, 147003234, clientData.billing_email.toLowerCase(), generateRandomPassword(10))
        // createAddress({
        //     address_type : [1],
        //     city: clientData.city,
        //     country: "France",
        //     firstname: clientData.billing_first_name,
        //     lastname: clientData.billing_last_name,
        //     address : clientData.billing_address_1,
        //     zipcode: clientData.billing_postcode,
        //     phone: clientData.billing_postcode,
        //     region: clientData.region
        // })
    })
    return await response
}

async function createVendorFromWordpress() {
    const options = {method: 'GET'};

    const response = await fetch('https://pro.captainchercheur.com/Functions/json_vendors.php', options)
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(err => console.error(err));

    console.log(response);
    for (const vendorId in response) {
        const vendorData = response[vendorId];
        if (vendorData.first_name === "" || vendorData.last_name === "") {
            const name = vendorData.email.split('@')
            vendorData.first_name = name[0]
            vendorData.last_name = name[0]
        }
        Registration(vendorData.first_name, vendorData.last_name, 147003234, vendorData.email.toLowerCase(), generateRandomPassword(10))
        console.log(vendorData)
    }
    return await response
}

async function getUserRole() {
    let user_data = await fetchAPI('users/' + localStorage.getItem('user_id') + "/roles", "GET", true, true);
    if (user_data.status === 200) {
        let roles: any = []

        user_data.response.userRoles.map((role: any) => {
            roles.push(role.id)
        })
        if (user_data.response.userRoles.length > 0) {
            if (roles.includes(1)) {
                return "admin"
            }
            return "vendor"
        } else {
            return "buyer"
        }
    }
    return "aled"
}

async function getUserById(id: number) {
    const user_infos = await fetchAPI('users/' + id, "GET", true, true);
    return user_infos.response && user_infos.response.user ? user_infos.response.user as UserInterface : false;
}

async function getUserByIdcopy(id: number) {
    const user_infos = await fetchAPI('users/' + id, "GET", true, true)
    if (user_infos !== 404) {
        return user_infos.response.user as UserInterface
    }
    return false
}


async function getUserById_Test_R(id: number) {
    const user_infos = await fetchAPI('users/' + id, "GET", true, true);
    return user_infos.response && user_infos.response.user ? user_infos.response.user as UserInterfaceRayan : false;
}

async function getAllUsers() {
    const user_infos = await fetchAPI('users/', "GET", true, true)
    return user_infos.response
}

async function getUserMangoWallet(wallet_id: number) {
    const wallet_infos = await fetchAPI('mangopay/users/wallets/' + wallet_id, "GET", true, true)
    console.log(wallet_infos)
    if (wallet_infos.status === 200) {
        return wallet_infos.response.wallets.wallets.Balance.Amount / 100
    }
    return 0
}

async function getUserWallet(user_id: number) {
    const wallet_infos = await fetchAPI('users/' + user_id + '/wallets', "GET", true, true)
    console.log(wallet_infos)
    if (wallet_infos.status === 200) {
        return wallet_infos.response.wallets.id
    }
    return 0
}

async function payout(vendor_id: number, amount: number) {
    const wallet_infos = await fetchAPI('mangopay/users/pay-out/' + vendor_id, "POST", true, true, {value: amount})
    // console.log(vendor_id, amount)
    toast.success("Le solde a été récupéré avec succès !");
    toast.success("Vos gains sont en cours de traitement. Vous recevrez un virement sous peu.");
    return wallet_infos
}

async function getUserBankAcountByMangoId(mangoId: number) {
    const bank_infos = await fetchAPI('users/bank-account/' + mangoId, "GET", true, true)
    return bank_infos.response
}

async function getAllVendors(): Promise<VendorInterface | []> {
    const vendors_infos = await fetchAPI('users/vendors/', "GET", true, true)
    if (vendors_infos.status === 200) {
        return vendors_infos.response as VendorInterface
    }
    return []
}



async function updateUserInfos(userId: number, updatedUserInfos: UpdateUserInfosInterfaceRayan) {
    const user_data = await fetchAPI('users/' + userId, "PUT", true, true, updatedUserInfos)
    console.log(user_data)
    return user_data
}




export {
    User,
    filterAddress,
    createUserFromWordpress,
    getUserRole,
    createVendorFromWordpress,
    getUserById,
    getUserMangoWallet,
    payout,
    getUserByIdcopy,
    getUserById_Test_R,
    getUserWallet,
    getUserBankAcountByMangoId,
    getAllUsers,
    getAllVendors,
    SendUserGoogleID,
    updateUserInfos,
    UserRayan
}

