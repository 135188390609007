import fetchAPI from "../../components/Fetch";
import {Browser} from "@capacitor/browser";
import {Capacitor} from "@capacitor/core";

// Fonction de paiement
async function pay(id: number, valuepay: number) {
    console.log(id);
    const isNative = Capacitor.isNativePlatform();

    let paypal;
    if (valuepay === 2) {
        if (isNative) {
            paypal = {"paypal": "true", "phone": "true"};
        } else {
            paypal = {"paypal": "true"};
        }
    } else {
        if (isNative) {
            paypal = {"phone": "true"};
        }
    }

    const pay = await fetchAPI("order/" + id + "/pay", "POST", true, true, paypal);
    console.log(pay);

    if (pay.status !== 200) {
        // Gérer les erreurs ici
    } else {
        const redirectUrl = valuepay === 2 ? pay.response.RedirectURL : pay.response.redirectURL;
        if(isNative){
            await Browser.open({url: redirectUrl});
            await Browser.close()
        } else {
            window.location.href = redirectUrl
        }

    }
}

// Fonction de renouvellement du paiement
async function repay(id: number) {
    const isNative = Capacitor.isNativePlatform();
    let repay
    if (isNative) {
        repay = {"repay": "true", "phone": "true"};
    } else {
        repay = {"repay": "true"};
    }
    console.log("on repay")
    const pay = await fetchAPI("order/" + id + "/pay", "POST", true, true, repay);
    console.log(pay)
    if (pay.status !== 200) {
        // Gérer les erreurs ici
    } else {
        const redirectUrl = pay.response.redirectURL;
        if(isNative){
            await Browser.open({url: redirectUrl});
            await Browser.close()
        } else {
            window.location.href = redirectUrl
        }
    }
}

export {pay, repay};
