import fetchAPI from "../Fetch";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getProductsAllPublished, ProductInterface} from "../Products/Products";
import {Button, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import {DatePickerProps} from "antd/lib/date-picker";
import moment, {Moment} from "moment";
import {DeleteOutlined, EditOutlined, FileOutlined} from "@ant-design/icons";
import {changeOrderState, deleteOrder} from "../Order";


export interface Promotion {
    id: number,
    slug: string,
    description: string,
    value: number,
    date_debut: number | null,
    date_fin: number | null,
    quantity: number,
    number_usages: number,
    promotion_val_id: number,
    Minimal_cart_value: number | null,
    orders: [],
    PromotionValue: PromotionValue,
    products: [ProductInterface]
}

interface dataSourceInterface {
    key: number,
    slug: string,
    description: string,
    nb_usages: number,
    quantity: number,
    value: number,
}
interface PromotionValue {
    id: number,
    value: string,
}
interface CreatePromotion {
    slug: string,
    description: string | null,
    date_debut: string | null,
    date_fin: string | null,
    quantity: number,
    value: number,
    promotion_val_id: number,
    product: [number] | null,
    minimal_cart: number | null
}

async function checkPromotion(slug:string, toast_active: boolean): Promise<Promotion | string> {
    const promotion = await fetchAPI(`promotion/checkPromo/${slug}`, "GET", true, true);
    if(promotion.status === 200) {
        if(toast_active) toast.success("Code promo valide");
        return promotion.response[0] as Promotion
    }
    if(toast_active) toast.error("Ce code de promotion n'est pas / plus valide");

    return "Ce code de promotion n'est pas / plus valide"
}

async function getDiscountPrice(promotionCode:string, totalTTC:number): Promise<number | null>{
    const result = await checkPromotion(promotionCode, false);
    if (result && typeof result === 'object' && 'value' in result) {
        const validDiscountPercentage = Math.max(0, Math.min(result.value, 100));
        const discountAmount = (validDiscountPercentage / 100) * totalTTC;
        return totalTTC - discountAmount
    }
    return null
}

async function createPromotion(promotion:any): Promise<Promotion | string>{
    const result = await fetchAPI("promotion/createPromo", "POST", true, false, promotion)
    console.log(result)
    if(result.status === 201) {
        toast.success("Promotion créée avec succès");
        return result.response as Promotion
    }
    toast.error("Erreur lors de la création de la promotion");
    return result.message
}

async function deletePromotion(id: [number]):Promise<string>{
    const result = await fetchAPI("promotion/deletePromo", "POST", true, true, {id:id})
    if(result.status === 200) {
        toast.success("Promition supprimée avec succès")
        return result.message
    }
    else {
        toast.error("Erreur lors de la suppression de la promotion")
        return result.message
    }
}
async function getAllPromotionCodes(){
    const result = await fetchAPI("promotion/checkAllActive", "GET", true, true)
    if(result.status === 200) {
        console.log(result.response as Promotion[])
        return result.response as Promotion[]
    }
    else {
        return result.message
    }
}
function ManagePromotion(): JSX.Element {
    const [date, setDate] = useState('');
    const [fetched, setFetched] = useState<boolean>(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);
    const [form] = Form.useForm();

    const showDeleteConfirmation = (promotionID: number) => {
        Modal.confirm({
            title: 'Confirmation',
            content: 'Êtes-vous sûr de vouloir supprimer ce code promo ?',
            okText: 'Oui',
            okType: 'danger',
            cancelText: 'Non',
            onOk() {
                // Appel à la fonction de suppression ici
                deletePromotion([promotionID]).then((value: any) => {
                    setFetched(false)
                });
            },
        });
    };

    useEffect(() => {
        async function fetchProducts() {
            try {
                const promotions = await getAllPromotionCodes()
                const mappedOptions = promotions.map(async (promotion: Promotion) => {
                    return {
                        key: promotion.id,
                        id: promotion.id,
                        slug: promotion.slug,
                        description: promotion.description,
                        nb_usages: promotion.number_usages,
                        quantity: promotion.quantity,
                        value: promotion.value,
                    } as dataSourceInterface;
                })
                const mappedOptionsWithData = await Promise.all(mappedOptions);
                setOptions(mappedOptionsWithData.reverse());
                setFetched(true)
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        }

        if (!fetched) {
            fetchProducts();
        }
    }, [fetched]);
    const onChange = (value: DatePickerProps["value"], dateString: string) => {
        console.log("Selected Time:", value);
        console.log("Formatted Selected Time:", dateString);
        setDate(dateString);
    };

    const onOk = (value: DatePickerProps["value"]) => {
        if (moment.isMoment(value)) {
            const formattedDate: string = (value as Moment).format("HH:mm:ss DD/MM/YYYY");
            console.log("onOk Formatted Date:", formattedDate);
        }
    };
    const columns = [
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
            width: '15%',
            render: (text: any) => <span >{text}</span>
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '15%',
            render: (text: any) => <span >{text}</span>
        },
        {
            title: 'Nombre d\'usage',
            dataIndex: 'nb_usages',
            key: 'nb_usages',
            width: '15%',
            render: (text: any) => <span >{text}</span>
        },
        {
            title: 'Quantité',
            dataIndex: 'quantity',
            key: 'quantity',
            width: '15%',
            render: (text: any) => <span >{text}</span>
        },
        {
            title: 'Valeur de la promotion',
            dataIndex: 'value',
            key: 'value',
            width: '15%',
            render: (text: any) => <span >{text}%</span>
        },
        {
            title: 'Action',
            key: 'action',
            width: '20%',
            render: (text: any, record: any) => (
                <div style={{ display: 'flex' }}>
                    <Button
                        style={{ margin: '5px' }}
                        icon={<DeleteOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                        onClick={() => showDeleteConfirmation(record.id)}
                    />
                </div>
            ),
        },
    ]
    const onFinish = (values: any) => {
        console.log(createPromotion({
            slug: values.slug as unknown as string,
            quantity: 1000,
            value: values.valeur as unknown as number,
            promotion_val_id: 2,
            description: values.description as unknown as string,
        } as CreatePromotion))
        setFetched(false)
    }
    return (
        <div>
            <Form onFinish={onFinish}
                  initialValues={{
                      remember: true,
                  }}
                  form={form}>
            {/*<Space direction="vertical" size={12}>*/}
            {/*    <DatePicker*/}
            {/*        showTime={{ format: "HH:mm:ss" }}*/}
            {/*        format="HH:mm:ss DD/MM/YYYY"*/}
            {/*        onChange={onChange}*/}
            {/*        onOk={onOk}*/}
            {/*        placeholder={'Date de début'}*/}
            {/*    />*/}
            {/*</Space>*/}
            <Space direction="vertical" size={12}>
                <Select
                    defaultValue="Pourcentage"
                    style={{ width: 120 }}
                    // onChange={handleChange}
                    options={[
                        { value: 1, label: 'Pourcentage' },
                    ]}
                />
            </Space>
            <Space direction="vertical" size={12}>
                <Form.Item name="slug" >
                    <Input placeholder={'slug'} showCount maxLength={10} allowClear={true} required/>
                </Form.Item>
            </Space>
            <Space direction="vertical" size={12}>
                <Form.Item name="description">
                    <Input placeholder={'Description'} showCount maxLength={120} allowClear={true} required/>
                </Form.Item>
            </Space>
            <Space direction="vertical" size={12}>
                <Form.Item name="valeur">
                    <InputNumber placeholder={'Valeur du code promo ex : 1000'} step={0.01} min={0} required
                                  addonAfter="%"/>
                </Form.Item>
            </Space>
            <Space direction="vertical" size={12}>
                    <Button htmlType="submit">Créer un code promo</Button>
            </Space>
            </Form>
            <Table
                dataSource={options}
                columns={columns}
                bordered
                pagination={false}
                className="custom-table"
                style={{margin: '0 20px'}}
                scroll={{y: 500}} // Set the desired height here (e.g., 400px)
            />
            <ToastContainer />
        </div>
    )
}

export {createPromotion, getDiscountPrice, checkPromotion, deletePromotion, ManagePromotion}