import React from 'react';
import { Button } from 'antd';
import { saveAs } from 'file-saver';

interface DownloadButtonProps {
    fileName: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ fileName }) => {
    const handleDownload = async () => {
        try {
            // Utilisez le lien dynamique avec le nom du fichier
            const downloadLink = `https://beta.captainchercheur.com/media/public/spreadsheet/${fileName}`;
            const response = await fetch(downloadLink);

            // Vérifiez si la réponse est réussie (status 200)
            if (!response.ok) {
                throw new Error('Erreur lors du téléchargement du fichier');
            }

            // Récupérez le contenu du fichier
            const blob = await response.blob();

            // Utilisez la bibliothèque FileSaver pour déclencher le téléchargement
            saveAs(blob, fileName);
        } catch (error) {
            console.error('Erreur lors du téléchargement du fichier:', error);
            // Gérer l'erreur (afficher un message à l'utilisateur, etc.)
        }
    };

    return (
        <Button onClick={handleDownload} type="primary" style={{margin:'10px'}}>
            Télécharger le fichier Excel
        </Button>
    );
};

export default DownloadButton;
