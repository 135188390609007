import {Button, Col, Input, Row, Select, Form, FormInstance} from "antd";
import React, {useEffect, useState} from "react";
import {AddressType, User, UserAddress, UserInterface} from "../../../User";
import {createBillingAddress, modifyAddress,deleteAddress} from "../../../Address";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {t} from "i18next";
import {countries} from "../../../../pages/Cart/countries";

interface OptionsInterface {
    value: number,
    label: string
}
type RequiredMark = boolean;


export default function BillingAddress() {

    const colorInfoHeaderTable = "#F3F5F6"
    const colorInfoBorderTable = "#EAEAEA"
    const colorInfoText = "#09154C"
    const colorInfoPayBtn = "#7ED957"

    const [userInfos, setUserInfos] = useState({} as UserInterface);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<OptionsInterface[]>();

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const [billingInfos, setbillingInfos] = useState<UserAddress>({
        id: 0,
        address: '',
        additional_address: '',
        city: '',
        country: 'france',
        country_code: '',
        zipcode: '',
        phone_number: '',
        company_id: null,
        address_type: [{
            id: 1,
            address_type: "Billing",
        }] as AddressType[],
        zone_id: 0,
        zone: '',
        company: '',
        region: '',
        firstname : '',
        lastname : '',
        relais: null
    });

    const onChangeBilling = (value_selected: string) => {
        // console.log(`selected ${value}`);
        const selectedUserAddress = userInfos.user_addresses.find(
            (address) => address.id === parseInt(value_selected)
        );
        if (selectedUserAddress) {
            console.log("Selected User BillingAddress:", selectedUserAddress);
            form.setFieldsValue({
                ...selectedUserAddress,
                // firstname: userInfos.firstname,
                // lastname: userInfos.lastname,
                phone: selectedUserAddress.phone_number,
                // email: userInfos.user_email,
                region: selectedUserAddress.region,
                id : selectedUserAddress.id
            });
            let billing = document.getElementById("billing");
            if (billing){
                billing.innerText="Modifier cette adresse de facturation"
                billing.title="edit"
            }
            let delete_billing = document.getElementById("delete");
            if (delete_billing){
                delete_billing.style.display="";
            }


        }

    };

    async function delete_Address(forme: FormInstance) {

        try {
            let address_id = forme.getFieldValue("id");
            const response = await deleteAddress(address_id);
            if (response) {
                toast.success("Adresse supprimée avec succès !");
                setTimeout(function () {
                    window.location.href = '/dashboard';
                }, 2000);
            } else {
                toast.error(" Il y a eu un problème lors de la suppression de votre adresse");
                setTimeout(function () {
                    window.location.reload();
                }, 2000);

            }
        } catch (error) {
            console.error('Une erreur s\'est produite lors de la suppression de l\'adresse :', error);
        }
    }

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };



    const [form] = Form.useForm();


    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>(true);

    const onRequiredTypeChange = ({requiredMarkValue}: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    }

    const config_firstname = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez entrer votre prénom'}],
    }
    const config_lastname = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez entrer votre nom'}],
    }
    const config_address = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez entrer votre adresse'}],
    }
    const config_country = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez choisir un pays'}],
    }
    const config_city = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez choisir une ville'}],
    }
    const config_zipcode = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez entrer votre code postal'}],
    }
    const config_phone_number = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez entrer votre numéro de téléphone'}],
    }
    const config_region = {
        rules: [{type: 'string' as const, required: true, message: 'Veuillez entrer une région'}],
    }


    const onFinish = async (values: any) => {
        let billing = document.getElementById("billing");
        if (billing){
            if( billing.title==="edit"){
                try {
                    let address_id= values.id;
                    const response = await modifyAddress(values,address_id);
                    if (response) {
                        toast.success("Adresse modifiée avec succès !");
                        setTimeout(function() {
                            window.location.href = '/dashboard';
                        }, 2000);
                    } else {
                        toast.error(" Il y a eu un problème lors de la modification de votre adresse");
                        setTimeout(function() {
                            window.location.reload();
                        }, 2000);

                    }
                } catch (error) {
                    console.error('Une erreur s\'est produite lors de la création de l\'adresse :', error);
                }
            }
            if( billing.title==="create"){
                try {
                    const response = await createBillingAddress(values);
                    if (response.id) {
                        toast.success("Adresse ajoutée avec succès !");
                        setTimeout(function() {
                            window.location.href = '/dashboard';
                        }, 2000);
                    } else {
                        toast.error(" Il y a eu un problème lors de la création de votre adresse");
                        setTimeout(function() {
                            window.location.reload();
                        }, 2000);

                    }
                } catch (error) {
                    console.error('Une erreur s\'est produite lors de la création de l\'adresse :', error);
                }
            }
        }

    };

    useEffect(() => {
        const fetchUserInfos = async () => {
            try {
                const data = await User();
                if (data) {
                    setUserInfos(data);
                    setDataFetched(true);
                    const filteredAddresses = data.user_addresses.filter((address) => address.address_type[0].id === 1);

                    const mappedOptions = filteredAddresses.map((address) => ({
                        value: address.id,
                        label: address.address + ' ' + address.zipcode
                    }));

                    setOptions(mappedOptions);
                    form.setFieldsValue({
                        email: data.user_email,
                    })

                }
            } catch (error) {
                console.log("No user data found");
            }
        };

        if (!dataFetched) {
            fetchUserInfos();
        }
    }, [dataFetched]);

    return (
        <div>
            <style>
                {`
                .ant-row.ant-form-item-row.css-dev-only-do-not-override-txh9fw{
                display : contents !important ;
                }      
                :where(.css-dev-only-do-not-override-txh9fw).ant-form-item .ant-form-item-label >label::after {
                content : "" !important ;     
               `}
            </style>

            <div>
                <ToastContainer />
                <Row>
                    <Col flex={3} style={{justifyContent: 'center', display: 'flex'}}>
                        <div style={{width: '90%', color: colorInfoText}}>
                            <div style={{width: '100%'}}>
                                <Form onFinish={onFinish} initialValues={{remember: true,requiredMarkValue: requiredMark}} onValuesChange={onRequiredTypeChange} requiredMark={requiredMark} form={form}>
                                    <div>
                                        <Row style={{marginBottom: '20px'}}>
                                            <p style={{marginBottom: '10px'}}>Sélectionner ou Ajouter une adresse de Facturation</p>
                                            <Select
                                                showSearch
                                                placeholder="Sélectionner une adresse de Facturation"
                                                optionFilterProp="children"
                                                style={{width: '100%'}}
                                                onChange={onChangeBilling}
                                                onSearch={onSearch}
                                                options={options}
                                            />
                                        </Row>
                                        {/*<Form.Item name="id" style={{display: "none"}}/>*/}
                                        <Form.Item name="id" style={{display:'none',marginBottom: '10px', width: '95%'}}>
                                            <Input style={{width: '100%', height: '35px'}}/>
                                        </Form.Item>
                                        <Form.Item name="email" style={{display:'none'}}>
                                            <Input placeholder="Email"
                                                   value={userInfos.user_email}
                                                   defaultValue={userInfos.user_email} disabled={true}/>
                                        </Form.Item>


                                        <Row style={{marginBottom: '20px'}}>
                                            <Col flex={1} style={{marginRight: '10px'}}>
                                                <Form.Item name={t('firstname')} label="Prénom" {...config_firstname}
                                                           tooltip="Ce champ est requis" >
                                                    <Input placeholder={t('Prénom')}
                                                           style={{width: '100%', height: '35px'}}
                                                           name='firstname' allowClear={true} maxLength={64} showCount={true} />
                                                </Form.Item>
                                            </Col>
                                            <Col flex={1}>
                                                <Form.Item name="lastname"
                                                           label={t('Nom de famille')} {...config_lastname}
                                                           tooltip={t('Ce champ est requis')}>
                                                    <Input placeholder={t('Nom')}
                                                           style={{width: '100%', height: '35px'}}
                                                           name='lastname' allowClear={true} maxLength={64} showCount={true} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '20px'}}>
                                            <Col flex={1}>
                                                <Form.Item name="address" {...config_address}
                                                           tooltip={t('Ce champ est requis')}
                                                           label={t('Adresse (Numéro et Rue)')}
                                                           style={{marginBottom: '10px', width: '95%'}}>
                                                    <Input placeholder={t('Adresse')}
                                                           style={{width: '100%', height: '35px'}}
                                                           value={billingInfos.address} allowClear={true} maxLength={256} showCount={true}  />
                                                </Form.Item>
                                            </Col>
                                            <Col flex={1}>
                                                <p style={{width: '100%', marginBottom: '10px'}}>{t('Complément d\'adresse (optionnel)')}</p>
                                                <Form.Item name="additional_address"
                                                           style={{marginBottom: '10px', width: '95%'}}>
                                                    <Input
                                                        placeholder={t('Apt, suite, unité, nom de l\'entreprise (facultatif)')} value={billingInfos.additional_address ? billingInfos.additional_address : ""}
                                                        style={{width: '100%', height: '35px'}} allowClear={true} maxLength={128} showCount={true} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '20px'}}>
                                            <Form.Item name="country"  {...config_country} label={t('Pays')}
                                                       style={{marginBottom: '10px', width: '95%'}}
                                                       tooltip={t('Ce champ est requis')}>
                                                <Select
                                                    showSearch
                                                    placeholder="Pays"
                                                    optionFilterProp="children"
                                                    filterOption={filterOption}
                                                    options={countries}
                                                />
                                            </Form.Item>
                                        </Row>
                                        <Row style={{marginBottom: '20px'}}>
                                            <Col flex={1}>
                                                <Form.Item name="city"  {...config_city}
                                                           tooltip={t('Ce champ est requis')} label={t('Ville')}
                                                           style={{marginBottom: '10px', width: '95%'}}>
                                                    <Input placeholder={t('Ville')}
                                                           style={{width: '100%', height: '35px'}} allowClear={true} maxLength={64} showCount={true}  />
                                                </Form.Item>
                                            </Col>
                                            <Col flex={1}>
                                                <Form.Item name="zipcode"  {...config_zipcode}
                                                           label={t('Code Postal')} tooltip={t('Ce champ est requis')}
                                                           style={{marginBottom: '10px', width: '95%'}}>
                                                    <Input placeholder={t('Code Postal')}
                                                           style={{width: '100%', height: '35px'}} allowClear={true} maxLength={32} showCount={true}  />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '20px'}}>
                                            <Col flex={1}>
                                                <Form.Item name="phone" label={t('Téléphone')}
                                                           tooltip={t('Ce champ est requis')} {...config_phone_number}
                                                           style={{marginBottom: '10px', width: '95%'}}>
                                                    <Input placeholder={t('Téléphone')}
                                                           style={{width: '100%', height: '35px'}} allowClear={true} maxLength={32} showCount={true} />
                                                </Form.Item>
                                            </Col>
                                            <Col flex={1}>
                                                <Form.Item name="region" label={t('Région')}  {...config_region}
                                                           tooltip={t('Ce champ est requis')}
                                                           style={{marginBottom: '10px', width: '95%'}}>
                                                    <Input placeholder={t('Région')}
                                                           style={{width: '100%', height: '35px'}} allowClear={true} maxLength={64} showCount={true} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row style={{justifyContent: 'end',marginBottom:'150px'}}>

                                            <Button style={{backgroundColor: colorInfoPayBtn, height: '35px'}}
                                                    htmlType="submit" title={"create"}
                                                    id={"billing"}>Ajouter
                                            </Button>

                                            <Button style={{backgroundColor: colorInfoPayBtn, height: '35px', display: 'none', marginLeft:'20px'}}
                                                    onClick={() => {delete_Address(form)}}
                                                    id={"delete"}>Supprimer
                                            </Button>

                                        </Row>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}