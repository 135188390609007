import {Button, DatePicker, Select, Space} from "antd";
import {RangePickerProps} from "antd/es/date-picker";
import moment, {Moment} from "moment";
import {DatePickerProps} from "antd/lib/date-picker";
import React, {useEffect, useState} from "react";
import {getProductsAllPublished, ProductInterface} from "../../Products/Products";
import {createHightlight} from "../../Misc/Highlights";
import {toast, ToastContainer} from "react-toastify";

interface OptionInterface {
    label: string;
    value: number;
}

function ManageHighlights(): JSX.Element {
    const { RangePicker } = DatePicker;
    const [products, setProducts] = useState([]);
    const [fetched, setFetched] = useState<boolean>(false);
    const [options, setOptions] = useState<OptionInterface[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [date, setDate] = useState('');

    useEffect(() => {
        async function fetchProducts() {
            try {
                const productInfos = await getProductsAllPublished();
                setProducts(productInfos);

                const transformedProducts = productInfos.map((product: { product: { product_name: any; id: any; }; }) => ({
                    label: product.product.product_name,
                    value: product.product.id,
                }));

                setOptions(transformedProducts);
                setFetched(true);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        }

        if (!fetched) {
            fetchProducts();
        }
    }, [fetched]);


    const onChange = (value: DatePickerProps["value"], dateString: string) => {
        console.log("Selected Time:", value);
        console.log("Formatted Selected Time:", dateString);
        setDate(dateString);
    };

    const onOk = (value: DatePickerProps["value"]) => {
        if (moment.isMoment(value)) {
            const formattedDate: string = (value as Moment).format("HH:mm:ss DD/MM/YYYY");
            console.log("onOk Formatted Date:", formattedDate);
        }
    };

    const handleSearch = (value: string) => {
        setSearchValue(value);

        if (!fetched) {
            return;
        }

        const filteredOptions = products
            .filter((product: { product: { product_name: string; id: number } }) => {
                const productName = product.product.product_name.toLowerCase();
                const includesValue = productName.includes(value.toLowerCase());

                return includesValue;
            })
            .map((product: { product: { product_name: string; id: number } }) => ({
                label: product.product.product_name,
                value: product.product.id,
            }));

        setOptions(filteredOptions);
    };




    const handleSelectChange = (selectedValues: number[]) => {
        setSelectedProducts(selectedValues);
    };

    const handleButtonClick = () => {
        console.log("Selected Products:", selectedProducts, date);

        createHightlight(date, selectedProducts).then((result) => {
            if (result.status === 201) {
                toast.success("Mise en avant effectuée");
            } else {
                toast.error("Une erreur s'est produite lors de la création de la mise en avant", result.message);
            }
        });
    };

    if (!fetched) {
        return <div></div>;
    }
    const filterOption = (input: string, option?: OptionInterface) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return (
        <div>
            <Space direction="vertical" size={12}>
                <DatePicker
                    showTime={{ format: "HH:mm:ss" }}
                    format="HH:mm:ss DD/MM/YYYY"
                    onChange={onChange}
                    onOk={onOk}
                />
            </Space>
            <ToastContainer />
            <Select
                mode="multiple"
                style={{ width: '75%' }}
                placeholder="Select les produits"
                filterOption={filterOption}
                optionLabelProp="label"
                allowClear={true}
                showSearch={true}
                // onSearch={handleSearch}
                onChange={handleSelectChange}
                options={options}
            >
                {/*{options.map((option) => (*/}
                {/*    <Select.Option key={option.value} value={option.value} label={option.label}>*/}
                {/*        {option.label}*/}
                {/*    </Select.Option>*/}
                {/*))}*/}
            </Select>
            <Button onClick={handleButtonClick}>Mettre ces produits en avant</Button>
        </div>
    );
}

export { ManageHighlights };