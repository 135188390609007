import React, {useEffect, useState} from "react";
import "./style.css";
import {Button, Col, Divider, Dropdown, Input, MenuProps, Row, Space} from "antd";
import {
    DownOutlined,
    EnvironmentOutlined,
    FacebookOutlined,
    InstagramOutlined,
    LinkedinOutlined,
    MailOutlined,
    PhoneOutlined, SafetyCertificateOutlined, SearchOutlined, UpOutlined, UserOutlined,
    YoutubeOutlined
} from "@ant-design/icons";
import logo1 from '../../img/Brands/logo-bpi-1-1-1.webp'
import logo2 from '../../img/Brands/logo-aper-slider03-1.webp'
import logo3 from '../../img/Brands/logo-captronic-prev-ui-1-1.webp'
import logo4 from '../../img/Brands/logo-fin-1-1.webp'
import logo5 from '../../img/Brands/logo-ceei-prev-ui-1-1.webp'
import logo6 from '../../img/Brands/logo-french-tech-prev-ui-1-1.webp'
import logo7 from '../../img/Brands/logo-hisse-et-oh-1-1.webp'
import logo8 from '../../img/Brands/logo-partenairs-1-1-1.webp'
import logo9 from '../../img/Brands/perpignan-mediterranee-metropole-2016-1-1.webp'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCreditCard, faHeadset, faLocationDot, faShieldAlt, faTruckFast} from "@fortawesome/free-solid-svg-icons";
import SliderBottom from "../Mobile/SliderBottom";
import {useTranslation} from "react-i18next";
import Translate from "../Header/translate";


export function FooterView() {
    const {t} = useTranslation();
    const colorFooterBgContainer = "#09154C"
    const colorFooterText = "#FFFFFF"
    const colorFooterButtonBg = "#7ED957"
    const colorDivider = "#EAEAEA"

    const currentYear = new Date().getFullYear();

    const lang: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer">
                    Français
                </a>
            ),
        },
        // {
        //     key: '2',
        //     label: (
        //         <a target="_blank" rel="noopener noreferrer">
        //             English
        //         </a>
        //     ),
        // },
        // {
        //     key: '3',
        //     label: (
        //         <a target="_blank" rel="noopener noreferrer">
        //             Español
        //         </a>
        //     ),
        // },
    ];

    const currency: MenuProps['items'] = [
        //     {
        //         key: '1',
        //         label: (
        //             <a target="_blank" rel="noopener noreferrer">
        //                 USD
        //             </a>
        //         ),
        //     },
        //     {
        //         key: '2',
        //         label: (
        //             <a target="_blank" rel="noopener noreferrer" >
        //                 GBP
        //             </a>
        //         ),
        //     },
        //     {
        //         key: '3',
        //         label: (
        //             <a target="_blank" rel="noopener noreferrer" >
        //                 CHF
        //             </a>
        //         ),
        //     },
    ];

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const colorHeaderUpperPanel = "#7ed957";


    return (
        <div className={'footer'} style={{backgroundColor: '#fff'}}>
            <div>
                {windowWidth < 768 ? (
                    <div>
                        <SliderBottom/>
                        <div style={{
                            backgroundColor: colorFooterBgContainer,
                            color: colorFooterText,
                            padding: '10px 0'
                        }}>
                            <Row style={{display: 'block', margin: '15px 10px'}}>
                                <p style={{marginBottom: '15px', marginLeft: '15px'}}>{t('Nous Contacter')}</p>
                                <div>
                                    <Row style={{marginBottom: '10px'}}>
                                        <Col flex={1} style={{marginRight: '5px'}}>
                                                <PhoneOutlined style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    color: 'white',
                                                    transform: 'scaleX(-1)',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    fontSize: '24px'
                                                }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                        </Col>
                                        <Col flex={4} style={{width: '250px'}}>
                                            <p>{t('Lundi')} - {t('Vendredi')} : 09h - 17h</p>
                                            <p><a href={'tel:+33468229025'} style={{color: colorFooterText}}>04 68 22 90
                                                25</a>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col flex={1} style={{marginRight: '5px'}}>
                                                <MailOutlined style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    color: 'white',
                                                    transform: 'scaleX(-1)',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    fontSize: '24px'
                                                }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                        </Col>
                                        <Col flex={4} style={{width: '250px'}}>
                                            <p>{t('Besoin d\'aide ?')}</p>
                                            <p><a target={"_blank"} href={'mailto:contact@poseidonshop.fr'}
                                                  style={{color: colorFooterText}}>contact@poseidonshop.fr</a>
                                            </p>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col flex={1} style={{marginRight: '5px'}}>
                                            <EnvironmentOutlined style={{
                                                height: '100%',
                                                width: '100%',
                                                color: 'white',
                                                transform: 'scaleX(-1)',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                                fontSize: '24px'
                                            }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                        </Col>
                                        <Col flex={4} style={{width: '250px'}}>
                                            <p>POSEIDONSHOP</p>
                                            <p><a target={"_blank"} href={'mailto:contact@poseidonshop.fr'}
                                                  style={{color: colorFooterText}}>Rue Augustin Thierry, 66000
                                                Perpignan</a></p>
                                        </Col>
                                    </Row>
                                </div>
                            </Row>
                            <Row style={{width: '80%', margin: 'auto',display: 'none'}}>
                                <Divider style={{borderTopColor: colorDivider}}/>
                            </Row>
                            <Row style={{margin: '10px 30px', display: 'none'}}>
                                    <div>
                                        <p style={{marginBottom: '10px'}}><b>{t('A Propos')}</b></p>
                                        <a style={{
                                            marginBottom: '5px',
                                            display: 'block',
                                            color: 'white'
                                        }}>{t('Présentation')}</a>
                                        <a style={{
                                            marginBottom: '5px',
                                            display: 'block',
                                            color: 'white'
                                        }}>{t('Nos Valeurs')}</a>
                                        {/*<a style={{marginBottom: '5px', display: 'block', color: 'white'}}>Nos*/}
                                        {/*    Partenaires</a>*/}
                                        <a target={"_blank"} href={'https://captainchercheur.com/'}
                                           style={{marginBottom: '20px', display: 'block', color: 'white'}}>Cap'tain
                                            Chercheur</a>
                                        <p style={{marginBottom: '10px'}}><b>{t('Infos')}</b></p>
                                        <a href={'/dashboard'} style={{
                                            marginBottom: '20px',
                                            display: 'block',
                                            color: 'white'
                                        }}>{t('Mon Compte')}</a>
                                        {/*<a style={{marginBottom: '5px', display: 'block', color: 'white'}}>Mode de*/}
                                        {/*    Paiement</a>*/}
                                        {/*<a style={{marginBottom: '5px', display: 'block', color: 'white'}}>Livraison</a>*/}
                                        {/*<a style={{marginBottom: '5px', display: 'block', color: 'white'}}>Retour</a>*/}
                                        {/*<a style={{marginBottom: '25px', display: 'block', color: 'white'}}>F.A.Q.</a>*/}
                                        <p style={{marginBottom: '10px'}}><b>{t('Mentions')}</b></p>
                                        <a href={'/mentions-legales'} style={{
                                            marginBottom: '5px',
                                            display: 'block',
                                            color: 'white'
                                        }}>{t('Mentions légales')}</a>
                                        <a href={'/cgv'} style={{
                                            marginBottom: '5px',
                                            display: 'block',
                                            color: 'white'
                                        }}>{t('Conditions générales de vente')}</a>
                                        <a href={'/cgu'} style={{
                                            marginBottom: '5px',
                                            display: 'block',
                                            color: 'white'
                                        }}>{t('Conditions générales d\'utilisation')}</a>
                                        <a href={'/politique-de-confidentialite'} style={{
                                            marginBottom: '5px',
                                            display: 'block',
                                            color: 'white'
                                        }}>{t('Politique de confidentialité')}</a>
                                    </div>
                            </Row>
                            <Row style={{width: '80%', margin: 'auto'}}>
                                <Divider style={{borderTopColor: colorDivider}}/>
                            </Row>

                            <Row style={{display: "grid", textAlign: 'center', justifyContent: 'center'}}>
                                <h3 style={{marginBottom: '20px'}}>{t('Suivez - Nous')}</h3>
                                <Row>
                                    <div style={{display: 'contents'}}>
                                        <Col flex={1}>
                                            <a style={{color: 'white'}} target={"_blank"}
                                               href={'https://www.facebook.com/profile.php?id=100088339946641&__cft__[0]=AZVsDXlivPr6FnyU84kY6T42ymANSgAY85RoCW64f1LaqCmRBWvnsQB7wU1lIfsbq_W2XBMrcm1yHTryJQGQ4XwXka5pCmmT0QNO0oKFJAvuBcR5CmiBcRYnmZf4HLAFg0Q&__tn__=-]K-R'}>
                                                <FacebookOutlined style={{fontSize: '30px', margin: '0 10px'}}
                                                                  onPointerEnterCapture={undefined}
                                                                  onPointerLeaveCapture={undefined}/>
                                            </a>
                                        </Col>
                                        <Col flex={1}>
                                            <a style={{color: 'white'}} target={"_blank"}
                                               href={'https://www.instagram.com/captainchercheur/'}>
                                                <InstagramOutlined style={{fontSize: '30px', margin: '0 10px'}}
                                                                   onPointerEnterCapture={undefined}
                                                                   onPointerLeaveCapture={undefined}/>
                                            </a>
                                        </Col>
                                        <Col flex={1}>
                                            <a style={{color: 'white'}} target={"_blank"}
                                               href={'https://www.youtube.com/channel/UC8SfQjh6up0Fy0b9HP9_tWg'}>
                                                <YoutubeOutlined style={{fontSize: '30px', margin: '0 10px'}}
                                                                 onPointerEnterCapture={undefined}
                                                                 onPointerLeaveCapture={undefined}/>
                                            </a>
                                        </Col>
                                        <Col flex={1}>
                                            <a style={{color: 'white'}} target={"_blank"}
                                               href={'https://fr.linkedin.com/company/captainchercheur'}>
                                                <LinkedinOutlined style={{fontSize: '30px', margin: '0 10px'}}
                                                                  onPointerEnterCapture={undefined}
                                                                  onPointerLeaveCapture={undefined}/>
                                            </a>
                                        </Col>
                                    </div>
                                </Row>
                            </Row>
                            <Row style={{width: '80%', margin: 'auto'}}>
                                <Divider style={{borderTopColor: colorDivider}}/>
                            </Row>
                            <Row style={{justifyContent: 'center', marginBottom :'150px'}}>
                                <h2 style={{
                                    color: colorFooterText,
                                    textAlign: 'center',
                                    marginBottom: '20px',
                                    fontSize: "14px"
                                }}>© {currentYear} POSEIDON. {t('Tous droit réservés')}</h2>
                                <Row style={{width: '100%', justifyContent: 'center'}}>
                                    <div>
                                        <Col style={{display: 'inline-flex', fontSize: '12px', margin: '0 5px'}}>
                                            <p style={{
                                                color: '#B5B9C9',
                                                alignItems: "center",
                                                display: 'flex'
                                            }}>{t('Langue')}</p>
                                           <Translate/>
                                        </Col>
                                        <Col style={{display: 'inline-flex', fontSize: '12px', margin: '0 5px'}}>
                                            <p style={{
                                                color: '#B5B9C9',
                                                alignItems: "center",
                                                display: 'flex'
                                            }}>{t('Devise')}</p>
                                            {/*<Dropdown menu={{items: currency}} placement="top" trigger={['click']}>*/}
                                            <a onClick={(e) => e.preventDefault()} style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'inline-flex',
                                                marginLeft: '10px',
                                                position: 'relative',
                                                color: colorFooterText
                                            }}>
                                                <Space>
                                                    EUR
                                                    <DownOutlined onPointerEnterCapture={undefined}
                                                                onPointerLeaveCapture={undefined}/>
                                                </Space>
                                            </a>
                                            {/*</Dropdown>*/}
                                        </Col>
                                    </div>
                                </Row>
                            </Row>
                        </div>
                    </div>
                ) : windowWidth >= 768 && windowWidth < 1100 ? (
                    <div>
                        <SliderBottom/>
                        <div style={{
                            backgroundColor: colorFooterBgContainer,
                            color: colorFooterText,
                            padding: '10px 0'
                        }}>
                            <Row style={{display: 'block', margin: '15px 10px'}}>
                                <p style={{marginBottom: '15px', marginLeft: '15px'}}>{t('Nous Contacter')}</p>
                                <div>
                                    {windowWidth < 1000 ? (
                                        <Row style={{marginBottom: '10px'}}>
                                            <Row style={{marginLeft: '5%',width:'75%'}}>
                                                <Col flex={1} style={{marginRight: '5px'}}>
                                                    <PhoneOutlined style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        color: 'white',
                                                        transform: 'scaleX(-1)',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        fontSize: '24px'
                                                    }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                                </Col>
                                                <Col flex={4} style={{width:'250px'}} >
                                                    <p>{t('Lundi')} - {t('Vendredi')} : 9h - 12h / 14h - 17h30</p>
                                                    <p>
                                                        <a href={'tel:+33468229025'} style={{color: colorFooterText}}>
                                                            04 68 22 90 25
                                                        </a>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row style={{marginLeft: '5%',width:'75%'}}>
                                                <Col flex={1} style={{marginRight: '5px'}}>
                                                    <MailOutlined style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        color: 'white',
                                                        transform: 'scaleX(-1)',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        fontSize: '24px'
                                                    }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                                </Col>
                                                <Col flex={4} style={{width:'250px'}} >
                                                    <p>{t('Besoin d\'aide ?')}</p>
                                                    <p><a target={"_blank"} href={'mailto:contact@poseidonshop.fr'}
                                                          style={{color: colorFooterText}}>contact@poseidonshop.fr</a>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row style={{marginLeft: '5%',width:'75%'}}>
                                                    <Col flex={1} style={{marginRight: '5px'}}>
                                                        <EnvironmentOutlined onPointerEnterCapture={undefined}
                                                                             onPointerLeaveCapture={undefined}
                                                                             style={{
                                                                                 height: '100%',
                                                                                 width: '100%',
                                                                                 color: 'white',
                                                                                 transform: 'scaleX(-1)',
                                                                                 justifyContent: 'center',
                                                                                 alignItems: 'center',
                                                                                 display: 'flex',
                                                                                 fontSize: '24px'
                                                                             }}/>
                                                    </Col>
                                                    <Col flex={4} style={{width:'250px'}} >
                                                        <p>POSEIDONSHOP</p>
                                                        <p>
                                                            <a target={"_blank"} href={'mailto:contact@poseidonshop.fr'}
                                                              style={{color: colorFooterText}}>Rue Augustin Thierry, 66000
                                                            Perpignan</a>
                                                        </p>
                                                    </Col>

                                            </Row>

                                        </Row>
                                    ) : ( windowWidth >= 1000 && windowWidth < 1100 ? (
                                        <Row style={{marginBottom: '10px'}}>
                                                <Col flex={1} style={{marginRight: '5px'}}>
                                                    <PhoneOutlined style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        color: 'white',
                                                        transform: 'scaleX(-1)',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        fontSize: '24px'
                                                    }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                                </Col>
                                                <Col flex={4}>
                                                    <p>{t('Lundi')} - {t('Vendredi')} : 9h - 12h / 14h - 17h30</p>
                                                    <p>
                                                        <a href={'tel:+33468229025'} style={{color: colorFooterText}}>
                                                            04 68 22 90 25
                                                        </a>
                                                    </p>
                                                </Col>
                                                <Col flex={1} style={{marginRight: '5px'}}>
                                                    <MailOutlined style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        color: 'white',
                                                        transform: 'scaleX(-1)',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        fontSize: '24px'
                                                    }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                                </Col>
                                                <Col flex={4}>
                                                    <p>{t('Besoin d\'aide ?')}</p>
                                                    <p><a target={"_blank"} href={'mailto:contact@poseidonshop.fr'}
                                                          style={{color: colorFooterText}}>contact@poseidonshop.fr</a>
                                                    </p>
                                                </Col>
                                                <br/>
                                                <Row>
                                                    <Col flex={1} style={{marginRight: '5px'}}>
                                                        <EnvironmentOutlined onPointerEnterCapture={undefined}
                                                                             onPointerLeaveCapture={undefined}
                                                                             style={{
                                                                                 height: '100%',
                                                                                 width: '100%',
                                                                                 color: 'white',
                                                                                 transform: 'scaleX(-1)',
                                                                                 justifyContent: 'center',
                                                                                 alignItems: 'center',
                                                                                 display: 'flex',
                                                                                 fontSize: '24px'
                                                                             }}/>
                                                    </Col>
                                                    <Col flex={4}>
                                                        <p>POSEIDONSHOP</p>
                                                        <p><a target={"_blank"} href={'mailto:contact@poseidonshop.fr'}
                                                              style={{color: colorFooterText}}>Rue Augustin Thierry, 66000
                                                            Perpignan</a></p>
                                                    </Col>

                                                </Row>
                                            </Row>
                                        ) : null
                                    )}

                                </div>
                            </Row>
                            <Row style={{width: '80%', margin: 'auto'}}>
                                <Divider style={{borderTopColor: colorDivider}}/>
                            </Row>
                            <Row style={{margin: '10px 30px', display: 'block'}}>

                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <Col style={{width: '30%'}}>
                                                <p style={{marginBottom: '10px'}}><b>{t('A Propos')}</b></p>
                                                <a style={{
                                                    marginBottom: '5px',
                                                    display: 'block',
                                                    color: 'white'
                                                }}>{t('Présentation')}</a>
                                                <a style={{
                                                    marginBottom: '5px',
                                                    display: 'block',
                                                    color: 'white'
                                                }}>{t('Nos Valeurs')}</a>
                                                <a target={"_blank"} href={'https://captainchercheur.com/'}
                                                   style={{marginBottom: '20px', display: 'block', color: 'white'}}>Cap'tain
                                                    Chercheur</a>
                                            </Col>
                                            <Col style={{width: '30%'}}>
                                                <p style={{marginBottom: '10px'}}><b>{t('Infos')}</b></p>
                                                <a href={'/dashboard'} style={{
                                                    marginBottom: '20px',
                                                    display: 'block',
                                                    color: 'white'
                                                }}>{t('Mon Compte')}</a>
                                            </Col>
                                            <Col style={{width: '30%'}}>
                                                <p style={{marginBottom: '10px'}}><b>{t('Mentions')}</b></p>
                                                <a href={'/mentions-legales'} style={{
                                                    marginBottom: '5px',
                                                    display: 'block',
                                                    color: 'white'
                                                }}>{t('Mentions légales')}</a>
                                                <a href={'/cgv'} style={{
                                                    marginBottom: '5px',
                                                    display: 'block',
                                                    color: 'white'
                                                }}>{t('Conditions générales de vente')}</a>
                                                <a href={'/cgu'} style={{
                                                    marginBottom: '5px',
                                                    display: 'block',
                                                    color: 'white'
                                                }}>{t('Conditions générales d\'utilisation')}</a>
                                                <a href={'/politique-de-confidentialite'} style={{
                                                    marginBottom: '5px',
                                                    display: 'block',
                                                    color: 'white'
                                                }}>{t('Politique de confidentialité')}</a>
                                            </Col>
                                        </div>

                            </Row>
                            <Row style={{width: '80%', margin: 'auto'}}>
                                <Divider style={{borderTopColor: colorDivider}}/>
                            </Row>
                            <Row style={{display: "grid", textAlign: 'center', justifyContent: 'center'}}>
                                <h3 style={{marginBottom: '20px',fontSize: '30px'}}>{t('Suivez - Nous')}</h3>
                                <Row>
                                    <div style={{display: 'contents'}}>
                                        <Col flex={1}>
                                            <a style={{color: 'white'}} target={"_blank"}
                                               href={'https://www.facebook.com/profile.php?id=100088339946641&__cft__[0]=AZVsDXlivPr6FnyU84kY6T42ymANSgAY85RoCW64f1LaqCmRBWvnsQB7wU1lIfsbq_W2XBMrcm1yHTryJQGQ4XwXka5pCmmT0QNO0oKFJAvuBcR5CmiBcRYnmZf4HLAFg0Q&__tn__=-]K-R'}>
                                                <FacebookOutlined style={{fontSize: '30px', margin: '0 10px'}}
                                                                  onPointerEnterCapture={undefined}
                                                                  onPointerLeaveCapture={undefined}/>
                                            </a>
                                        </Col>
                                        <Col flex={1}>
                                            <a style={{color: 'white'}} target={"_blank"}
                                               href={'https://www.instagram.com/captainchercheur/'}>
                                                <InstagramOutlined style={{fontSize: '30px', margin: '0 10px'}}
                                                                   onPointerEnterCapture={undefined}
                                                                   onPointerLeaveCapture={undefined}/>
                                            </a>
                                        </Col>
                                        <Col flex={1}>
                                            <a style={{color: 'white'}} target={"_blank"}
                                               href={'https://www.youtube.com/channel/UC8SfQjh6up0Fy0b9HP9_tWg'}>
                                                <YoutubeOutlined style={{fontSize: '30px', margin: '0 10px'}}
                                                                 onPointerEnterCapture={undefined}
                                                                 onPointerLeaveCapture={undefined}/>
                                            </a>
                                        </Col>
                                        <Col flex={1}>
                                            <a style={{color: 'white'}} target={"_blank"}
                                               href={'https://fr.linkedin.com/company/captainchercheur'}>
                                                <LinkedinOutlined style={{fontSize: '30px', margin: '0 10px'}}
                                                                  onPointerEnterCapture={undefined}
                                                                  onPointerLeaveCapture={undefined}/>
                                            </a>
                                        </Col>
                                    </div>
                                </Row>
                            </Row>
                            <Row style={{width: '80%', margin: 'auto'}}>
                                <Divider style={{borderTopColor: colorDivider}}/>
                            </Row>
                            <Row style={{justifyContent: 'center', marginBottom :'150px'}}>
                                <h2 style={{
                                    color: colorFooterText,
                                    textAlign: 'center',
                                    marginBottom: '20px',
                                    fontSize: "14px"
                                }}>© {currentYear} POSEIDON. {t('Tous droit réservés')}</h2>
                                <Row style={{width: '100%', justifyContent: 'center'}}>
                                    <div>
                                        <Col style={{display: 'inline-flex', fontSize: '14px', margin: '0 15px'}}>
                                            <p style={{
                                                color: '#B5B9C9',
                                                alignItems: "center",
                                                display: 'flex'
                                            }}>{t('Langue')}</p>
                                            <Translate/>
                                        </Col>
                                        <Col style={{display: 'inline-flex', fontSize: '14px', margin: '0 15px'}}>
                                            <p style={{
                                                color: '#B5B9C9',
                                                alignItems: "center",
                                                display: 'flex'
                                            }}>{t('Devise')}</p>
                                            {/*<Dropdown menu={{items: currency}} placement="top" trigger={['click']}>*/}
                                            <a onClick={(e) => e.preventDefault()} style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'inline-flex',
                                                marginLeft: '10px',
                                                position: 'relative',
                                                color: colorFooterText
                                            }}>
                                                <Space>
                                                    EUR
                                                    <DownOutlined onPointerEnterCapture={undefined}
                                                                onPointerLeaveCapture={undefined}/>
                                                </Space>
                                            </a>
                                            {/*</Dropdown>*/}
                                        </Col>

                                    </div>

                                </Row>
                            </Row>
                        </div>
                    </div>
                ) : windowWidth >= 1100 && windowWidth < 1300 ? (
                    <div>
                        <SliderBottom/>
                        <div style={{backgroundColor: 'white', paddingTop: '20px', paddingBottom: '30px'}}>
                            <h3 style={{textAlign: 'center', marginBottom: '30px'}}>{t('Ils nous font confiance')}</h3>
                            <Row>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex',}}>
                                    <img style={{height: '75px'}} src={logo1} alt={"BPI France"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo2} alt={"Aper"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo3} alt={"Captronic"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo4} alt={"FIN"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo5} alt={"CEEI"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo6} alt={"La French Tech"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo7} alt={"Hisse et Oh"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo8} alt={"Région Occitanie"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo9} alt={"Perpignan Méditerranée Métropole"}/>
                                </Col>
                            </Row>
                        </div>
                        <div style={{backgroundColor: colorFooterBgContainer, color: colorFooterText}}>
                            <br/>
                            {   windowWidth < 1120 ? (
                                    <Row style={{margin: '20px'}}>
                                            <Col flex={5}>
                                        <ul>
                                            <li style={{
                                                listStyleType: 'none',
                                                fontSize: '24px'
                                            }}>{t('Nous contacter')}</li>
                                            <br/>
                                            <Row style={{alignItems: 'center'}}>
                                                <Col  style={{marginRight : '10px'}}>
                                                    <PhoneOutlined onPointerEnterCapture={undefined}
                                                                   onPointerLeaveCapture={undefined}
                                                                   style={{fontSize: '24px', transform: 'scaleX(-1)'}}/>
                                                </Col>
                                                <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <p style={{width: '325px'}}>
                                                        {t('Lundi')} - {t('Vendredi')} : 9h - 12h / 14h - 17h30</p>
                                                    <p>
                                                        <a href={'tel:+33468229025'} style={{color: colorFooterText}}>04 68 22 90 25</a>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row style={{alignItems: 'center'}}>
                                                <Col  style={{marginRight : '10px'}}>
                                                    <MailOutlined onPointerEnterCapture={undefined}
                                                                  onPointerLeaveCapture={undefined}
                                                                  style={{fontSize: '24px', transform: 'scaleX(-1)'}}/>
                                                </Col>
                                                <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <p style={{width: '325px'}}>{t('Besoin d\'aide ?')}</p>
                                                    <p>
                                                        <a target={"_blank"} href={'mailto:contact@poseidonshop.fr'} style={{color: colorFooterText}}>contact@poseidonshop.fr</a>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row style={{alignItems: 'center'}}>
                                                <Col style={{marginRight : '10px'}}>
                                                    {/*<FontAwesomeIcon icon={faLocationDot} size="2x" />*/}
                                                    <EnvironmentOutlined onPointerEnterCapture={undefined}
                                                                         onPointerLeaveCapture={undefined}
                                                                         style={{fontSize: '24px', transform: 'scaleX(-1)'}}/>
                                                </Col>
                                                <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <p style={{width: '325px'}}>POSEIDONSHOP</p>
                                                    <p>Rue Augustin Thierry, 66000 Perpignan</p>
                                                </Col>
                                            </Row>
                                        </ul>
                                    </Col>
                                            <Col flex={5}>
                                                <ul>
                                                    <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('A propos')}</li>
                                                    <br/>
                                                    <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                        <a style={{color: 'white'}}>{t('Présentation')}</a>
                                                    </li>
                                                    <br/>
                                                    <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                        <a style={{color: 'white'}}>{t('Nos Valeurs')}</a>
                                                    </li>
                                                    {/*<br/>*/}
                                                    {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Nos partenaires</li>*/}
                                                    <br/>
                                                    <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                        <a target={"_blank"} href={'https://captainchercheur.com/'}
                                                           style={{color: 'white'}}>Cap'tain Chercheur</a>
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col flex={5} style={{marginRight : '5px'}}>
                                                <ul>
                                                    <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('Infos')}</li>
                                                    <br/>
                                                    <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                        <a href={'/dashboard'} style={{color: 'white'}}>{t('Mon Compte')}</a>
                                                    </li>
                                                    {/*<br/>*/}
                                                    {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Mode de Paiement</li>*/}
                                                    {/*<br/>*/}
                                                    {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Livraison</li>*/}
                                                    {/*<br/>*/}
                                                    {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Retour</li>*/}
                                                    {/*<br/>*/}
                                                    {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>F.A.Q.</li>*/}
                                                </ul>
                                            </Col>
                                            <Col flex={5}>
                                                <ul>
                                                    <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('Mentions')}</li>
                                                    <br/>
                                                    <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                        <a href={'/mentions-legales'}
                                                           style={{color: 'white'}}>{t('Mentions légales')}</a>
                                                    </li>
                                                    <br/>
                                                    <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                        <a href={'/cgv'}
                                                           style={{color: 'white'}}>{t('Conditions générales de vente')}</a>
                                                    </li>
                                                    <br/>
                                                    <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                        <a href={'/cgu'}
                                                           style={{color: 'white'}}>{t('Conditions générales d\'utilisation')}</a>
                                                    </li>
                                                    <br/>
                                                    <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                        <a href={'/politique-de-confidentialite'}
                                                           style={{color: 'white'}}>{t('Politique de confidentialité')}</a>
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col flex={5}>
                                                <ul>
                                                    <li style={{
                                                        listStyleType: 'none',
                                                        fontSize: '24px',
                                                        textAlign: 'center'
                                                    }}>Suivez-nous !
                                                    </li>
                                                    <br/>
                                                    <Row>
                                                        <Col flex={1} style={{
                                                            position: 'relative', display: 'inline-flex', justifyContent:
                                                                'center', alignItems: 'center'
                                                        }}>
                                                            <a target={"_blank"}
                                                               href={'https://www.facebook.com/profile.php?id=100088339946641&__cft__[0]=AZVsDXlivPr6FnyU84kY6T42ymANSgAY85RoCW64f1LaqCmRBWvnsQB7wU1lIfsbq_W2XBMrcm1yHTryJQGQ4XwXka5pCmmT0QNO0oKFJAvuBcR5CmiBcRYnmZf4HLAFg0Q&__tn__=-]K-R'}
                                                               style={{color: 'white'}}><FacebookOutlined style={{fontSize: '30px'}}
                                                                                                          onPointerEnterCapture={undefined}
                                                                                                          onPointerLeaveCapture={undefined}/></a>
                                                        </Col>
                                                        <Col flex={1} style={{
                                                            position: 'relative', display: 'inline-flex', justifyContent:
                                                                'center', alignItems: 'center'
                                                        }}>
                                                            <a target={"_blank"}
                                                               href={'https://www.instagram.com/captainchercheur/'}
                                                               style={{color: 'white'}}><InstagramOutlined
                                                                style={{fontSize: '30px'}} onPointerEnterCapture={undefined}
                                                                onPointerLeaveCapture={undefined}/></a>
                                                        </Col>
                                                        <Col flex={1} style={{
                                                            position: 'relative', display: 'inline-flex', justifyContent:
                                                                'center', alignItems: 'center'
                                                        }}>
                                                            <a target={"_blank"}
                                                               href={'https://www.youtube.com/channel/UC8SfQjh6up0Fy0b9HP9_tWg'}
                                                               style={{color: 'white'}}><YoutubeOutlined style={{fontSize: '30px'}}
                                                                                                         onPointerEnterCapture={undefined}
                                                                                                         onPointerLeaveCapture={undefined}/></a>
                                                        </Col>
                                                        <Col flex={1} style={{
                                                            position: 'relative', display: 'inline-flex', justifyContent:
                                                                'center', alignItems: 'center'
                                                        }}>
                                                            <a target={"_blank"}
                                                               href={'https://fr.linkedin.com/company/captainchercheur'}
                                                               style={{color: 'white'}}><LinkedinOutlined style={{fontSize: '30px'}}
                                                                                                          onPointerEnterCapture={undefined}
                                                                                                          onPointerLeaveCapture={undefined}/></a>
                                                        </Col>
                                                    </Row>
                                                </ul>
                                            </Col>
                                        </Row>
                                ) : windowWidth >= 1120 && windowWidth < 1200 ? (
                                    <Row style={{margin: '20px'}}>
                                        <Col flex={5}>
                                        <ul>
                                            <li style={{
                                                listStyleType: 'none',
                                                fontSize: '24px'
                                            }}>{t('Nous contacter')}</li>
                                            <br/>
                                            <Row style={{alignItems: 'center'}}>
                                                <Col flex={1}>
                                                    <PhoneOutlined onPointerEnterCapture={undefined}
                                                                   onPointerLeaveCapture={undefined}
                                                                   style={{fontSize: '24px', transform: 'scaleX(-1)'}}/>
                                                </Col>
                                                <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <p style={{width: '325px'}}>
                                                        {t('Lundi')} - {t('Vendredi')} : 9h - 12h / 14h - 17h30</p>
                                                    <p>
                                                        <a href={'tel:+33468229025'} style={{color: colorFooterText}}>04 68 22 90 25</a>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row style={{alignItems: 'center'}}>
                                                <Col flex={1}>
                                                    <MailOutlined onPointerEnterCapture={undefined}
                                                                  onPointerLeaveCapture={undefined}
                                                                  style={{fontSize: '24px', transform: 'scaleX(-1)'}}/>
                                                </Col>
                                                <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <p style={{width: '325px'}}>{t('Besoin d\'aide ?')}</p>
                                                    <p>
                                                        <a target={"_blank"} href={'mailto:contact@poseidonshop.fr'} style={{color: colorFooterText}}>contact@poseidonshop.fr</a>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row style={{alignItems: 'center'}}>
                                                <Col flex={1}>
                                                    {/*<FontAwesomeIcon icon={faLocationDot} size="2x" />*/}
                                                    <EnvironmentOutlined onPointerEnterCapture={undefined}
                                                                         onPointerLeaveCapture={undefined}
                                                                         style={{fontSize: '24px', transform: 'scaleX(-1)'}}/>
                                                </Col>
                                                <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <p style={{width: '325px'}}>POSEIDONSHOP</p>
                                                    <p>Rue Augustin Thierry, 66000 Perpignan</p>
                                                </Col>
                                            </Row>
                                        </ul>
                                    </Col>
                                        <Col flex={5}>
                                            <ul>
                                                <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('A propos')}</li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a style={{color: 'white'}}>{t('Présentation')}</a>
                                                </li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a style={{color: 'white'}}>{t('Nos Valeurs')}</a>
                                                </li>
                                                {/*<br/>*/}
                                                {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Nos partenaires</li>*/}
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a target={"_blank"} href={'https://captainchercheur.com/'}
                                                       style={{color: 'white'}}>Cap'tain Chercheur</a>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col flex={5}>
                                            <ul>
                                                <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('Infos')}</li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a href={'/dashboard'} style={{color: 'white'}}>{t('Mon Compte')}</a>
                                                </li>
                                                {/*<br/>*/}
                                                {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Mode de Paiement</li>*/}
                                                {/*<br/>*/}
                                                {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Livraison</li>*/}
                                                {/*<br/>*/}
                                                {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Retour</li>*/}
                                                {/*<br/>*/}
                                                {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>F.A.Q.</li>*/}
                                            </ul>
                                        </Col>
                                        <Col flex={5}>
                                            <ul>
                                                <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('Mentions')}</li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a href={'/mentions-legales'}
                                                       style={{color: 'white'}}>{t('Mentions légales')}</a>
                                                </li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a href={'/cgv'}
                                                       style={{color: 'white'}}>{t('Conditions générales de vente')}</a>
                                                </li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a href={'/cgu'}
                                                       style={{color: 'white'}}>{t('Conditions générales d\'utilisation')}</a>
                                                </li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a href={'/politique-de-confidentialite'}
                                                       style={{color: 'white'}}>{t('Politique de confidentialité')}</a>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col flex={5}>
                                            <ul>
                                                <li style={{
                                                    listStyleType: 'none',
                                                    fontSize: '24px',
                                                    textAlign: 'center'
                                                }}>Suivez-nous !
                                                </li>
                                                <br/>
                                                <Row>
                                                    <Col flex={1} style={{
                                                        position: 'relative', display: 'inline-flex', justifyContent:
                                                            'center', alignItems: 'center'
                                                    }}>
                                                        <a target={"_blank"}
                                                           href={'https://www.facebook.com/profile.php?id=100088339946641&__cft__[0]=AZVsDXlivPr6FnyU84kY6T42ymANSgAY85RoCW64f1LaqCmRBWvnsQB7wU1lIfsbq_W2XBMrcm1yHTryJQGQ4XwXka5pCmmT0QNO0oKFJAvuBcR5CmiBcRYnmZf4HLAFg0Q&__tn__=-]K-R'}
                                                           style={{color: 'white'}}><FacebookOutlined style={{fontSize: '30px'}}
                                                                                                      onPointerEnterCapture={undefined}
                                                                                                      onPointerLeaveCapture={undefined}/></a>
                                                    </Col>
                                                    <Col flex={1} style={{
                                                        position: 'relative', display: 'inline-flex', justifyContent:
                                                            'center', alignItems: 'center'
                                                    }}>
                                                        <a target={"_blank"}
                                                           href={'https://www.instagram.com/captainchercheur/'}
                                                           style={{color: 'white'}}><InstagramOutlined
                                                            style={{fontSize: '30px'}} onPointerEnterCapture={undefined}
                                                            onPointerLeaveCapture={undefined}/></a>
                                                    </Col>
                                                    <Col flex={1} style={{
                                                        position: 'relative', display: 'inline-flex', justifyContent:
                                                            'center', alignItems: 'center'
                                                    }}>
                                                        <a target={"_blank"}
                                                           href={'https://www.youtube.com/channel/UC8SfQjh6up0Fy0b9HP9_tWg'}
                                                           style={{color: 'white'}}><YoutubeOutlined style={{fontSize: '30px'}}
                                                                                                     onPointerEnterCapture={undefined}
                                                                                                     onPointerLeaveCapture={undefined}/></a>
                                                    </Col>
                                                    <Col flex={1} style={{
                                                        position: 'relative', display: 'inline-flex', justifyContent:
                                                            'center', alignItems: 'center'
                                                    }}>
                                                        <a target={"_blank"}
                                                           href={'https://fr.linkedin.com/company/captainchercheur'}
                                                           style={{color: 'white'}}><LinkedinOutlined style={{fontSize: '30px'}}
                                                                                                      onPointerEnterCapture={undefined}
                                                                                                      onPointerLeaveCapture={undefined}/></a>
                                                    </Col>
                                                </Row>
                                            </ul>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row style={{margin: '20px'}}>
                                        <Col flex={5}>
                                        <ul>
                                            <li style={{
                                                listStyleType: 'none',
                                                fontSize: '24px'
                                            }}>{t('Nous contacter')}</li>
                                            <br/>
                                            <Row style={{alignItems: 'center'}}>
                                                <Col flex={1}>
                                                    <PhoneOutlined onPointerEnterCapture={undefined}
                                                                   onPointerLeaveCapture={undefined}
                                                                   style={{fontSize: '32px', transform: 'scaleX(-1)'}}/>
                                                </Col>
                                                <Col style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center'
                                                }}>
                                                    <p style={{width: '325px'}}>{t('Lundi')} - {t('Vendredi')} : 9h -
                                                        12h / 14h - 17h30</p>
                                                    <p><a href={'tel:+33468229025'} style={{color: colorFooterText}}>04
                                                        68 22 90 25</a></p>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row style={{alignItems: 'center'}}>
                                                <Col flex={1}>
                                                    <MailOutlined onPointerEnterCapture={undefined}
                                                                  onPointerLeaveCapture={undefined}
                                                                  style={{fontSize: '32px', transform: 'scaleX(-1)'}}/>
                                                </Col>
                                                <Col style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center'
                                                }}>
                                                    <p style={{width: '325px'}}>{t('Besoin d\'aide ?')}</p>
                                                    <p><a target={"_blank"} href={'mailto:contact@poseidonshop.fr'}
                                                          style={{color: colorFooterText}}>contact@poseidonshop.fr</a>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row style={{alignItems: 'center'}}>
                                                <Col flex={1}>
                                                    {/*<FontAwesomeIcon icon={faLocationDot} size="2x" />*/}
                                                    <EnvironmentOutlined onPointerEnterCapture={undefined}
                                                                         onPointerLeaveCapture={undefined} style={{
                                                        fontSize: '32px',
                                                        transform: 'scaleX(-1)'
                                                    }}/>
                                                </Col>
                                                <Col style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center'
                                                }}>
                                                    <p style={{width: '325px'}}>POSEIDONSHOP</p>
                                                    <p>Rue Augustin Thierry, 66000 Perpignan</p>
                                                </Col>
                                            </Row>
                                        </ul>
                                    </Col>
                                        <Col flex={5}>
                                            <ul>
                                                <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('A propos')}</li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a style={{color: 'white'}}>{t('Présentation')}</a>
                                                </li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a style={{color: 'white'}}>{t('Nos Valeurs')}</a>
                                                </li>
                                                {/*<br/>*/}
                                                {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Nos partenaires</li>*/}
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a target={"_blank"} href={'https://captainchercheur.com/'}
                                                       style={{color: 'white'}}>Cap'tain Chercheur</a>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col flex={5}>
                                            <ul>
                                                <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('Infos')}</li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a href={'/dashboard'} style={{color: 'white'}}>{t('Mon Compte')}</a>
                                                </li>
                                                {/*<br/>*/}
                                                {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Mode de Paiement</li>*/}
                                                {/*<br/>*/}
                                                {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Livraison</li>*/}
                                                {/*<br/>*/}
                                                {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Retour</li>*/}
                                                {/*<br/>*/}
                                                {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>F.A.Q.</li>*/}
                                            </ul>
                                        </Col>
                                        <Col flex={5}>
                                            <ul>
                                                <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('Mentions')}</li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a href={'/mentions-legales'}
                                                       style={{color: 'white'}}>{t('Mentions légales')}</a>
                                                </li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a href={'/cgv'}
                                                       style={{color: 'white'}}>{t('Conditions générales de vente')}</a>
                                                </li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a href={'/cgu'}
                                                       style={{color: 'white'}}>{t('Conditions générales d\'utilisation')}</a>
                                                </li>
                                                <br/>
                                                <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                                    <a href={'/politique-de-confidentialite'}
                                                       style={{color: 'white'}}>{t('Politique de confidentialité')}</a>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col flex={5}>
                                            <ul>
                                                <li style={{
                                                    listStyleType: 'none',
                                                    fontSize: '24px',
                                                    textAlign: 'center'
                                                }}>Suivez-nous !
                                                </li>
                                                <br/>
                                                <Row>
                                                    <Col flex={1} style={{
                                                        position: 'relative', display: 'inline-flex', justifyContent:
                                                            'center', alignItems: 'center'
                                                    }}>
                                                        <a target={"_blank"}
                                                           href={'https://www.facebook.com/profile.php?id=100088339946641&__cft__[0]=AZVsDXlivPr6FnyU84kY6T42ymANSgAY85RoCW64f1LaqCmRBWvnsQB7wU1lIfsbq_W2XBMrcm1yHTryJQGQ4XwXka5pCmmT0QNO0oKFJAvuBcR5CmiBcRYnmZf4HLAFg0Q&__tn__=-]K-R'}
                                                           style={{color: 'white'}}><FacebookOutlined style={{fontSize: '30px'}}
                                                                                                      onPointerEnterCapture={undefined}
                                                                                                      onPointerLeaveCapture={undefined}/></a>
                                                    </Col>
                                                    <Col flex={1} style={{
                                                        position: 'relative', display: 'inline-flex', justifyContent:
                                                            'center', alignItems: 'center'
                                                    }}>
                                                        <a target={"_blank"}
                                                           href={'https://www.instagram.com/captainchercheur/'}
                                                           style={{color: 'white'}}><InstagramOutlined
                                                            style={{fontSize: '30px'}} onPointerEnterCapture={undefined}
                                                            onPointerLeaveCapture={undefined}/></a>
                                                    </Col>
                                                    <Col flex={1} style={{
                                                        position: 'relative', display: 'inline-flex', justifyContent:
                                                            'center', alignItems: 'center'
                                                    }}>
                                                        <a target={"_blank"}
                                                           href={'https://www.youtube.com/channel/UC8SfQjh6up0Fy0b9HP9_tWg'}
                                                           style={{color: 'white'}}><YoutubeOutlined style={{fontSize: '30px'}}
                                                                                                     onPointerEnterCapture={undefined}
                                                                                                     onPointerLeaveCapture={undefined}/></a>
                                                    </Col>
                                                    <Col flex={1} style={{
                                                        position: 'relative', display: 'inline-flex', justifyContent:
                                                            'center', alignItems: 'center'
                                                    }}>
                                                        <a target={"_blank"}
                                                           href={'https://fr.linkedin.com/company/captainchercheur'}
                                                           style={{color: 'white'}}><LinkedinOutlined style={{fontSize: '30px'}}
                                                                                                      onPointerEnterCapture={undefined}
                                                                                                      onPointerLeaveCapture={undefined}/></a>
                                                    </Col>
                                                </Row>
                                            </ul>
                                        </Col>
                                    </Row>
                                )
                            }
                            <br/>
                            <br/>
                            <br/>
                            <Divider style={{borderTop: '1px solid ' + colorDivider}}/>
                            <Row style={{margin: '20px'}}>
                                <Col flex={1} style={{marginLeft: '250px'}}>
                                    <h2 style={{fontSize: "14px"}}>© {currentYear} POSEIDON. {t('Tous droits réservés')}</h2>
                                </Col>
                                <Col flex={1} style={{display: 'inline-flex', justifyContent: 'end'}}>
                                    <p style={{textAlign: 'end', color: '#B5B9C9'}}>{t('Langue')}</p>
                                    <Translate/>
                                    {/*<Dropdown menu={{items: lang}} placement="top" trigger={['click']}>*/}
                                    {/*    <a onClick={(e) => e.preventDefault()} style={{*/}
                                    {/*        justifyContent: 'center', alignItems: 'center', display: 'inline-flex',*/}
                                    {/*        marginLeft: '10px', position: 'relative', color: colorFooterText*/}
                                    {/*    }}>*/}
                                    {/*        <Space>*/}
                                    {/*            {t('Français')}*/}
                                    {/*            <UpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>*/}
                                    {/*        </Space>*/}
                                    {/*    </a>*/}
                                    {/*</Dropdown>*/}
                                    <p style={{marginLeft: '25px', color: '#B5B9C9'}}>{t('Devise')}</p>
                                    {/*<Dropdown menu={{items: currency}} placement="top" trigger={['click']}>*/}
                                    <a onClick={(e) => e.preventDefault()} style={{
                                        justifyContent: 'center', alignItems: 'center', display: 'inline-flex',
                                        marginLeft: '10px', position: 'relative', color: colorFooterText
                                    }}>
                                        <Space>
                                            EUR
                                            <DownOutlined onPointerEnterCapture={undefined}
                                                          onPointerLeaveCapture={undefined}/>
                                        </Space>
                                    </a>
                                    {/*</Dropdown>*/}
                                </Col>
                            </Row>
                            <br/>
                        </div>
                    </div>
                ) : windowWidth >= 1300 && windowWidth < 1500 ? (
                    <div>
                        <SliderBottom/>
                        <div style={{backgroundColor: 'white', paddingTop: '20px', paddingBottom: '30px'}}>
                            <h3 style={{textAlign: 'center', marginBottom: '30px'}}>{t('Ils nous font confiance')}</h3>
                            <Row>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo1} alt={"BPI France"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo2} alt={"Aper"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo3} alt={"Captronic"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo4} alt={"FIN"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo5} alt={"CEEI"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo6} alt={"La French Tech"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo7} alt={"Hisse et Oh"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo8} alt={"Région Occitanie"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo9} alt={"Perpignan Méditerranée Métropole"}/>
                                </Col>
                            </Row>
                        </div>
                        <div style={{backgroundColor: colorFooterBgContainer, color: colorFooterText}}>
                            <br/>
                            <Row style={{margin: '20px'}}>

                                <Col flex={5}>
                                    <ul>
                                        <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('Nous contacter')}</li>
                                        <br/>
                                        <Row style={{alignItems: 'center'}}>
                                            <Col flex={1}>
                                                <PhoneOutlined onPointerEnterCapture={undefined}
                                                               onPointerLeaveCapture={undefined}
                                                               style={{fontSize: '32px', transform: 'scaleX(-1)'}}/>
                                            </Col>
                                            <Col flex={4} style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}>
                                                <p style={{width: '325px'}}>{t('Lundi')} - {t('Vendredi')} : 9h - 12h /
                                                    14h - 17h30</p>
                                                <p><a href={'tel:+33468229025'} style={{color: colorFooterText}}>04 68
                                                    22 90 25</a></p>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row style={{alignItems: 'center'}}>
                                            <Col flex={1}>
                                                <MailOutlined onPointerEnterCapture={undefined}
                                                              onPointerLeaveCapture={undefined}
                                                              style={{fontSize: '32px', transform: 'scaleX(-1)'}}/>
                                            </Col>
                                            <Col flex={4} style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}>
                                                <p style={{width: '325px'}}>{t('Besoin d\'aide ?')}</p>
                                                <p><a target={"_blank"} href={'mailto:contact@poseidonshop.fr'}
                                                      style={{color: colorFooterText}}>contact@poseidonshop.fr</a></p>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row style={{alignItems: 'center'}}>
                                            <Col flex={1}>
                                                {/*<FontAwesomeIcon icon={faLocationDot} size="2x" />*/}
                                                <EnvironmentOutlined onPointerEnterCapture={undefined}
                                                                     onPointerLeaveCapture={undefined} style={{
                                                    fontSize: '32px',
                                                    transform: 'scaleX(-1)'
                                                }}/>
                                            </Col>
                                            <Col flex={4} style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}>
                                                <p style={{width: '325px'}}>POSEIDONSHOP</p>
                                                <p>Rue Augustin Thierry, 66000 Perpignan</p>
                                            </Col>
                                        </Row>
                                    </ul>
                                </Col>

                                <Col flex={5}>
                                    <ul>
                                        <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('A propos')}</li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a style={{color: 'white'}}>{t('Présentation')}</a>
                                        </li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a style={{color: 'white'}}>{t('Nos Valeurs')}</a>
                                        </li>
                                        {/*<br/>*/}
                                        {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Nos partenaires</li>*/}
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a target={"_blank"} href={'https://captainchercheur.com/'}
                                               style={{color: 'white'}}>Cap'tain Chercheur</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col flex={5}>
                                    <ul>
                                        <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('Infos')}</li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a href={'/dashboard'} style={{color: 'white'}}>{t('Mon Compte')}</a>
                                        </li>
                                        {/*<br/>*/}
                                        {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Mode de Paiement</li>*/}
                                        {/*<br/>*/}
                                        {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Livraison</li>*/}
                                        {/*<br/>*/}
                                        {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Retour</li>*/}
                                        {/*<br/>*/}
                                        {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>F.A.Q.</li>*/}
                                    </ul>
                                </Col>
                                <Col flex={5}>
                                    <ul>
                                        <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('Mentions')}</li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a href={'/mentions-legales'}
                                               style={{color: 'white'}}>{t('Mentions légales')}</a>
                                        </li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a href={'/cgv'}
                                               style={{color: 'white'}}>{t('Conditions générales de vente')}</a>
                                        </li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a href={'/cgu'}
                                               style={{color: 'white'}}>{t('Conditions générales d\'utilisation')}</a>
                                        </li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a href={'/politique-de-confidentialite'}
                                               style={{color: 'white'}}>{t('Politique de confidentialité')}</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col flex={5}>
                                    <ul>
                                        <li style={{
                                            listStyleType: 'none',
                                            fontSize: '24px',
                                            textAlign: 'center'
                                        }}>Suivez-nous !
                                        </li>
                                        <br/>
                                        <Row>
                                            <Col flex={1} style={{
                                                position: 'relative', display: 'inline-flex', justifyContent:
                                                    'center', alignItems: 'center'
                                            }}>
                                                <a target={"_blank"}
                                                   href={'https://www.facebook.com/profile.php?id=100088339946641&__cft__[0]=AZVsDXlivPr6FnyU84kY6T42ymANSgAY85RoCW64f1LaqCmRBWvnsQB7wU1lIfsbq_W2XBMrcm1yHTryJQGQ4XwXka5pCmmT0QNO0oKFJAvuBcR5CmiBcRYnmZf4HLAFg0Q&__tn__=-]K-R'}
                                                   style={{color: 'white'}}><FacebookOutlined style={{fontSize: '30px'}}
                                                                                              onPointerEnterCapture={undefined}
                                                                                              onPointerLeaveCapture={undefined}/></a>
                                            </Col>
                                            <Col flex={1} style={{
                                                position: 'relative', display: 'inline-flex', justifyContent:
                                                    'center', alignItems: 'center'
                                            }}>
                                                <a target={"_blank"}
                                                   href={'https://www.instagram.com/captainchercheur/'}
                                                   style={{color: 'white'}}><InstagramOutlined
                                                    style={{fontSize: '30px'}} onPointerEnterCapture={undefined}
                                                    onPointerLeaveCapture={undefined}/></a>
                                            </Col>
                                            <Col flex={1} style={{
                                                position: 'relative', display: 'inline-flex', justifyContent:
                                                    'center', alignItems: 'center'
                                            }}>
                                                <a target={"_blank"}
                                                   href={'https://www.youtube.com/channel/UC8SfQjh6up0Fy0b9HP9_tWg'}
                                                   style={{color: 'white'}}><YoutubeOutlined style={{fontSize: '30px'}}
                                                                                             onPointerEnterCapture={undefined}
                                                                                             onPointerLeaveCapture={undefined}/></a>
                                            </Col>
                                            <Col flex={1} style={{
                                                position: 'relative', display: 'inline-flex', justifyContent:
                                                    'center', alignItems: 'center'
                                            }}>
                                                <a target={"_blank"}
                                                   href={'https://fr.linkedin.com/company/captainchercheur'}
                                                   style={{color: 'white'}}><LinkedinOutlined style={{fontSize: '30px'}}
                                                                                              onPointerEnterCapture={undefined}
                                                                                              onPointerLeaveCapture={undefined}/></a>
                                            </Col>
                                        </Row>
                                    </ul>
                                </Col>
                            </Row>
                            <br/>
                            <br/>
                            <br/>
                            <Divider style={{borderTop: '1px solid ' + colorDivider}}/>
                            <Row style={{margin: '20px'}}>
                                <Col flex={1} style={{marginLeft: '250px'}}>
                                    <h2 style={{fontSize: "14px"}}>© {currentYear} POSEIDON. {t('Tous droits réservés')}</h2>
                                </Col>
                                <Col flex={1} style={{display: 'inline-flex', justifyContent: 'end'}}>
                                    <p style={{textAlign: 'end', color: '#B5B9C9'}}>{t('Langue')}</p>
                                    <Translate/>
                                    {/*<Dropdown menu={{items: lang}} placement="top" trigger={['click']}>*/}
                                    {/*    <a onClick={(e) => e.preventDefault()} style={{*/}
                                    {/*        justifyContent: 'center', alignItems: 'center', display: 'inline-flex',*/}
                                    {/*        marginLeft: '10px', position: 'relative', color: colorFooterText*/}
                                    {/*    }}>*/}
                                    {/*        <Space>*/}
                                    {/*            {t('Français')}*/}
                                    {/*            <UpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>*/}
                                    {/*        </Space>*/}
                                    {/*    </a>*/}
                                    {/*</Dropdown>*/}
                                    <p style={{marginLeft: '25px', color: '#B5B9C9'}}>{t('Devise')}</p>
                                    {/*<Dropdown menu={{items: currency}} placement="top" trigger={['click']}>*/}
                                    <a onClick={(e) => e.preventDefault()} style={{
                                        justifyContent: 'center', alignItems: 'center', display: 'inline-flex',
                                        marginLeft: '10px', position: 'relative', color: colorFooterText
                                    }}>
                                        <Space>
                                            EUR
                                            <DownOutlined onPointerEnterCapture={undefined}
                                                          onPointerLeaveCapture={undefined}/>
                                        </Space>
                                    </a>
                                    {/*</Dropdown>*/}
                                </Col>
                            </Row>
                            <br/>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div style={{backgroundColor: colorHeaderUpperPanel, height: '200px'}}>
                            <Row>
                                <Col flex={1} style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '200px'
                                }}>
                                    <FontAwesomeIcon icon={faTruckFast} size={"2x"} style={{marginBottom: '30px'}}/>
                                    <p style={{marginBottom: '15px', fontSize: '16px'}}><b>{t('Livraison')}</b></p>
                                    <p style={{fontSize: '16px'}}>{t('Expédié et livré par Chronopost sous 24h – 48h')}</p>
                                </Col>
                                <Col flex={1} style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '200px'
                                }}>
                                    {/*<SafetyCertificateOutlined style={{marginBottom: '30px', fontSize: '32px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>*/}
                                    <FontAwesomeIcon icon={faShieldAlt} size={"2x"} style={{marginBottom: '30px'}}/>
                                    <p style={{marginBottom: '15px', fontSize: '16px'}}><b>{t('Sécurité')}</b></p>
                                    {/*<p style={{fontSize: '16px'}}>{t('Paiement 100% Sécurisé avec MangoPay')}</p>*/}
                                    <p style={{fontSize: '16px'}}>{t('Paiement Sécurisé avec 3D Secure')}</p>
                                    {/*<p style={{fontSize: '16px'}}>{t('Paiement 100% Sécurisé avec Double Authentification 3D Secure')}</p>*/}
                                </Col>
                                <Col flex={1} style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '200px'
                                }}>
                                    <FontAwesomeIcon icon={faHeadset} size={"2x"} style={{marginBottom: '30px'}}/>
                                    <p style={{marginBottom: '15px', fontSize: '16px'}}><b>{t('S.A.V.')}</b></p>
                                    <p style={{fontSize: '16px'}}>contact@poseidonshop.fr</p>
                                </Col>
                                <Col flex={1} style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '200px'
                                }}>
                                    <FontAwesomeIcon icon={faCreditCard} size={"2x"} style={{marginBottom: '30px'}}/>
                                    <p style={{marginBottom: '15px', fontSize: '16px'}}>
                                        <b>{t('Facilité de Paiement')}</b></p>
                                    <p style={{fontSize: '16px'}}>Payez en 4 fois sans frais avec PayPal</p>
                                </Col>
                            </Row>
                        </div>
                        <div style={{backgroundColor: 'white', paddingTop: '20px', paddingBottom: '30px'}}>
                            <h3 style={{textAlign: 'center', marginBottom: '30px'}}>{t('Ils nous font confiance')}</h3>
                            <Row>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex',}}>
                                    <img style={{height: '75px'}} src={logo1} alt={"BPI France"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo2} alt={"Aper"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo3} alt={"Captronic"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo4} alt={"FIN"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo5} alt={"CEEI"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo6} alt={"La French Tech"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo7} alt={"Hisse et Oh"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo8} alt={"Région Occitanie"}/>
                                </Col>
                                <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                    <img style={{height: '75px'}} src={logo9} alt={"Perpignan Méditerranée Métropole"}/>
                                </Col>
                            </Row>
                        </div>
                        <div style={{backgroundColor: colorFooterBgContainer, color: colorFooterText}}>
                            <br/>
                            <Row style={{margin: '20px'}}>

                                <Col flex={5}>
                                    <ul>
                                        <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('Nous contacter')}</li>
                                        <br/>
                                        <Row style={{alignItems: 'center'}}>
                                            <Col flex={1} style={{display: 'flex', justifyContent: 'center'}}>
                                                <PhoneOutlined onPointerEnterCapture={undefined}
                                                               onPointerLeaveCapture={undefined}
                                                               style={{fontSize: '32px', transform: 'scaleX(-1)'}}/>
                                            </Col>
                                            <Col flex={4} style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}>
                                                <p style={{width: '325px'}}>{t('Lundi')} - {t('Vendredi')} : 9h - 12h /
                                                    14h - 17h30</p>
                                                <p><a href={'tel:+33468229025'} style={{color: colorFooterText}}>04 68
                                                    22 90 25</a></p>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row style={{alignItems: 'center'}}>
                                            <Col flex={1} style={{display: 'flex', justifyContent: 'center'}}>
                                                <MailOutlined onPointerEnterCapture={undefined}
                                                              onPointerLeaveCapture={undefined}
                                                              style={{fontSize: '32px', transform: 'scaleX(-1)'}}/>
                                            </Col>
                                            <Col flex={4} style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}>
                                                <p style={{width: '325px'}}>{t('Besoin d\'aide ?')}</p>
                                                <p><a target={"_blank"} href={'mailto:contact@poseidonshop.fr'}
                                                      style={{color: colorFooterText}}>contact@poseidonshop.fr</a></p>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row style={{alignItems: 'center'}}>
                                            <Col flex={1} style={{display: 'flex', justifyContent: 'center'}}>
                                                {/*<FontAwesomeIcon icon={faLocationDot} size="2x" />*/}
                                                <EnvironmentOutlined onPointerEnterCapture={undefined}
                                                                     onPointerLeaveCapture={undefined} style={{
                                                    fontSize: '32px',
                                                    transform: 'scaleX(-1)'
                                                }}/>
                                            </Col>
                                            <Col flex={4} style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center'
                                            }}>
                                                <p style={{width: '325px'}}>POSEIDONSHOP</p>
                                                <p>Rue Augustin Thierry, 66000 Perpignan</p>
                                            </Col>
                                        </Row>
                                    </ul>
                                </Col>

                                <Col flex={5}>
                                    <ul>
                                        <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('A propos')}</li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a style={{color: 'white'}}>{t('Présentation')}</a>
                                        </li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a style={{color: 'white'}}>{t('Nos Valeurs')}</a>
                                        </li>
                                        {/*<br/>*/}
                                        {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Nos partenaires</li>*/}
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a target={"_blank"} href={'https://captainchercheur.com/'}
                                               style={{color: 'white'}}>Cap'tain Chercheur</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col flex={5}>
                                    <ul>
                                        <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('Infos')}</li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a href={'/dashboard'} style={{color: 'white'}}>{t('Mon Compte')}</a>
                                        </li>
                                        {/*<br/>*/}
                                        {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Mode de Paiement</li>*/}
                                        {/*<br/>*/}
                                        {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Livraison</li>*/}
                                        {/*<br/>*/}
                                        {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>Retour</li>*/}
                                        {/*<br/>*/}
                                        {/*<li style={{listStyleType: 'none', fontSize: '16px'}}>F.A.Q.</li>*/}
                                    </ul>
                                </Col>
                                <Col flex={5}>
                                    <ul>
                                        <li style={{listStyleType: 'none', fontSize: '24px'}}>{t('Mentions')}</li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a href={'/mentions-legales'}
                                               style={{color: 'white'}}>{t('Mentions légales')}</a>
                                        </li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a href={'/cgv'}
                                               style={{color: 'white'}}>{t('Conditions générales de vente')}</a>
                                        </li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a href={'/cgu'}
                                               style={{color: 'white'}}>{t('Conditions générales d\'utilisation')}</a>
                                        </li>
                                        <br/>
                                        <li style={{listStyleType: 'none', fontSize: '16px'}}>
                                            <a href={'/politique-de-confidentialite'}
                                               style={{color: 'white'}}>{t('Politique de confidentialité')}</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col flex={5}>
                                    <ul>
                                        <li style={{
                                            listStyleType: 'none',
                                            fontSize: '24px',
                                            textAlign: 'center'
                                        }}>Suivez-nous !
                                        </li>
                                        <br/>
                                        <Row>
                                            <Col flex={1} style={{
                                                position: 'relative', display: 'inline-flex', justifyContent:
                                                    'center', alignItems: 'center'
                                            }}>
                                                <a target={"_blank"}
                                                   href={'https://www.facebook.com/profile.php?id=100088339946641&__cft__[0]=AZVsDXlivPr6FnyU84kY6T42ymANSgAY85RoCW64f1LaqCmRBWvnsQB7wU1lIfsbq_W2XBMrcm1yHTryJQGQ4XwXka5pCmmT0QNO0oKFJAvuBcR5CmiBcRYnmZf4HLAFg0Q&__tn__=-]K-R'}
                                                   style={{color: 'white'}}><FacebookOutlined style={{fontSize: '30px'}}
                                                                                              onPointerEnterCapture={undefined}
                                                                                              onPointerLeaveCapture={undefined}/></a>
                                            </Col>
                                            <Col flex={1} style={{
                                                position: 'relative', display: 'inline-flex', justifyContent:
                                                    'center', alignItems: 'center'
                                            }}>
                                                <a target={"_blank"}
                                                   href={'https://www.instagram.com/captainchercheur/'}
                                                   style={{color: 'white'}}><InstagramOutlined
                                                    style={{fontSize: '30px'}} onPointerEnterCapture={undefined}
                                                    onPointerLeaveCapture={undefined}/></a>
                                            </Col>
                                            <Col flex={1} style={{
                                                position: 'relative', display: 'inline-flex', justifyContent:
                                                    'center', alignItems: 'center'
                                            }}>
                                                <a target={"_blank"}
                                                   href={'https://www.youtube.com/channel/UC8SfQjh6up0Fy0b9HP9_tWg'}
                                                   style={{color: 'white'}}><YoutubeOutlined style={{fontSize: '30px'}}
                                                                                             onPointerEnterCapture={undefined}
                                                                                             onPointerLeaveCapture={undefined}/></a>
                                            </Col>
                                            <Col flex={1} style={{
                                                position: 'relative', display: 'inline-flex', justifyContent:
                                                    'center', alignItems: 'center'
                                            }}>
                                                <a target={"_blank"}
                                                   href={'https://fr.linkedin.com/company/captainchercheur'}
                                                   style={{color: 'white'}}><LinkedinOutlined style={{fontSize: '30px'}}
                                                                                              onPointerEnterCapture={undefined}
                                                                                              onPointerLeaveCapture={undefined}/></a>
                                            </Col>
                                        </Row>
                                    </ul>
                                </Col>
                            </Row>
                            <br/>
                            <br/>
                            <br/>
                            <Divider style={{borderTop: '1px solid ' + colorDivider}}/>
                            <Row style={{margin: '20px'}}>
                                <Col flex={1} style={{marginLeft: '250px'}}>
                                    <h2 style={{fontSize: "14px"}}>© {currentYear} POSEIDON. {t('Tous droits réservés')}</h2>
                                </Col>
                                <Col flex={1} style={{display: 'inline-flex', justifyContent: 'end'}}>
                                    <p style={{textAlign: 'end', color: '#B5B9C9'}}>{t('Langue')}</p>
                                    <Translate/>
                                    {/*<Dropdown menu={{items: lang}} placement="top" trigger={['click']}>*/}
                                    {/*    <a onClick={(e) => e.preventDefault()} style={{*/}
                                    {/*        justifyContent: 'center', alignItems: 'center', display: 'inline-flex',*/}
                                    {/*        marginLeft: '10px', position: 'relative', color: colorFooterText*/}
                                    {/*    }}>*/}
                                    {/*        <Space>*/}
                                    {/*            {t('Français')}*/}
                                    {/*            <UpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>*/}
                                    {/*        </Space>*/}
                                    {/*    </a>*/}
                                    {/*</Dropdown>*/}
                                    <p style={{marginLeft: '25px', color: '#B5B9C9'}}>{t('Devise')}</p>
                                    {/*<Dropdown menu={{items: currency}} placement="top" trigger={['click']}>*/}
                                    <a onClick={(e) => e.preventDefault()} style={{
                                        justifyContent: 'center', alignItems: 'center', display: 'inline-flex',
                                        marginLeft: '10px', position: 'relative', color: colorFooterText
                                    }}>
                                        <Space>
                                            EUR
                                            <DownOutlined onPointerEnterCapture={undefined}
                                                          onPointerLeaveCapture={undefined}/>
                                        </Space>
                                    </a>
                                    {/*</Dropdown>*/}
                                </Col>
                            </Row>
                            <br/>
                        </div>
                    </div>
                )
                }
            </div>
        </div>
    );
}

