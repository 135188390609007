import React, {useEffect, useState} from 'react';
import type {MenuProps} from 'antd';
import {Menu} from 'antd';
import {Content} from "antd/es/layout/layout";
import {ManageHighlights} from "./Highlights";
import {ManagePromotion} from "../../Promotions";
import XML from "../../../pages/GoogleMerchant";


export function CompanyView() {

    const items: MenuProps['items'] = [
        {
            label: 'Entreprises',
            key: 'highlight',
        },
        {
            label: 'Demandes',
            key: 'promotions',
        },
        {
            label: 'Création d\'une entreprise',
            key: 'xml',
        }
    ];

    const [content, setCurrent] = useState('profile');

    const onClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{color: colorHeaderText}}>
            <div>
                <h1 style={{fontSize: '24px'}}>Paramètres de modification du site pour admin</h1>
                {/*<p style={{marginTop: '15px', fontSize: '16px', marginBottom: '20px'}}>Lorem ipsum dolor sit amet,*/}
                {/*    consectetur.</p>*/}
                <Menu onClick={onClick} selectedKeys={[content]} mode="horizontal" items={items}/>
                {
                    content === "profile" ? (
                        <Content
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 280,
                                background: "#FFFFFF",
                            }}
                        >
                            <ManageHighlights/>
                        </Content>
                    ) : content === "promotions" ? (
                        <Content
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 280,
                                background: "#FFFFFF",
                            }}
                        >
                            <ManagePromotion/>
                        </Content>
                    ) : content === "profile" ? (
                        <Content
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 280,
                                background: "#FFFFFF",
                            }}
                        >
                            <ManageHighlights/>
                        </Content>
                    ): content === "xml" ? (
                        <Content
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 280,
                                background: "#FFFFFF",
                            }}
                        >
                            <XML/>
                        </Content>
                    ) : null
                }

            </div>
        </div>
    )
}