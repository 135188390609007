import fetchAPI from "../Fetch";

interface PayOuts {
    Id: string,
    DebitedFunds: {
        Currency: string,
        Amount: number
    },
    ResultMessage: string,
    Nature: string,
    ExecutionDate: number,
    Tag: string
}


export function refundSubOrder () {

}
export async function getTransactionDetails(order_id:number){
    const result = await fetchAPI('mangopay/order-transaction/'+order_id, "GET", true, true)
    if(result.status === 200){
        return result.response
    }
    return []
}

export async function getVendorPayouts(bankAccountId:number){
    const result = await fetchAPI('mangopay/pay-out/'+bankAccountId, "GET", true, true)
    if(result.status === 200){
        return result.response as PayOuts[]
    }
    return [] as PayOuts[]
}

