import fetchAPI from "../Fetch";
import {Medias} from "../Media";
import {toast} from "react-toastify";
import {t} from "i18next";

export interface ProductInterface {
    slug: string,
    id: number,
    product_name: string,
    price: number,
    price_promo: number,
    product_sku: string | null,
    description: string,
    dimensions: string,
    quantity: number,
    weight: number,
    creation_date: number,
    product_image: number,
    marque: string
    gallery: number[],
    vendor_sku?: string
    state?: string
    state_description?: string,
    additional_description?: string,
    conditioningType?: number | string,//
    conditioningValue?: number,//
    product_categories: [{
        "id": number,
        "category_name": string,
        "category_slug": string,
        "products": []
    }],//
    commission: Commission,
    product_keywords?: string,
    tva: boolean,
    has_tva: boolean,
    vendor_id: number,
    backorder: boolean,
    no_stock: boolean
}

interface Commission {
    id: number,
    name: string,
    value: number
}

interface ProductCreation {
    state: string,//
    product_name: string,//
    vendor_sku: string | null,
    product_sku: string | null,
    tva_type: number,//
    price: number,//
    price_promo: number,
    description: string,//
    additional_description: string | null,
    linked_product: string | null,
    product_keywords: string,//
    weight: number,//
    slug: string,//
    height: number,//
    width: number,//
    depth: number,//
    quantity: number,//
    marque: string,//
    state_description: string,//
    conditioningType: string,//
    conditioningValue: string,//
    product_categories: [],//
    backorder: string | null,
}

interface ProductModification {
    id: number,
    product_name: string,//
    description: string,//
    product_sku: string | null,
    vendor_sku: string | null,
    price: number,//
    price_promo: number,
    price_visualised: number,//
    price_promo_visualised: number | null,
    quantity: number,//
    state: string,//
    state_description: string,//
    product_categories: [],//
    marque: string,//
    depth: number,//
    width: number,//
    height: number,//
    weight: number,//
    conditioningType: string,//
    conditioningValue: string,//
    additional_description: string | null,
    product_keywords: string,//
    slug: string,//
    tva_type: number,//
    linked_product: string | null,

}

export interface GlobalProduct {
    image: string,
    product: ProductInterface
}

async function getProductsAllPublished(limit = 6000, page = 1) {
    let get_products: any;
    if (limit !== 6000) {
        get_products = await fetchAPI(`product/allPublished?max=${limit}&page=${page}`, 'GET', false, true);
    } else {
        get_products = await fetchAPI(`product/allPublished`, 'GET', false, true);
    }

    return get_products.response;
}
async function getProductsAllPublishedCategory() {

    let get_products = await fetchAPI(`product/allPublishedCategory`, 'GET', false, true);


    return get_products.response;
}

async function getNewProducts() {
    // donne les derniers produits créés
    const products = await fetchAPI(`product/newProducts`, 'GET', false, true);
    return products.response
}

async function getProductsLastProductsCreated(limit = 6000, page = 1, minPrice?: number, maxPrice?: number, productState?: number) {
    let queryParams = `max=${limit}&page=${page}`;

    // Ajouter les filtres de prix à la requête si définis
    if (minPrice !== undefined) {
        queryParams += `&minPrice=${minPrice}`;
    }
    if (maxPrice !== undefined) {
        queryParams += `&maxPrice=${maxPrice}`;
    }

    // Ajouter le filtre d'état du produit à la requête si défini
    if (productState !== undefined) {
        queryParams += `&productState=${productState}`;
    }

    let get_products: any;
    if (limit !== 6000) {
        // Effectuer la requête API avec les filtres et la pagination
        get_products = await fetchAPI(`product/allLastCreated?${queryParams}`, 'GET', false, true);
    } else {
        get_products = await fetchAPI(`product/allLastCreated`, 'GET', false, true);
    }

    return get_products.response;
}

async function getProductsLastProductsPublished(limit = 6000, page = 1) {
    let get_products: any;
    if (limit !== 6000) {
        // donne les derniers produits ajoutés/modifiés mais faut faire un reverse après
        get_products = await fetchAPI(`product/allLastPublished?max=${limit}&page=${page}`, 'GET', false, true);
    } else {
        get_products = await fetchAPI(`product/allLastPublished`, 'GET', false, true);
    }

    return get_products.response;
}

async function addView(product_id: number){
    const addview = await fetchAPI("product/addView", 'POST', false, true, {
        product_id: product_id
    })
    return addview.response;
}
async function getView(){
    const addview = await fetchAPI("product/productViewOrders", 'GET', false, true)
    return addview.response;
}

async function getNumberAvailableProducts() {
    const get_number_available_products = await fetchAPI('product/number', 'GET', false, true)
    return get_number_available_products.response;
}

async function getNumberAvailableProductsByCategory(category: string) {
    const get_number_available_products = await fetchAPI('product/numberCategory/' + category, 'GET', false, true)
    return get_number_available_products.response;
}

async function getProductsWithSearch(productName: string, max?: number, page?: number, minPrice?: number, maxPrice?: number, productState?: number) {
    let get_products;
    const productStateParam = productState !== undefined ? `&productState=${productState}` : '';
    if (max && page) {
        get_products = await fetchAPI(`product/allPublished?name=${productName}&max=${max}&page=${page}&minPrice=${minPrice}&maxPrice=${maxPrice}${productStateParam}`, 'GET', false, true);
    } else {
        get_products = await fetchAPI(`product/allPublished?name=${productName}${productStateParam}`, 'GET', false, true);
    }
    return get_products.response;
}

export interface ProductSettingsInterface{
    id: number,
    activated: number,
    url: string,
    height : number,
    width: number,
    mobile: boolean,
    onclick: string,
    alt: string
}

async function getProductSettings(mobile: boolean) {
    let get_picture
    if (!mobile) {
        get_picture = await fetchAPI(`frontsettings/front_image/0`, 'GET', false, true);
    } else {
        get_picture = await fetchAPI(`frontsettings/front_image/1`, 'GET', false, true);
    }
    console.log(get_picture.response)
    return get_picture.response as ProductSettingsInterface;
}

async function getProductByID(id: number): Promise<ProductInterface> {

    const get_products = await fetchAPI(`product/${id}`, 'GET', false, true);
    if (get_products && get_products.response && get_products.response.product_infos) {

        return {
            id: get_products.response.product_infos.id,
            product_name: get_products.response.product_infos.product_name,
            price: get_products.response.product_infos.price,
            price_promo: get_products.response.product_infos.price_promo,
            description: get_products.response.product_infos.description,
            dimensions: get_products.response.product_infos.dimensions,
            quantity: get_products.response.product_infos.quantity,
            weight: get_products.response.product_infos.weight,
            product_sku: get_products.response.product_infos.product_sku,
            creation_date: get_products.response.product_infos.creation_date,
            product_image: get_products.response.product_infos.product_image,
            marque: get_products.response.product_infos.marque,
            vendor_sku: get_products.response.product_infos.vendor_sku,
            state: get_products.response.product_infos.state,
            state_description: get_products.response.product_infos.state_description,
            additional_description: get_products.response.product_infos.additional_description,
            conditioningType: get_products.response.product_infos.conditioningType,
            conditioningValue: get_products.response.product_infos.conditioningValue,
            product_categories: get_products.response.product_infos.product_categories,
            gallery: get_products.response.product_infos.gallery,
            product_keywords: get_products.response.product_infos.product_keywords,
            slug: get_products.response.product_infos.slug,
            tva: get_products.response.product_infos.has_tva,
            vendor_id: get_products.response.product_infos.vendor_id,
            has_tva: get_products.response.product_infos.has_tva,
            backorder: get_products.response.product_infos.backorder,
            commission: get_products.response.product_infos.commission,
            no_stock: get_products.response.product_infos.no_stock
        }
    } else {
        throw new Error('Product info not found or API returned an unexpected structure.');

    }
}


async function getProductByID_numéro2(id: number): Promise<ProductInterface> {

    const get_products = await fetchAPI(`product/${id}`, 'GET', false, true);

    return {
        id: get_products.response.product_infos.id,
        product_name: get_products.response.product_infos.product_name,
        price: get_products.response.product_infos.price,
        price_promo: get_products.response.product_infos.price_promo,
        description: get_products.response.product_infos.description,
        dimensions: get_products.response.product_infos.dimensions,
        quantity: get_products.response.product_infos.quantity,
        weight: get_products.response.product_infos.weight,
        product_sku: get_products.response.product_infos.product_sku,
        creation_date: get_products.response.product_infos.creation_date,
        product_image: get_products.response.product_infos.product_image,
        marque: get_products.response.product_infos.marque,
        vendor_sku: get_products.response.product_infos.vendor_sku,
        state: get_products.response.product_infos.state,
        state_description: get_products.response.product_infos.state_description,
        additional_description: get_products.response.product_infos.additional_description,
        conditioningType: get_products.response.product_infos.conditioningType,
        conditioningValue: get_products.response.product_infos.conditioningValue,
        product_categories: get_products.response.product_infos.product_categories,
        gallery: get_products.response.product_infos.gallery,
        product_keywords: get_products.response.product_infos.product_keywords,
        slug: get_products.response.product_infos.slug,
        tva: get_products.response.product_infos.has_tva,
        vendor_id: get_products.response.product_infos.vendor_id,
        has_tva: get_products.response.product_infos.has_tva,
        backorder: get_products.response.product_infos.backorder,
        commission: get_products.response.product_infos.commission,
        no_stock: get_products.response.product_infos.no_stock

    }

}


async function getProductBySlug(slug: string): Promise<ProductInterface> {
    let get_products
    if (localStorage.getItem("ga_session_id")) {
        get_products = await fetchAPI(`product/slug/${slug}?ga_session_id=` + localStorage.getItem("ga_session_id"), 'GET', false, true);
    } else {
        get_products = await fetchAPI(`product/slug/${slug}`, 'GET', false, true);
    }

    const commission = 1 + get_products.response.product_infos.commission.value / 100
    return {
        slug: slug,
        id: get_products.response.product_infos.id,
        product_name: get_products.response.product_infos.product_name,
        price: get_products.response.product_infos.has_tva ? get_products.response.product_infos.price * commission * 1.2 : get_products.response.product_infos.price * 1.18,
        price_promo: get_products.response.product_infos.has_tva ? get_products.response.product_infos.price_promo * commission * 1.2 : get_products.response.product_infos.price_promo * 1.18,
        description: get_products.response.product_infos.description,
        dimensions: get_products.response.product_infos.dimensions,
        quantity: get_products.response.product_infos.quantity,
        weight: get_products.response.product_infos.weight,
        product_sku: get_products.response.product_infos.product_sku,
        creation_date: get_products.response.product_infos.creation_date,
        product_image: get_products.response.product_infos.product_image,
        marque: get_products.response.product_infos.marque,
        vendor_sku: get_products.response.product_infos.vendor_sku,
        state: get_products.response.product_infos.state,
        state_description: get_products.response.product_infos.state_description,
        additional_description: get_products.response.product_infos.additional_description,
        conditioningType: get_products.response.product_infos.conditioningType,
        conditioningValue: get_products.response.product_infos.conditioningValue,
        product_categories: get_products.response.product_infos.product_categories,
        product_keywords: get_products.response.product_infos.product_keywords,
        tva: get_products.response.product_infos.has_tva,
        vendor_id: get_products.response.product_infos.vendor_id,
        gallery: get_products.response.product_infos.gallery.reduce((acc: number[], val: any) => {
            acc.push(val.id)
            return acc
        }, [] as number[]),
        has_tva: get_products.response.product_infos.has_tva,
        backorder: get_products.response.product_infos.backorder,
        commission: get_products.response.product_infos.commission,
        no_stock: get_products.response.product_infos.no_stock

    }
}

async function getProductByName(name: string): Promise<ProductInterface> {
    let get_products
    if (localStorage.getItem("ga_session_id")) {
        get_products = await fetchAPI(`product/name/${name}?ga_session_id=` + localStorage.getItem("ga_session_id"), 'GET', false, true);
    } else {
        get_products = await fetchAPI(`product/name/${name}`, 'GET', false, true);
    }
    const commission = 1 + get_products.response.product_infos.commission.value / 100
    return {
        slug: get_products.response.product_infos.slug,
        id: get_products.response.product_infos.id,
        product_name: get_products.response.product_infos.product_name,
        price: get_products.response.product_infos.has_tva ? get_products.response.product_infos.price * commission * 1.2 : get_products.response.product_infos.price * 1.18,
        price_promo: get_products.response.product_infos.has_tva ? get_products.response.product_infos.price_promo * commission * 1.2 : get_products.response.product_infos.price_promo * 1.18,
        description: get_products.response.product_infos.description,
        dimensions: get_products.response.product_infos.dimensions,
        quantity: get_products.response.product_infos.quantity,
        weight: get_products.response.product_infos.weight,
        product_sku: get_products.response.product_infos.product_sku,
        creation_date: get_products.response.product_infos.creation_date,
        product_image: get_products.response.product_infos.product_image,
        marque: get_products.response.product_infos.marque,
        vendor_sku: get_products.response.product_infos.vendor_sku,
        state: get_products.response.product_infos.state,
        state_description: get_products.response.product_infos.state_description,
        additional_description: get_products.response.product_infos.additional_description,
        conditioningType: get_products.response.product_infos.conditioningType,
        conditioningValue: get_products.response.product_infos.conditioningValue,
        product_categories: get_products.response.product_infos.product_categories,
        product_keywords: get_products.response.product_infos.product_keywords,
        tva: get_products.response.product_infos.has_tva,
        vendor_id: get_products.response.product_infos.vendor_id,
        gallery: get_products.response.product_infos.gallery.reduce((acc: number[], val: any) => {
            acc.push(val.id)
            return acc
        }, [] as number[]),
        has_tva: get_products.response.product_infos.has_tva,
        backorder: get_products.response.product_infos.backorder,
        commission: get_products.response.product_infos.commission,
        no_stock: get_products.response.product_infos.no_stock
    }
}

async function getVendorProduct() {
    const get_products = await fetchAPI(`product/allVendor`, 'GET', true, true);
    return get_products.response as GlobalProduct[];
}

async function getAllTVA() {
    const get_all_tva = await fetchAPI(`product/allTVA`,'GET', false, true);
    return get_all_tva.response.TVAType;
}

async function getAllCommission() {
    const get_all_commission = await fetchAPI(`product/allCommission`,'GET', false, true);
    return get_all_commission.response.AllCommission;
}

async function getCommissionProduct(product_id : number) {
    const get_product_commission = await fetchAPI(`product/getCommission/`+product_id,'GET', false, true);
    return get_product_commission.response.commission;
}


async function deleteProduct(product_id: number) {
    const get_products = await fetchAPI(`product/delete`, 'POST', true, false, {"id": [product_id]});
    if (get_products.status !== 200) {
        console.log(get_products.response)
        toast.error(t('Une erreur s\'est produite lors de la suppression de votre produit : ', get_products.response));
    } else {
        toast.success(t("Produit supprimé avec succès !"));

    }
    return get_products.response;
}

function generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

async function createProduct(product_infos: ProductCreation, images: Medias[]) {

    const minRange = 0;
    const maxRange = 10000000; // 10 millions
    const randomValue: number = generateRandomNumber(minRange, maxRange);
    product_infos.slug = product_infos.product_name.replaceAll(" ", "-")
    product_infos.slug = product_infos.slug + "-" + randomValue;

    const gallery: number[] = [];

    const image_featured = images[0];
    for (let i = 1; i < images.length; i++) {
        gallery.push(images[i].id);
    }

    const create_product = await fetchAPI('product/create', 'POST', true, true, {
        product_image: image_featured.id,
        product_image_gallery: gallery.join(),
        state: product_infos.state,
        product_name: product_infos.product_name,
        vendor_sku: product_infos.vendor_sku,
        product_sku: product_infos.product_sku,
        price: product_infos.price,
        price_promo: product_infos.price_promo,
        description: product_infos.description,
        additional_description: product_infos.additional_description,
        linked_products: product_infos.linked_product,
        weight: product_infos.weight,
        height: product_infos.height,
        width: product_infos.width,
        depth: product_infos.depth,
        quantity: product_infos.quantity,
        marque: product_infos.marque || "",
        state_description: product_infos.state_description,
        conditioningValue: "1",//product_infos.conditioningValue,
        conditioningType: "unite",//product_infos.conditioningType
        product_categories: product_infos.product_categories.join(),
        product_keywords: product_infos.product_name,
        slug: product_infos.slug,
        tva_type: "1",
        backorder: 'false'
    })

    if (create_product.status !== 201) {
        console.log(create_product)
        toast.error(t('Une erreur s\'est produite lors de la création de votre produit : ', create_product.response));
    } else {
        toast.success(t("Produit crée avec succès !"));
        setTimeout(function () {
            window.location.href = "/dashboard"
        }, 2500);

    }
    console.log("product_creation", create_product)

    // TODO IF vendor SKU existe déjà , pop-up

}


async function modifyProduct(product_infos: ProductModification, images: Medias[], vendor_id: number | undefined = undefined) {


    const gallery: number[] = [];

    const image_featured = images[0];
    for (let i = 1; i < images.length; i++) {
        gallery.push(images[i].id);
    }
    console.log(gallery, image_featured)

    const modify_product = await fetchAPI('product/' + product_infos.id + '/update', 'PUT', true, true, {
        product_image: image_featured.id,
        product_image_gallery: gallery.join(","),
        state: product_infos.state,
        product_name: product_infos.product_name,
        vendor_sku: "", //product_infos.vendor_sku,
        product_sku: product_infos.product_sku || "",
        price: product_infos.price,
        ...((product_infos.price_promo > 0.1 && {price_promo: product_infos.price_promo})),
        description: product_infos.description,
        additional_description: product_infos.additional_description || "",
        // linked_products: product_infos.linked_product,
        weight: product_infos.weight,
        height: product_infos.height,
        width: product_infos.width,
        depth: product_infos.depth,
        quantity: product_infos.quantity,
        marque: product_infos.marque,
        state_description: product_infos.state_description,
        conditioningValue: "1",//product_infos.conditioningValue,
        conditioningType: "unite", //product_infos.conditioningType,
        product_categories: product_infos.product_categories.join(),
        product_keywords: product_infos.product_name,
        slug: product_infos.slug,
        tva_type: "1",
        backorder: 'false',
        ...(vendor_id !== undefined && {vendor_id: vendor_id}),
    })

    if (modify_product.status !== 200) {
        // alert(modify_product.message)
        toast.error(t('Une erreur s\'est produite lors de la modification de votre produit : ', modify_product.message));
        return {
            status: 400,
            response: modify_product.message
        };
    } else {
        toast.success(t("Produit modifié avec succès !"));
        setTimeout(function () {
            window.location.href = "/dashboard"
        }, 2500);

        return {
            status: 200,
            response: modify_product.response
        }
    }


    // TODO IF vendor SKU existe déjà , pop-up
}

async function downloadProduct() {
    const filename = await fetchAPI("upload/export/xls", "GET", true, true)
    const link = "https://beta.captainchercheur.com/media/public/spreadsheet/" + filename.response.filename;
    const response = await fetch(link);
    // Lire le Blob à partir de la réponse
    const blob = await response.blob();

    // Créer un URL temporaire pour le Blob
    const url = window.URL.createObjectURL(blob);

    // Créer un élément <a> temporaire
    const a = document.createElement('a');

    // Configurer l'élément <a>
    a.href = url;
    a.download = filename.response.filename; // Nom du fichier à télécharger

    // "Cliquer" sur l'élément <a> pour déclencher le téléchargement
    document.body.appendChild(a);
    a.click();

    // Supprimer l'élément <a> et libérer l'URL du blob
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

async function telechargerFichierExemple(url: string, nomFichier: string) {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            // Créer un objet URL à partir du blob
            const url = window.URL.createObjectURL(blob);

            // Créer un élément de lien pour le téléchargement
            const a = document.createElement('a');
            a.href = url;
            a.download = nomFichier;

            // Ajouter l'élément de lien à la page et déclencher le téléchargement
            document.body.appendChild(a);
            a.click();

            // Supprimer l'élément de lien après le téléchargement
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
        .catch(error => console.error('Erreur de téléchargement :', error));
}


function uploadNewProducts() {

}

async function createProductsFromWordpress() {
    const options = {method: 'GET'};

    const response = await fetch('https://pro.captainchercheur.com/Functions/json_get_all_poseidon_products.php', options)
        .then(response => response.json())
        .then(response => {
            return response
        })
        .catch(err => console.error(err));
    console.log(response);
    // response.forEach((clientData: any) => {
    // Registration(clientData.billing_first_name, clientData.billing_last_name, 147003234, clientData.billing_email.toLowerCase(), generateRandomPassword(10))
    // createAddress({
    //     address_type : [1],
    //     city: clientData.city,
    //     country: "France",
    //     firstname: clientData.billing_first_name,
    //     lastname: clientData.billing_last_name,
    //     address : clientData.billing_address_1,
    //     zipcode: clientData.billing_postcode,
    //     phone: clientData.billing_postcode,
    //     region: clientData.region
    // })

    // createProduct({
    //     state: "",//
    //     product_name: "",//
    //     vendor_sku: "",
    //     product_sku: "",
    //     tva_type: "",//
    //     price: "",//
    //     price_promo: "",par
    //     description: "",//
    //     additional_description: "",
    //     linked_product: "",
    //     product_keywords: "",//
    //     weight: "",//
    //     slug: "",//
    //     height: "",//
    //     width: "",//
    //     depth: "",//
    //     quantity: "",//
    //     marque: "",//
    //     state_description: "",//
    //     conditioningType: "",//
    //     conditioningValue: "",//
    //     product_categories: [],//
    //     backorder:"",
    // } as unknown as ProductCreation, "","")
    // })
    return await response
}

async function getCurrentHighlightedProducts() {
    const highlighted_products = await fetchAPI('highlighted', 'GET', true, true)
    return highlighted_products.response as ProductInterface[]
}

async function addNewHighlightedProduct(product: ProductInterface[], start_time: string) {

    const highlighted_product = await fetchAPI('highlighted', 'POST', true, true, {
        products: product.map(product => product.id).join(),
        date_debut: start_time
    })
    return highlighted_product.response as ProductInterface
}

async function updateNewHighlightedProduct(product: ProductInterface[], start_time: string) {

    const highlighted_product = await fetchAPI('highlighted', 'POST', true, true, {
        products: product.map(product => product.id).join(),
        date_debut: start_time
    })
    return highlighted_product.response as ProductInterface
}

async function getUserFavorites() {

    const user_favorites = await fetchAPI('product/getFavorite', 'GET', true, true)
    if (user_favorites.response.length === 0) {
        return false;
    }
    return user_favorites.response[0]
}

async function getUserFavoriteByProductId(product_id: number) {

    const user_favorites = await fetchAPI('product/getFavorite', 'GET', true, true)
    if (user_favorites.response.length === 0) {
        return false;
    }
    const userResponse = user_favorites.response[0];
    return userResponse.favoris.some((favorisItem: { id: number; }) => favorisItem.id === product_id);
}

async function addProductFavorite(product_id: number) {
    const user_favorites = await fetchAPI('product/addFavorite', 'POST', true, true, {
        "id": product_id
    })
    return !!user_favorites;
}
async function addProductFavoriteFromSlug(product_slug: string) {
    const user_favorites = await fetchAPI('product/addFavoriteSlug', 'POST', true, true, {
        "slug": product_slug
    })
    return !!user_favorites;
}
async function removeProductFavorite(product_id: number) {
    const user_favorites = await fetchAPI('product/removeFavorite', 'POST', true, true, {
        "id": product_id
    })
    return !!user_favorites;
}
async function removeProductFavoriteFromSlug(product_slug: string) {
    const user_favorites = await fetchAPI('product/removeFavoriteSlug', 'POST', true, true, {
        "slug": product_slug
    })
    return !!user_favorites;
}
async function test_newsletter(products: number[],sujetEmail:string) {
    const user_favorites = await fetchAPI('mangopay/hooks/test_newsletter_preview', 'POST', true, true, {
        "products": products,
        "subject": sujetEmail
    })
    return !!user_favorites;
}
async function real_newsletter(products: number[],sujetEmail:string) {
    const user_favorites = await fetchAPI('mangopay/hooks/test_newsletter', 'POST', true, true, {
        "products": products,
        "subject": sujetEmail

    })
    return !!user_favorites;
}
//
async function getProductsAllPublishedNoFilters(limit = 6000, page = 1) {
    let get_products: any;
    if (limit !== 6000) {
        get_products = await fetchAPI(`product/allPublishedWithoutRestrictions?max=${limit}&page=${page}`, 'GET', false, true);
    } else {
        get_products = await fetchAPI(`product/allPublished`, 'GET', false, true);
    }

    return get_products.response;
}
export {
    test_newsletter,
    real_newsletter,
    createProduct,
    deleteProduct,
    getProductByID,
    getProductBySlug,
    getProductsAllPublished,
    getProductsWithSearch,
    getVendorProduct,
    modifyProduct,
    downloadProduct,
    createProductsFromWordpress,
    getNumberAvailableProducts,
    getProductsLastProductsPublished,
    telechargerFichierExemple,
    getProductByName,
    getProductByID_numéro2,
    getNumberAvailableProductsByCategory,
    getNewProducts,
    getCurrentHighlightedProducts,
    addNewHighlightedProduct,
    updateNewHighlightedProduct,
    getProductsLastProductsCreated,
    getUserFavorites,
    getUserFavoriteByProductId,
    addProductFavorite,
    removeProductFavorite,
    addProductFavoriteFromSlug,
    removeProductFavoriteFromSlug,
    getCommissionProduct,
    getProductSettings,
    getAllCommission,
    getAllTVA,
    addView,
    getView,
    getProductsAllPublishedCategory,
    getProductsAllPublishedNoFilters
}