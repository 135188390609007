import React, { ChangeEvent, useState } from 'react';
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';

const PhoneNumberProcessor: React.FC = () => {
    const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);

    const cleanPhoneNumber = (phoneNumber: string): string | null => {
        const cleanedNumber = phoneNumber.replace(/[\s.()]/g, '');
        const normalizedNumber = cleanedNumber.replace(/^\+33/, '0');
        return /^(06|07)/.test(normalizedNumber) ? normalizedNumber : null;
    };

    const processFile = async (file: File) => {
        return new Promise<string[]>((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                try {
                    const data = event.target?.result as string;
                    const workbook = XLSX.read(data, { type: 'binary' });

                    const processedNumbers: string[] = [];

                    workbook.SheetNames.forEach((sheetName) => {
                        const sheet = workbook.Sheets[sheetName];
                        const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                        sheetData.forEach((row: any) => {
                            if (row[0] && typeof row[0] === 'string' && !row[0].includes(',')) {
                                let cleanedNumber = cleanPhoneNumber(row[0].split(',')[0]);
                                if (cleanedNumber && !/^(01|02|03|04|05|08|09|001|002|003|004|005|008|009)/.test(cleanedNumber)) {
                                    processedNumbers.push(cleanedNumber);
                                }
                            }
                        });
                    });

                    resolve(processedNumbers);
                } catch (error) {
                    reject(error);
                }
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsBinaryString(file);
        });
    };

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target.files?.[0];

        if (fileInput) {
            try {
                const numbers = await processFile(fileInput);
                setPhoneNumbers(numbers);

                // Créer le fichier CSV
                const csvData = Papa.unparse(numbers.map((number) => [number]), { delimiter: ',' });
                const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.setAttribute('download', 'processed_numbers.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Erreur lors du traitement du fichier :', error);
            }
        }
    };

    return (
        <div>
            <h1>Phone Number Processor</h1>
            <label htmlFor="fileInput" style={{marginTop:'10px'}}>Sélectionnez un fichier XLSX : </label>
            <input type="file" id="fileInput" accept=".xlsx" onChange={handleFileChange} />
            {phoneNumbers.length > 0 && (
                <div>
                    <h2>Numéros de téléphone traités :</h2>
                    <ul>
                        {phoneNumbers.map((number, index) => (
                            <li key={index}>{number}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default PhoneNumberProcessor;
