import {Media} from "../Media";
import fetchAPI from "../Fetch";
import {getCommissionProduct} from "../Products/Products";

async function Highlights() {
    const handleHighlights = async () => {
        const get_highlights = await fetchAPI(`highlight/mobile`, 'GET', false, true);
        if (get_highlights.status === 200) {
            let products = get_highlights.response.products;
			// console.log(get_highlights.response.products)
            let products_array: {
                [key: number]: {
                    id: number,
                    product_image: string,
                    product_name: string,
                    product_description: string,
                    product_price: string | number,
                    product_price_promo: string | number,
                    slug: string,
                    has_tva : boolean
                }
            } = {};
            for (const product of products) {
                // console.log(product)
                const commission = await getCommissionProduct(product.id)
                const media = await Media(product.product_image)
                const response = await fetch(media);

                // Vérifier si la requête a réussi
                if (!response.ok) {
                    throw new Error('Impossible de récupérer l\'image');
                }

                // Convertir l'image en tableau de bytes
                const imageBytes = await response.arrayBuffer();

                // Vérifier la taille de l'image https://www.poseidonshop.fr/product/boucle-inox-a-barrette-40mm-6349108
                if (imageBytes.byteLength === 30184) {
                    // Si la taille de l'image est égale à 30 184 bytes, retourner l'URL de l'image
                   continue;
                } else {
                    products_array[product.id] = {
                        id: product.id,
                        product_image: await Media(product.product_image),
                        product_name: product.product_name,
                        product_description: product.description,
                        product_price: product.has_tva ? ( product.price * (1 + (commission.value /100)) * 1.2)?.toFixed(2) : (product.price * 1.18)?.toFixed(2),
                        product_price_promo: product.has_tva ? ( product.price_promo * (1 + (commission.value /100)) * 1.2)?.toFixed(2) : (product.price_promo * 1.18)?.toFixed(2),
                        slug: product.slug,
                        has_tva: product.has_tva
                    };
                }

            }
            return products_array;
        } else {
            return [];
        }
    }
    return await handleHighlights();
}

export async function createHightlight(date: string, products: number[]){
    const result = await fetchAPI("highlight", "POST", true, true,{
        date_debut:date.slice(1),
        products:products
    })
    return result
}

export default Highlights;
