import {getOrder, getVendorOrders, getVendorSubOrders, SubOrders} from "../Order";
import * as XLSX from 'xlsx';
import {getUserById} from "../User";
import {getUserAddress} from "../Address";
import {getProductByID} from "../Products/Products";

const ExportOrders = async () => {

    async function generateExcelFile(orders: SubOrders[]) {
        const wsDataPromises = orders.map(async order => {
            const orderinfo = await getOrder(order.order_id);
            const userinfo = await getUserById(orderinfo.buyer_id);
            if(userinfo && (order.state_id === 2 ||order.state_id === 3)){
            const addressinfo = await getUserAddress(orderinfo.buyer_delivery_id);
			const productinfos = await getProductByID(order.product_id);
            // console.log(order.expedition.price)
            return {
                "Date": new Date(orderinfo.creation_date * 1000 || 0).toLocaleDateString(),
                "N° de commande": order.order_id,
                "Email": userinfo.user_email,
                "Nom expédition": addressinfo.lastname,
                "Prénom expédition": addressinfo.firstname,
                "Adresse expédition": addressinfo.address,
                "Complément d'adresse expédition": addressinfo.additional_address,
                "Nom du point relais": '',
                "Code relais": addressinfo.relais,
                "Code postal expédition": addressinfo.zipcode,
                "Ville expédition": addressinfo.city,
                "Nom facturation": addressinfo.lastname,
                "Prénom facturation": addressinfo.firstname,
                "Téléphone facturation": addressinfo.phone_number,
                "Adresse facturation": addressinfo.address,
                "Code postal facturation": addressinfo.zipcode,
                "Ville facturation": addressinfo.city,
                "Référence du produit": productinfos.product_sku,
                "Désignation du produit": productinfos.product_name,
                "Quantité": order.quantity,
                "Prix de vente U HT": order.product_price_HT,
                "Prix de vente total HT": order.product_price_HT * order.quantity,
                "Commission HT": (order.product_price_TTC / 1.2) - ((order.product_price_TTC / 1.2) / 1.15),
                "Solde récupérable HT": (order.product_price_HT / 1.15)* order.quantity,
                "Solde récupérable TTC": (order.product_price_TTC/ 1.15)* order.quantity
            };
        }else {return {}}});

        // Attendre que toutes les promesses soient résolues
        const wsData = await Promise.all(wsDataPromises);

        const ws = XLSX.utils.json_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Orders");
        XLSX.writeFile(wb, "orders.xlsx");
    }

    const orders = await getVendorSubOrders();
    generateExcelFile(orders);


}

export {ExportOrders}