import PopupMessages from "../Misc/PopupMessages";
import fetchAPI from "../Fetch";
import {getUserRole} from "../User";
import React from "react";

export default async function Login(user_email: string, password: string) {
    user_email=user_email.toLowerCase()
    let get_token = await (fetchAPI(`auth/token`, 'POST', false,true, {
        'user_email': `${user_email}`,
        'user_pass': `${password}`
    }));

    if (get_token.status === 401) {console.log(get_token.message);PopupMessages(get_token.message)}

    else {
        const get_access_token = await fetchAPI(`auth/access-token`, 'POST',false ,true,{
            'token': `${get_token.response.token}`,
        });
        if (get_access_token.status !== 200) {
            PopupMessages(get_access_token.message)

        } else {
            localStorage.setItem('token', get_access_token.response.access_token);
            localStorage.setItem('user_id', get_access_token.response.id);
            localStorage.setItem('role', await getUserRole());
            let redirect = '/dashboard';

            const searchParams = new URLSearchParams(window.location.search);
            const paramValue = searchParams.get('pay');
            const paramRedirect = searchParams.get('redirect');
            if (paramValue) {
                window.location.href = '/pay';
            } else if (paramRedirect) {
                window.location.href = "/product/" + paramRedirect + "?favorite=true"

            } else {
                localStorage.getItem('redirect') ? redirect = localStorage.getItem('redirect') || '/dashboard' : redirect = '/dashboard'
                window.location.href = redirect;
            }



        }
    }
}