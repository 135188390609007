import {Button, Col, Input, Row, Select, Space, Table, Pagination, InputNumber} from "antd";
import React, {useEffect, useState} from "react";
import {EditOutlined, SearchOutlined, DeleteOutlined} from "@ant-design/icons";
import {User, UserInterface} from "../../User";
import {getVendorProduct, GlobalProduct, ProductInterface} from "../../Products/Products";

interface dataSourceInterface {
    key: string,
    id: string,
    productName: string,
    sku: string,
    stock: string,
    price: number,
    promoPrice: number,
    categories: string,
    date: string,
}

export default function Messages() {

    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"

    const [productInfos, setProductInfos] = useState({} as GlobalProduct[]);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);

    useEffect(() => {
        const fetchUserInfos = async () => {
            const products = await getVendorProduct();
            setProductInfos(products)
            try {
                const mappedOptions = products.map((product) => ({
                    key: product.product.id as unknown as string,
                    productName: product.product.product_name,
                    sku: product.product.product_sku,
                    date: new Date(product.product.creation_date * 1000).toDateString(),
                    categories: '',
                    id: product.product.id as unknown as string,
                    price: product.product.price,
                    promoPrice: product.product.price_promo,
                    stock: product.product.quantity > 0 ? "Stock" : "Hors stock",
                } as dataSourceInterface));
                setOptions(mappedOptions);
            } catch (error) {
                console.log("No user data found");
            }
            setDataFetched(true);
        };
        if (!dataFetched) {
            fetchUserInfos();
        }
    }, [dataFetched]);

    const onChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    const dataSource = options

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Produit',
            dataIndex: 'productName',
            key: 'productName',
            width: '15%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    <Button style={{margin: '5px'}} icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}/>
                    <Button style={{margin: '5px'}} icon={<DeleteOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}/>
                </div>
            )
        },
    ];


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const totalItems = dataSource.length;
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(rangeStart + itemsPerPage - 1, totalItems);

    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (current: any, size: any) => {
        setItemsPerPage(size);
    };

    const customPaginationStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const hoverStyle = {
        cursor: 'pointer',
        // Add your hover styles here, for example:
        backgroundColor: '#000000',
    };

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{color: colorHeaderText}}>
            {windowWidth < 768 ? (
                <div
                    style={{
                        margin: '0px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#F3F5F6",
                        borderRadius: '15px'
                    }}>
                    <h1 style={{fontSize: '24px'}}>Messages</h1>
                    {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    <div>
                        <h3 style={{
                            marginTop: '15px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            textAlign: 'center'
                        }}>Pour garantir une expérience agréable lors de l'utilisation de nos services, nous ne pouvons
                            afficher ces informations sur votre appareil.</h3>
                        <h3 style={{
                            marginTop: '15px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            textAlign: 'center'
                        }}>Nous nous excusons pour la gêne occasionnée.</h3>
                    </div>
                </div>
            ) : windowWidth >= 768 && windowWidth < 1100 ? (
                <div
                    style={{
                        margin: '0px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#F3F5F6",
                        borderRadius: '15px'
                    }}>
                    <div style={{margin: '15px'}}>
                        <h1 style={{fontSize: '24px'}}>Messages</h1>
                        {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    </div>
                    <div style={{
                        marginTop: '20px',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #FFFFFF',
                        borderRadius: '5px',
                        width: '95%',
                        margin: '20px auto'
                    }}>
                        <div style={{padding: '20px'}}>
                            <Row>
                                <Space.Compact style={{width: '95%'}}>
                                    <Input name='search' placeholder="Rechercher" style={{width: '100%'}}/>
                                    <Button type="primary" style={{
                                        color: colorHeaderText, backgroundColor:
                                        colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                    }}><SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                </Space.Compact>
                            </Row>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <style>
                                {`
                          .custom-table tbody {
                            height: 20px;
                            overflow-y: auto;
                          }
                          
                          /* Remove default padding on the table and related elements */
                          .custom-table .ant-table-thead tr th,
                          .custom-table .ant-table-tbody tr td {
                            padding: 5px;
                          }
                        `}
                            </style>
                            <Table
                                dataSource={dataSource.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
                                columns={columns}
                                bordered
                                pagination={false}
                                className="custom-table"
                                style={{margin: '0 20px'}}
                                scroll={{y: 500}} // Set the desired height here (e.g., 400px)
                            />
                        </div>
                        <div style={{margin: '20px'}}>
                            <style>
                                {`
                          .customPaginationStyle {
                             display: flex;
                             justify-content: center;
                             align-items: center;
                          }
                          
                          .customPaginationStyle : hover {
                             background-color : #000000
                          }
                        `}
                            </style>
                            <Pagination
                                total={totalItems}
                                current={currentPage}
                                showSizeChanger
                                onShowSizeChange={handleItemsPerPageChange}
                                onChange={handlePageChange}
                                showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} produits`}
                                pageSize={itemsPerPage}
                                defaultCurrent={1}
                                style={customPaginationStyle}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div style={{margin: '15px'}}>
                        <h1 style={{fontSize: '24px'}}>Messages</h1>
                        {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    </div>
                    <div style={{
                        marginTop: '20px',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #FFFFFF',
                        borderRadius: '5px',
                        width: '95%',
                        margin: '20px auto'
                    }}>
                        <div style={{padding: '20px'}}>
                            <Row>
                                <Space.Compact style={{width: '95%'}}>
                                    <Input name='search' placeholder="Rechercher" style={{width: '100%'}}/>
                                    <Button type="primary" style={{
                                        color: colorHeaderText, backgroundColor:
                                        colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                    }}><SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                </Space.Compact>
                            </Row>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <style>
                                {`
                          .custom-table tbody {
                            height: 20px;
                            overflow-y: auto;
                          }
                          
                          /* Remove default padding on the table and related elements */
                          .custom-table .ant-table-thead tr th,
                          .custom-table .ant-table-tbody tr td {
                            padding: 5px;
                          }
                        `}
                            </style>
                            <Table
                                dataSource={dataSource.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
                                columns={columns}
                                bordered
                                pagination={false}
                                className="custom-table"
                                style={{margin: '0 20px'}}
                                scroll={{y: 500}} // Set the desired height here (e.g., 400px)
                            />
                        </div>
                        <div style={{margin: '20px'}}>
                            <style>
                                {`
                          .customPaginationStyle {
                             display: flex;
                             justify-content: center;
                             align-items: center;
                          }
                          
                          .customPaginationStyle : hover {
                             background-color : #000000
                          }
                        `}
                            </style>
                            <Pagination
                                total={totalItems}
                                current={currentPage}
                                showSizeChanger
                                onShowSizeChange={handleItemsPerPageChange}
                                onChange={handlePageChange}
                                showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} produits`}
                                pageSize={itemsPerPage}
                                defaultCurrent={1}
                                style={customPaginationStyle}
                            />
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    );
}