import React, { useState, useEffect } from 'react';
import { Dropdown, Menu, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const Translate: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState<string>('fr');

    useEffect(() => {
        const storedLanguage = localStorage.getItem('lng') || 'fr';
        setSelectedLanguage(storedLanguage);
        i18n.changeLanguage(storedLanguage);
    }, [i18n]);

    const handleLanguageChange = (language: string) => {
        setSelectedLanguage(language);
        i18n.changeLanguage(language);

        localStorage.setItem('lng', language);
        window.location.reload();
    };

    const langMenu = (
        <Menu>
            <Menu.Item key="fr" onClick={() => handleLanguageChange('fr')}>
                {t('Français')}
            </Menu.Item>
            <Menu.Item key="en" onClick={() => handleLanguageChange('en')}>
                {t('English')}
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={langMenu} trigger={['click']}>
            <Space style={{cursor:'pointer'}}>
                <p style={{marginLeft: '10px'}}> {selectedLanguage === 'fr' ? t('Français') : t('English')} </p>
                <DownOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
            </Space>
        </Dropdown>
    );
};

export default Translate;
