import React, {useEffect, useState} from 'react';
import {AppstoreOutlined, MailOutlined, SettingOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Menu} from 'antd';
import EditProfile from "../Profile/EditProfile";
import {Content} from "antd/es/layout/layout";
import Password from "../Profile/Password";
import {User} from "../../User";
import Company from "../Profile/Company";
import CloseAccount from "../Profile/CloseAccount";
import Address from "../Profile/Adress";


export default function SettingsClient() {

    const items: MenuProps['items'] = [
        {
            label: 'Éditer le profil',
            key: 'profile',
        },
        {
            label: 'Adresses',
            key: 'address',
        },
        {
            label: 'Mot de passe',
            key: 'password',
        },
        {
            label: 'Fermer le Compte',
            key: 'close',
        },
    ];

    const [content, setCurrent] = useState('profile');

    const onClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{color: colorHeaderText}}>
            {windowWidth < 1100 ? (
                <div style={{margin: '0px 16px', padding: 12, minHeight: 280, background: "#F3F5F6", borderRadius: '15px',}}>
                    <h1 style={{fontSize: '24px'}}>Paramètres</h1>

                    <Menu onClick={onClick} selectedKeys={[content]} mode="horizontal" items={items}/>
                    {
                        content === "profile" ? (
                            <Content
                                style={{
                                    padding: 12,
                                    minHeight: 280,
                                    background: "#FFFFFF",
                                }}
                            >
                                <EditProfile/>
                            </Content>
                        ) : content === "address" ? (
                            <Content
                                style={{
                                    padding: 12,
                                    minHeight: 280,
                                    background: "#FFFFFF",
                                }}
                            >
                                <Address/>
                            </Content>
                        ) : content === "password" ? (
                            <Content
                                style={{
                                    padding: 12,
                                    minHeight: 280,
                                    background: "#FFFFFF",
                                }}
                            >
                                <Password/>
                            </Content>
                        ) : content === "company" ? (
                            <Content
                                style={{
                                    padding: 12,
                                    minHeight: 280,
                                    background: "#FFFFFF",
                                    alignItems: 'center',
                                    display: 'grid'
                                }}
                            >
                                <Company/>
                            </Content>
                        ) : content === "close" ? (
                            <Content
                                style={{
                                    padding: 12,
                                    minHeight: 280,
                                    background: "#FFFFFF",
                                }}
                            >
                                <CloseAccount/>
                            </Content>
                        ) : null
                    }
                </div>
                ) : (
                    <div>
                        <h1 style={{fontSize: '24px'}}>Paramètres</h1>

                        <Menu onClick={onClick} selectedKeys={[content]} mode="horizontal" items={items}/>
                        {
                            content === "profile" ? (
                                <Content
                                    style={{
                                        margin: '24px 16px',
                                        padding: 24,
                                        minHeight: 280,
                                        background: "#FFFFFF",
                                    }}
                                >
                                    <EditProfile/>
                                </Content>
                            ) : content === "address" ? (
                                <Content
                                    style={{
                                        margin: '24px 16px',
                                        padding: 24,
                                        minHeight: 280,
                                        background: "#FFFFFF",
                                    }}
                                >
                                    <Address/>
                                </Content>
                            ) : content === "password" ? (
                                <Content
                                    style={{
                                        margin: '24px 16px',
                                        padding: 24,
                                        minHeight: 280,
                                        background: "#FFFFFF",
                                    }}
                                >
                                    <Password/>
                                </Content>
                            ) : content === "close" ? (
                                <Content
                                    style={{
                                        margin: '24px 16px',
                                        padding: 24,
                                        minHeight: 280,
                                        background: "#FFFFFF",
                                    }}
                                >
                                    <CloseAccount/>
                                </Content>
                            ) : null
                        }
                    </div>
                )
            }
        </div>
    )
}