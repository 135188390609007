import * as xml from 'xml-js';
import {getProductsAllPublished} from "../../components/Products/Products";
import {ExportOrders} from "../../components/Misc/ExportOrders";
import EmailExtractor from "../../components/Misc/EmailExtractor";
import {getAllCarts} from "../../components/Cart";
import ExcelUploader from "../../components/Misc/Chronopost";
import AllVendorOrders from "../../components/Expedition/allMonth";
import {ExportProductsInstagram} from "../../components/Misc/exportProductsInstagram";
import {ExportUsers} from "../../components/Misc/exportUsers";
import ExcelFileConverter from "../../components/Misc/getCorrespondingProducts";
import {
    DownloadAllOrders,
    DownloadAllOrdersByDate,
} from "../../components/Dashboard/Vendor/downloadProducts";
import {AutoComplete, Button, Card, Col, Row, Select, Space, Statistic} from "antd";
import {getAllVendors, VendorInterface} from "../../components/User";
import {useEffect, useState} from "react";
import ExtractorEmails from "../../components/Misc/extractEmails";
import Translation from "../../components/Misc/translate";
import PhoneNumberProcessor from "../../components/Misc/PhoneNumberProcessor";
import RemoveDuplicates from "../../components/Misc/removeDuplicates";
import {createUserExport} from "../../components/Media";
import DownloadButton from "../../components/Misc/filesaver";
import {ArrowDownOutlined, ArrowUpOutlined} from '@ant-design/icons';
import {Autocomplete} from "@react-google-maps/api";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';


const generateXML = async () => {
    const products = await getProductsAllPublished()
    let datas: any[] = [];

    products.forEach((product: any) => {

        datas.push({
            id: product.product.id,
            title: product.product.product_name,
            description: product.product.description,
            link: "https://poseidonshop.fr/product/" + product.product.slug,
            image_link: "https://beta.captainchercheur.com/" + product.image.replace("/product/", "/products/"),
            condition: product.product.state !== 1 ? "used" : "new",
            availability: product.product.quantity === 0 ? "out_of_stock" : "in_stock",
            price: product.product.has_tva ? product.product.price * 1.15 * 1.2 : product.product.price * 1.18,
            sku: product.product.product_sku,
            brand: product.product.marque,

            ...(product.product.price_promo && {sale_price: product.product.has_tva ? product.product.price_promo * 1.15 * 1.2 : product.product.price_promo * 1.18})
        })

    })


    const xmlObj = {
        _declaration: {_attributes: {version: '1.0', encoding: 'utf-8'}},
        rss: {
            _attributes: {'xmlns:g': 'http://base.google.com/ns/1.0', version: '2.0'},
            channel: {
                title: 'Poseidonshop',
                link: 'https://poseidonshop.fr',
                description: 'Poseidonshop feed',
                item: datas.map((item: any) => ({
                    'g:id': item.id,
                    'g:title': item.title,
                    'g:description': item.description,
                    'g:link': item.link,
                    'g:image_link': item.image_link,
                    'g:condition': item.condition,
                    'g:availability': item.availability,
                    'g:price': item.price?.toFixed(2) + " EUR",
                    'g:sku': item.sku !== '0' ? item.sku : '',
                    'g:mpn': item.sku !== '0' ? item.sku : '',
                    'g:brand': item.brand !== 'NC' ? item.brand : '',
                    ...(item.sale_price?.toFixed(2) && {'g:sale_price': item.sale_price?.toFixed(2) + ' EUR'})
                })),
            },
        },
    };


    const xmlString = xml.js2xml(xmlObj, {compact: true, ignoreComment: false, spaces: 4});

    // Créer un Blob à partir de la chaîne XML
    const blob = new Blob([xmlString], {type: 'text/xml'});

    // Créer une URL pour le Blob
    const url = URL.createObjectURL(blob);
    //window.location.href = url;
    // Créer un élément "a" et l'ajouter au DOM
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'google-merchant.xml';

    document.body.appendChild(a);
    a.click();

    // Supprimer l'élément "a" et libérer l'URL du blob
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};
const getMonthLabel = (monthNumber: number) => {
    const monthLabels = [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
    ];

    return monthLabels[monthNumber - 1] || '';
};
const monthLabelsToNumbers: Record<string, number> = {
    'janvier': 1,
    'février': 2,
    'mars': 3,
    'avril': 4,
    'mai': 5,
    'juin': 6,
    'juillet': 7,
    'août': 8,
    'septembre': 9,
    'octobre': 10,
    'novembre': 11,
    'décembre': 12,
};

export default function XML() {
    const [vendors, setVendors] = useState<VendorInterface[]>([]);
    const [vendorsFetch, setVendorsFetch] = useState(false)
    const today = new Date();
    const currentMonth = getMonthLabel(today.getMonth() + 1);
    const lastMonth = getMonthLabel(today.getMonth() <= 0 ? 12 : today.getMonth());
    const twoMonthsAgo = getMonthLabel(today.getMonth() - 1 < 0 ? 11 : today.getMonth() - 1);
    const threeMonthsAgo = getMonthLabel(today.getMonth() - 2 < 0 ? 10 : today.getMonth() - 2);
    const fourMonthsAgo = getMonthLabel(today.getMonth() - 3 < 0 ? 9 : today.getMonth() - 3);

    const [selectedMonth, setSelectedMonth] = useState<string>(getMonthLabel(today.getMonth()));
    const [selectedEmail, setSelectedEmail] = useState<string>('');


    useEffect(() => {
        async function fetchVendors() {
            const vendors = await getAllVendors();
            if (Array.isArray(vendors)) {
                setVendors(vendors);
                setVendorsFetch(true);
            }
        }

        if (!vendorsFetch) {
            fetchVendors()
        }
    }, [vendors, vendorsFetch])

    const [filename, setFilename] = useState("")
    return (
        <>

            <GooglePlacesAutocomplete
                apiKey="AIzaSyBVrqGDBqE85jvuaOO6-bXBTywbfCzoET0"
            />
            {/*AIzaSyBVrqGDBqE85jvuaOO6-bXBTywbfCzoET0*/}
            <Row gutter={16}>
                <Col span={4}>
                    <Card bordered={false}>
                        <Statistic
                            title="Active"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={4}>
                    <Card bordered={false}>
                        <Statistic
                            title="Idle"
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                            suffix="%"
                        />
                    </Card>
                </Col>
            </Row>
            <div style={{margin:'10px'}}>
                <PhoneNumberProcessor/>
            </div>
            <div>
                <Row>
                    <button onClick={generateXML} style={{margin:'10px'}}>Générer XML</button>
                    <button onClick={ExportOrders} style={{margin:'10px'}}>Générer excel</button>
                    {/*<button onClick={ExportProductsInstagram}>Générer all products</button>*/}
                    <button onClick={ExportUsers} style={{margin:'10px'}}>Générer all users</button>
                </Row>
                <Row>
                    <Button onClick={() => (createUserExport("xlsx", 1).then(value => {
                        setFilename(value.value)
                    }))} style={{margin:'10px'}}>Télécharger les vendeurs
                    </Button>
                    <Button onClick={() => (createUserExport("xlsx", 0).then(value => {
                        setFilename(value.value)
                    }))} style={{margin:'10px'}}>Télécharger les acheteurs
                    </Button>
                    <DownloadButton fileName={filename}/>
                </Row>

                {/*<RemoveDuplicates/>*/}
                {/*<button onClick={()=>{getAllVendorOrders()}}>Générer excel</button>*/}
                {/*<button onClick={getAllCarts}>Get All Carts</button>*/}
                <Row style={{margin:'10px'}}>
                    <AllVendorOrders/>
                </Row>
                {/*<ExcelUploader/>*/}
                {/*<EmailExtractor/>*/}
                {/*<ExcelFileConverter/>*/}
                {/*<ChronoRelaisSelect/>*/}
            </div>
            <div>
                {/*<DownloadAllOrders/>*/}
            </div>
            <div>
                <Space.Compact block>
                    <Select showSearch style={{width: 350,margin:'10px'}}
                            placeholder="Sélectionnez un fournisseur"
                            optionFilterProp="searchKey"  // Utiliser une nouvelle propriété pour la recherche
                            value={selectedEmail}
                            onChange={(value) => setSelectedEmail(value)}
                            filterOption={(input, option) =>
                                // Utiliser la nouvelle propriété pour la recherche
                                (option?.searchKey as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                        {vendors.map((vendor: VendorInterface) => (
                            <Select.Option key={vendor.id} value={vendor.id.toString()} searchKey={`${vendor.user_email} ${vendor.firstname} ${vendor.lastname}`}>
                                {vendor.user_email}
                            </Select.Option>
                        ))}
                    </Select>
                    <div style={{margin:'10px'}}>
                        <DownloadAllOrdersByDate selectedMonth={monthLabelsToNumbers[selectedMonth.toLowerCase()]} vendor_id={selectedEmail as unknown as number}/>
                    </div>

                    <Select style={{width: 200, margin:'10px'}} value={selectedMonth} onChange={(value) => setSelectedMonth(value)}>
                        <Select.Option value={currentMonth}>{currentMonth}</Select.Option>
                        <Select.Option value={lastMonth}>{lastMonth}</Select.Option>
                        <Select.Option value={twoMonthsAgo}>{twoMonthsAgo}</Select.Option>
                    </Select>
                </Space.Compact>
                <Space.Compact block>
                    <div style={{margin:'10px'}}>Dropship</div>
                    <Select
                        showSearch
                        style={{ width: 350,margin:'10px'}}
                        placeholder="Sélectionnez un fournisseur"
                        optionFilterProp="searchKey"
                        value={selectedEmail}
                        onChange={(value) => setSelectedEmail(value)}
                        filterOption={(input, option) =>
                            (option?.searchKey as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {vendors
                            .filter((vendor: VendorInterface) => vendor.id === 1824 || vendor.id === 1961)
                            .map((vendor: VendorInterface) => (
                                <Select.Option
                                    key={vendor.id}
                                    value={vendor.id.toString()}
                                    searchKey={`${vendor.user_email} ${vendor.firstname} ${vendor.lastname}`}
                                >
                                    {vendor.user_email}
                                </Select.Option>
                            ))}
                    </Select>

                    {/*1824*/}
                    <div style={{margin:'10px'}}>
                        <DownloadAllOrdersByDate selectedMonth={monthLabelsToNumbers[selectedMonth.toLowerCase()]}
                                                 vendor_id={selectedEmail as unknown as number}/>
                    </div>
                    <Select

                        style={{width: 200,margin:'10px'}}
                        value={selectedMonth}
                        onChange={(value) => setSelectedMonth(value)}
                    >
                        <Select.Option value={currentMonth}>{currentMonth}</Select.Option>
                        <Select.Option value={lastMonth}>{lastMonth}</Select.Option>
                        <Select.Option value={twoMonthsAgo}>{twoMonthsAgo}</Select.Option>
                        <Select.Option value={threeMonthsAgo}>{threeMonthsAgo}</Select.Option>
                        <Select.Option value={fourMonthsAgo}>{fourMonthsAgo}</Select.Option>
                    </Select>
                </Space.Compact>
            </div>

            {/*<div>Extraction des mails : <ExtractorEmails/></div>*/}
            {/*<div>*/}
            {/*    Adresse mail des vendeurs*/}
            {/*    {vendors.map((vendor: VendorInterface) => (*/}
            {/*        <p>{vendor.user_email}</p>*/}
            {/*    ))}*/}
            {/*</div>*/}
            {/*<div>Traduction : <Translation/></div>*/}
            {/*<iframe width="600" height="2125" src="https://lookerstudio.google.com/embed/reporting/5a765263-7db5-495a-9e7d-27723fd785a5/page/p_m63q9t54bd" allowFullScreen></iframe>*/}
        </>

    );
}
