import PopupMessages from "../../components/Misc/PopupMessages";
import {useTranslation} from "react-i18next";
import {Capacitor} from "@capacitor/core";
import { CapacitorHttp  } from '@capacitor/core';

function Registration(firstName: string, lastName: string, birthdate: number, user_email: string, password: string) {
    const handleRegistration = async () => {
        const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY3Yzc1ZTQxYzU4ZjRhNDE5YzJlYTJhMDA0YmI1OTE3IiwiaWF0IjoxNjg5Njc0MTE3fQ.0aipVBmJcrJYteP7RbKesTUTPKtr0UoRgeR3jYeDwvQ";
        const isNative = Capacitor.isNativePlatform();
        if (!isNative) {
            fetch(`https://beta.captainchercheur.com/users`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'user_email': `${user_email}`,
                    'user_pass': `${password}`,
                    'firstname': `${firstName}`,
                    'lastname': `${lastName}`,
                    'birthdate': birthdate,
                })
            })
                .then(response => response.json())
                .then(data => {
                    console.log("data create account tel ", data)
                    if (data.status === 400 || data.status === 404 || data.status === 500) {
                        if (data.message === "Failed to send email.") {
                            PopupMessages("invalid_email")
                        }
                        if (data.message === "Bad request. User already has email" || data.message === "Not found. User already has email" || data.message === "Not found. L'utilisateur a déjà un e-mail") {
                            PopupMessages("user_already_exists")
                        } else if (!/\S+@\S+\.\S+/.test(data.response)) {
                            const response = data.response;
                            const PropertyName = Object.keys(response);
                            for (const firstPropertyName of PropertyName) {
                                if (firstPropertyName) {
                                    // const value = response[firstPropertyName];
                                    let erreur = ""
                                    if (firstPropertyName === "firstname") {
                                        erreur = 'prénom'
                                    }
                                    if (firstPropertyName === "lastname") {
                                        erreur = 'nom'
                                    }
                                    if (firstPropertyName === "email") {
                                        erreur = 'email'
                                    }
                                    if (firstPropertyName === "password") {
                                        erreur = 'mot de passe'
                                    }
                                    if (firstPropertyName === "birthdate") {
                                        erreur = 'date de naissance'
                                    }
                                    PopupMessages('Il y a un problème avec votre' + erreur)
                                    console.log(`La propriété qui rencontre une erreur est + firstPropertyName}`);
                                } else {
                                    console.log('Aucune propriété trouvée dans data.response');
                                }
                            }

                        }
                    } else {

                        const searchParams = new URLSearchParams(window.location.search);
                        const paramValue = searchParams.get('pay');
                        if (paramValue) {
                            PopupMessages("user_created")
                            setTimeout(function () {
                                window.location.href = '/login?pay=true';
                            }, 1500);
                        } else {
                            PopupMessages("user_created")
                            setTimeout(function () {
                                window.location.href = '/login';
                            }, 2500);
                        }


                    }
                })
                .catch(error => console.error('Add to cart error:', error));
        } else {

            const options = {
                url: 'https://beta.captainchercheur.com/users',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },

                data: {
                    user_email: user_email,
                    user_pass: password,
                    firstname: firstName,
                    lastname: lastName,
                    birthdate: birthdate,
                },
            };

            try {
                const response = await CapacitorHttp.post(options);
                const data = response.data;
                console.log('data create account tel', data);

                if (data.status === 400 || data.status === 404 || data.status === 500) {
                    if (data.message === 'Failed to send email.') {
                        PopupMessages('invalid_email');
                    }
                    if (
                        data.message === 'Bad request. User already has email' ||
                        data.message === 'Not found. User already has email' ||
                        data.message === "Not found. L'utilisateur a déjà un e-mail"
                    ) {
                        PopupMessages('user_already_exists');
                    } else if (!/\S+@\S+\.\S+/.test(data.response)) {
                        const response = data.response;
                        const PropertyName = Object.keys(response);
                        for (const firstPropertyName of PropertyName) {
                            if (firstPropertyName) {
                                let erreur = '';
                                if (firstPropertyName === 'firstname') {
                                    erreur = 'prénom';
                                }
                                if (firstPropertyName === 'lastname') {
                                    erreur = 'nom';
                                }
                                if (firstPropertyName === 'email') {
                                    erreur = 'email';
                                }
                                if (firstPropertyName === 'password') {
                                    erreur = 'mot de passe';
                                }
                                if (firstPropertyName === 'birthdate') {
                                    erreur = 'date de naissance';
                                }
                                PopupMessages('Il y a un problème avec votre ' + erreur);
                                console.log(`La propriété qui rencontre une erreur est ${firstPropertyName}`);
                            } else {
                                console.log('Aucune propriété trouvée dans data.response');
                            }
                        }
                    }
                } else {
                    const searchParams = new URLSearchParams(window.location.search);
                    const paramValue = searchParams.get('pay');
                    if (paramValue) {
                        PopupMessages('user_created');
                        setTimeout(() => {
                            window.location.href = '/login?pay=true';
                        }, 1500);
                    } else {
                        PopupMessages('user_created');
                        setTimeout(() => {
                            window.location.href = '/login';
                        }, 2500);
                    }
                }
            } catch (error) {
                console.error('Add to cart error:', error);
            }
        }
    }

    return handleRegistration();

}

export default Registration;