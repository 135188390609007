import React, {useEffect, useRef, useState} from "react";
import {Avatar, Button, Col, Divider, Form, Input, Row, Select, DatePicker, Upload, message, InputRef} from "antd";
import { DeleteOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { updateUserInfos, UserRayan,UpdateUserInfosInterfaceRayan } from "../../User";
import { toast, ToastContainer } from "react-toastify";
import { t } from "i18next";
import dayjs from "dayjs";
import {DatePickerProps} from "antd/lib/date-picker";


export default function EditProfile() {

    const [form] = Form.useForm();
    const colorHeaderVerticalBar = "#EAEAEA";
    const colorHeaderText = "#09154C";
    const colorHearderSearch = "rgba(0,0,0,0.0)";
    const colorProductBtn = "#7ED957";
    const [userInfos, setUserInfos] = useState({} as UpdateUserInfosInterfaceRayan);
    const [dataFetched, setDataFetched] = useState(false);
    const [initialValues, setInitialValues] = useState({} as any);

    // Custom Date Formats
    const formatDate = (timestamp: number) => {
        return dayjs.unix(timestamp); // Use dayjs.unix for converting timestamp to dayjs object
    };

    const parseDate = (date: any) => {
        return dayjs(date).startOf("day").unix(); // Convert to timestamp (seconds) without time
    };


    useEffect(() => {
        const fetchUserInfos = async () => {
            try {
                const data = await UserRayan();
                if (data) {
                    setUserInfos(data);
                    setDataFetched(true);
                    const initial = {
                        firstname: data.firstname,
                        lastname: data.lastname,
                        user_email: data.user_email,
                        birthdate: formatDate(data.birthdate),
                    };
                    form.setFieldsValue(initial);
                    setInitialValues(initial);  // Stocker les valeurs initiales

                    // adapte width input email
                    const length_email = data.user_email.length;
                    console.log(length_email)
                    setInputWidth(length_email + 2);

                }
            } catch (error) {
                console.log("No user data found");
            }
        };

        if (!dataFetched) {
            fetchUserInfos();
        }
    }, [dataFetched, form]);


    const onFinish = async (values: any) => {
        const currentValues = {
            ...values,
            // birthdate: values.birthdate ? parseDate(values.birthdate) : undefined,
        };
        console.log("old infos -> ",initialValues)

        // Find the fields that have changed
        const modifiedFields: any = {};

        for (const key in currentValues) {
            if (currentValues[key] !== initialValues[key]) {
                modifiedFields[key] = currentValues[key];
            }
        }

        // Vérifier si les valeurs actuelles sont différentes des valeurs initiales
        if (Object.keys(modifiedFields).length > 0) { //if (JSON.stringify(currentValues) !== JSON.stringify(initialValues)) {
            try {
                if (modifiedFields.birthdate){
                    modifiedFields.birthdate=parseDate(modifiedFields.birthdate)
                }
                console.log("new infos edit -> ",modifiedFields)
                const userId = userInfos.user_id;
                const response = await updateUserInfos(userId, modifiedFields);
                console.log("La reponse API -> ",response)
                if (response.status === 200) {
                    toast.success('Modifications effectuées avec succès');
                }
                else {
                    toast.error('Une erreur s\'est produite lors de la modification de vos infos');
                }
            } catch (error) {
                console.error('Une erreur s\'est produite lors de la modification de vos infos : ', error);
            }
        } else {
            toast.info('Aucune modification détectée');
        }
    };


    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const inputRef = useRef<InputRef>(null);
    const [inputWidth, setInputWidth] = useState<number>(20);



    const handleInputChange = () => {
        if (inputRef.current?.input) {
            const length = inputRef.current.input.value.length;
            console.log(length)
            setInputWidth(length ? length + 2 : 20);
        }
    };

    return (
        <div style={{color: colorHeaderText,}}>
            <style>
                {`
                .ant-row.ant-form-item-row.css-dev-only-do-not-override-txh9fw{
                display : contents !important ;
                }      
                :where(.css-dev-only-do-not-override-txh9fw).ant-form-item .ant-form-item-label >label::after {
                content : "" !important ;     
               `}
            </style>
            <ToastContainer />
                <div>
                    <Form onFinish={onFinish} initialValues={{ remember: true }} form={form}>
                        <div style={{display:"none"}}>
                            <Row style={{ width: '50%', alignItems: 'center' }}>
                                <Col style={{ display: 'flex', marginRight: '20px' }}>
                                    <Avatar
                                        shape="square"
                                        size={64}
                                        icon={<UserOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                                    />
                                </Col>
                                <Col flex={1}>
                                    <p style={{ marginBottom: '5px' }}><b>Avatar</b></p>
                                    <p style={{ marginBottom: '5px' }}>PNG ou JPG de moins de 800px de large</p>
                                    <Upload showUploadList={true}>
                                        <Button
                                            type="primary"
                                            style={{
                                                color: colorHeaderText,
                                                backgroundColor: colorHearderSearch,
                                                border: '1px solid ' + colorHeaderVerticalBar,
                                                height: '40px',
                                                marginRight: '10px'
                                            }}
                                        >
                                            <UploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                        </Button>
                                    </Upload>
                                    <Button type="primary" style={{
                                        color: colorHeaderText,
                                        backgroundColor: colorHearderSearch,
                                        border: '1px solid ' + colorHeaderVerticalBar,
                                        height: '40px',
                                        marginRight: '10px'
                                        }}>
                                        <DeleteOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        {/*<Divider />*/}
                        <Row style={{ marginBottom: '20px' }}>
                                <Form.Item name="firstname" label="Prénom">
                                    <Input placeholder="Prénom" allowClear={true} maxLength={64} showCount={true}/>
                                </Form.Item>
                        </Row>
                        <Row style={{ marginBottom: '20px' }}>
                                <Form.Item name="lastname" label="Nom de famille">
                                    <Input placeholder="Nom" allowClear={true} maxLength={64} showCount={true}/>
                                </Form.Item>
                        </Row>

                        <Row style={{ marginBottom: '20px' }}>
                                <Form.Item name="user_email" label="Adresse mail (identifiant)">

                                    <Input
                                        placeholder="Adresse email"
                                        allowClear={true}
                                        ref={inputRef}
                                        onInput={handleInputChange}
                                        style={{ width: `${inputWidth}ch` }} // Application de la largeur dynamique
                                    />
                                </Form.Item>

                        </Row>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col flex={1}>
                                <Form.Item name="birthdate" label="Date de naissance">
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ justifyContent: 'space-between',marginBottom: '60px' }}>
                            <Button type="primary" style={{color: colorHeaderText,
                                backgroundColor: colorProductBtn,
                                border: '1px solid ' + colorHeaderVerticalBar,
                                height: '40px'}} htmlType="submit">Mettre à Jour</Button>
                        </Row>
                    </Form>
                </div>
        </div>
    )
}