import React, {useEffect, useState} from 'react';
import {Modal, Table, Button, Spin, message} from 'antd';
import {HeartOutlined, DeleteOutlined} from '@ant-design/icons';
import {getUserFavorites, removeProductFavorite} from './Products';

interface dataSourceInterface {
    slug: string;
    product_name: string;
    id: number;
    product_image: string;
}

const FavoriteModalMobile: React.FC = () => {
    const [productFavoris, setProductFavoris] = useState<dataSourceInterface[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state to handle async operations
    const [favoriteCount, setFavoriteCount] = useState<number>(0); // State for the favorite count
    const [firstTime, setFirstTime] = useState(false)
    useEffect(() => {
        if (isModalOpen || !firstTime) {
            const fetchProduct = async () => {
                setLoading(true);
                if (localStorage.getItem('user_id')) {
                    try {
                        const user_favorites = await getUserFavorites();
                        console.log('user_favorites:', user_favorites);

                        if (user_favorites && user_favorites.favoris) {
                            console.log('user_favorites.favoris:', user_favorites.favoris);

                            if (user_favorites.favoris.length > 0) {
                                const array_products: dataSourceInterface[] = user_favorites.favoris.map((favoris_product: {
                                    slug: any;
                                    product_name: any;
                                    id: any;
                                    product_image: any;
                                }) => ({
                                    slug: favoris_product.slug,
                                    product_name: favoris_product.product_name,
                                    id: favoris_product.id,
                                    product_image: favoris_product.product_image,
                                }));
                                console.log('array_products:', array_products);
                                setProductFavoris(array_products);
                                setFavoriteCount(array_products.length); // Update the favorite count
                                setFirstTime(true)
                            } else {
                                setProductFavoris([]);
                                setFavoriteCount(0); // No favorites
                            }
                        } else {
                            console.log('No favorites found or invalid response.');
                            setProductFavoris([]);
                            setFavoriteCount(0); // No favorites
                        }
                    } catch (error) {
                        console.error('Failed to fetch favorites:', error);
                        message.error('Failed to load favorites');
                        setProductFavoris([]);
                        setFavoriteCount(0); // On error, assume no favorites
                    }
                } else {
                    window.location.href = '/login';
                }
                setLoading(false);
            };

            fetchProduct();
        }
    }, [isModalOpen]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleProductClick = (product: dataSourceInterface) => {
        window.location.href = '/shop/' + product.slug
    };

    const handleRemoveFavorite = async (id: number) => {
        try {
            await removeProductFavorite(id); // Assurez-vous que removeFavorite est une fonction async qui supprime le favori
            setProductFavoris(prevFavoris => {
                const updatedFavoris = prevFavoris.filter(product => product.id !== id);
                setFavoriteCount(updatedFavoris.length); // Update the favorite count
                return updatedFavoris;
            });
            message.success('Article supprimé des favoris');
        } catch (error) {
            message.error('Impossible de supprimer l\'article des favoris');
        }
    };

    const handleShopRedirect = () => {
        window.location.href = '/shop'; // Remplacez '/shop' par l'URL de votre page boutique
    };

    const columns = [
        {
            title: 'Product Image',
            dataIndex: 'product_image',
            key: 'product_image',
            render: (text: string) => <img style={{width: '100px'}}
                                           src={`https://beta.captainchercheur.com/media/public/product/${text}`}
                                           alt="Product"/>
        },
        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text: string, record: dataSourceInterface) => (
                <a onClick={() => handleProductClick(record)} style={{color: '#09154C', textDecoration: 'underline'}}>
                    {text}
                </a>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: dataSourceInterface) => (
                <Button
                    icon={<DeleteOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                    onClick={() => handleRemoveFavorite(record.id)}
                    danger
                    style={{
                        display: 'flex', alignItems: 'center', padding: '0 8px', WebkitTapHighlightColor: 'transparent' // Ajoutez cette ligne
                    }}
                >
                </Button>
            ),
        },
    ];

    const colorHeaderText = 'white';

    return (
        <>
            <div
                onClick={showModal}
                style={{
                    display: 'inline-flex',
                    cursor: 'pointer',
                    paddingRight: "10px",
                    WebkitTapHighlightColor: 'transparent' // Ajoutez cette ligne
                }}
            >
                <HeartOutlined
                    style={{
                        fontSize: '26px',
                        color: colorHeaderText,
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginRight: '10px',
                        transform: 'scaleX(-1)',
                        position: 'relative'
                    }}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                />
                {favoriteCount > 0 && (
                    <span style={{
                        position: 'absolute',
                        top: '60px', // Adjust this value to bring it closer to the top of the heart icon
                        right: '25px', // Adjust this value to bring it closer to the right edge of the heart icon
                        backgroundColor: '#FF4D4F',
                        color: 'white',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        transform: 'translate(50%, -50%)' // This adjustment centers the counter over the heart icon
                    }}>
                        {favoriteCount}
                    </span>
                )}
            </div>

            <Modal
                title="Mes favoris"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={"90%"}
                style={{marginBottom: "40%"}}
                bodyStyle={{padding: 0}}
            >
                {loading ? (
                    <Spin tip="Chargement..."/>
                ) : productFavoris.length > 0 ? (
                    <Table
                        dataSource={productFavoris}
                        columns={columns}
                        rowKey="id"
                        pagination={false}
                        showHeader={false}
                        bordered={false}
                    />
                ) : (
                    <div style={{textAlign: 'center', padding: '20px'}}>
                        <p>Vous n'avez pas encore de favoris.</p>
                        <Button type="primary" onClick={handleShopRedirect}>
                            Aller à la boutique
                        </Button>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default FavoriteModalMobile;
