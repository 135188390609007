import {Avatar, Button, Col, Collapse, Divider, Input, Menu, MenuProps, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import {DeleteOutlined, LogoutOutlined, UploadOutlined, UserOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {AddressType, User, UserAddress, UserInterface} from "../../User";
interface OptionsInterface {
    value: number,
    label: string
}
export default function PromoCode() {

    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"
    const [userInfos, setUserInfos] = useState({} as UserInterface);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<OptionsInterface[]>();
    const [billingInfos, setBillingInfos] = useState<UserAddress>({
        id: 0,
        address: '',
        additional_address: '',
        city: '',
        country: 'france',
        country_code: '',
        zipcode: '',
        phone_number: '',
        company_id: null,
        address_type: [{
            id: 1,
            address_type: "Billing",
        }] as AddressType[],
        zone_id: 0,
        zone: '',
        company: '',
        region: '',
        firstname : '',
        lastname : '',
        relais : null
    });

    useEffect(() => {
        const fetchUserInfos = async () => {
            try {
                const data = await User();
                if(data){
                    setUserInfos(data);
                    setDataFetched(true);
                    const mappedOptions = data.user_addresses.map((address) => ({
                        value: address.id,
                        label: address.address + ' ' + address.zipcode
                    }));
                    setOptions(mappedOptions);
                }

            } catch (error) {
                console.log("No user data found");
            }
        };

        if (!dataFetched) {
            fetchUserInfos();
        }
    }, [dataFetched]);


    const onChangeBilling = (value_selected: string) => {
        // console.log(`selected ${value}`);
        const selectedUserAddress = userInfos.user_addresses.find(
            (address) => address.id === parseInt(value_selected)
        );
        if (selectedUserAddress) {
            console.log("Selected User BillingAddress:", selectedUserAddress);
            setBillingInfos(prevState => {

                return {
                    zipcode: selectedUserAddress.zipcode,
                    phone_number: selectedUserAddress.phone_number,
                    address: selectedUserAddress.address,
                    country: selectedUserAddress.country,
                    city: selectedUserAddress.city,
                } as UserAddress
            })
        }

    };

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{color: colorHeaderText,}}>
            {windowWidth < 768 ? (
                <div>
                    <div>
                        <Row>
                            <Col style={{justifyContent: 'center', alignItems: 'center', display: 'flex', marginRight: '20px'}}>
                                <Avatar shape="square" size={64} style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}} icon={<UserOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>} />
                            </Col>
                            <Col flex={1}>
                                <p style={{marginBottom: '5px'}}><b>Avatar</b></p>
                                <p style={{marginBottom: '5px'}}>PNG ou JPG de moins de 800px de large</p>
                                <Button type="primary" style={{
                                    color: colorHeaderText, backgroundColor:
                                    colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                    height: '40px', marginRight: '10px'
                                }}><UploadOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                <Button type="primary" style={{
                                    color: colorHeaderText, backgroundColor:
                                    colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                    height: '40px', marginRight: '10px'
                                }}><DeleteOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                            </Col>
                        </Row>
                    </div>
                    <Divider/>
                    <div>
                        <Row>
                            <p style={{marginBottom: '10px', width: '100%'}}>Nom</p>
                            <Input name='lastname' placeholder="Nom" value={userInfos.lastname} style={{marginBottom: '10px', width: '100%'}}/>
                        </Row>
                        <Row>
                            <p style={{marginBottom: '10px', width: '100%'}}>Prénom</p>
                            <Input name='firstname' placeholder="Prénom" value={userInfos.firstname} style={{marginBottom: '10px', width: '100%'}}/>
                        </Row>
                        <Row style={{marginBottom: '10px'}}>
                            <p style={{marginBottom: '10px', width: '100%'}}>Email</p>
                            <Input name='mail' placeholder="Email" value={userInfos.user_email} style={{marginBottom: '10px', width: '100%'}}/>
                        </Row>
                        <Row>
                            <p style={{marginBottom: '10px', width: '100%'}}>Téléphone</p>
                            <Input name='phone' placeholder="+33" style={{marginBottom: '10px', width: '100%'}}/>
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                            <Col flex={1} style={{marginRight: '10px'}}>
                                <p style={{marginBottom: '10px'}}>Sélectionnez une de vos addresses enregistrées</p>
                                <Select
                                    showSearch
                                    placeholder="Sélectionner une adresse de livraison"
                                    optionFilterProp="children"
                                    style={{width: '100%'}}
                                    onChange={onChangeBilling}
                                    // onSearch={onSearch}
                                    options={options}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                            <Col style={{marginRight: '10px', flex: '1.18 1 auto'}}>
                                <p style={{marginBottom: '10px'}}>Pays</p>
                                <Select
                                    defaultValue="France"
                                    style={{ width: '100%' }}
                                    onChange={handleChange}
                                    options={[
                                        { value: 'fr', label: 'France' },
                                        { value: 'ge', label: 'Allemagne' },
                                        { value: 'en', label: 'Angleterre' },
                                        { value: 'ch', label: 'Suisse'},
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p style={{marginBottom: '10px'}}>Profil</p>
                            <TextArea
                                autoSize={{ minRows: 2, maxRows: 6 }}
                            />
                        </Row>
                    </div>
                    <Row style={{marginTop: '20px', display: "flex", justifyContent: 'end'}}>
                        <Button type="primary" style={{
                            color: colorHeaderText, backgroundColor:
                            colorProductBtn, border: '1px solid' + colorHeaderVerticalBar,
                            height: '40px', marginRight: '10px'
                        }}>Mettre à Jour</Button>
                    </Row>
                    <Row style={{marginTop: '20px', display: "flex", justifyContent: 'end'}}>
                        <Button type="primary" style={{
                            color: colorHeaderText, backgroundColor:
                            colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                            height: '40px', marginRight: '10px'
                        }}>Annuler</Button>
                    </Row>
                </div>
            ) : windowWidth >= 768 && windowWidth < 1100 ? (
                <div>
                    <div>
                        <Row style={{width: '50%', alignItems: 'center'}}>
                            <Col style={{display: 'flex', marginRight: '20px'}}>
                                <Avatar shape="square" size={64} style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}} icon={<UserOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>} />
                            </Col>
                            <Col flex={1}>
                                <p style={{marginBottom: '5px'}}><b>Avatar</b></p>
                                <p style={{marginBottom: '5px'}}>PNG ou JPG de moins de 800px de large</p>
                                <Button type="primary" style={{
                                    color: colorHeaderText, backgroundColor:
                                    colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                    height: '40px', marginRight: '10px'
                                }}><UploadOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                <Button type="primary" style={{
                                    color: colorHeaderText, backgroundColor:
                                    colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                    height: '40px', marginRight: '10px'
                                }}><DeleteOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                            </Col>
                        </Row>
                    </div>
                    <Divider/>
                    <div>
                        <Row style={{marginBottom: '20px'}}>
                            <Col flex={1} style={{marginRight: '10px'}}>
                                <p style={{marginBottom: '10px'}}>Nom</p>
                                <Input name='lastname' placeholder="Nom" value={userInfos.lastname}/>
                            </Col>
                            <Col flex={1} style={{marginLeft: '10px'}}>
                                <p style={{marginBottom: '10px'}}>Prénom</p>
                                <Input name='firstname' placeholder="Prénom" value={userInfos.firstname}/>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                            <Col flex={1} style={{marginRight: '10px'}}>
                                <p style={{marginBottom: '10px'}}>Email</p>
                                <Input name='mail' placeholder="Email" value={userInfos.user_email}/>
                            </Col>
                            <Col flex={1} style={{marginLeft: '10px'}}>
                                <p style={{marginBottom: '10px'}}>Téléphone</p>
                                <Input name='phone' placeholder="+33"/>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                            <Col flex={1} style={{marginRight: '10px'}}>
                                <p style={{marginBottom: '10px'}}>Sélectionnez une de vos addresses enregistrées</p>
                                <Select
                                    showSearch
                                    placeholder="Sélectionner une adresse de livraison"
                                    optionFilterProp="children"
                                    style={{width: '100%'}}
                                    onChange={onChangeBilling}
                                    // onSearch={onSearch}
                                    options={options}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                            <Col style={{marginRight: '10px', flex: '1.18 1 auto'}}>
                                <p style={{marginBottom: '10px'}}>Pays</p>
                                <Select
                                    defaultValue="France"
                                    style={{ width: '100%' }}
                                    onChange={handleChange}
                                    options={[
                                        { value: 'fr', label: 'France' },
                                        { value: 'ge', label: 'Allemagne' },
                                        { value: 'en', label: 'Angleterre' },
                                        { value: 'ch', label: 'Suisse'},
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p style={{marginBottom: '10px'}}>Profil</p>
                            <TextArea
                                placeholder="Autosize height with minimum and maximum number of lines"
                                autoSize={{ minRows: 2, maxRows: 6 }}
                            />
                        </Row>
                    </div>
                    <div style={{marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
                        <Button type="primary" style={{
                            color: colorHeaderText, backgroundColor:
                            colorProductBtn, border: '1px solid' + colorHeaderVerticalBar,
                            height: '40px', marginRight: '10px'
                        }}>Mettre à Jour</Button>
                        <Button type="primary" style={{
                            color: colorHeaderText, backgroundColor:
                            colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                            height: '40px', marginRight: '10px'
                        }}>Annuler</Button>
                    </div>
                </div>
            ) : (
                <div>
                    <div>
                        <Row style={{width: '50%', alignItems: 'center'}}>
                            <Col style={{display: 'flex', marginRight: '20px'}}>
                                <Avatar shape="square" size={64} icon={<UserOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>} />
                            </Col>
                            <Col flex={1}>
                                <p style={{marginBottom: '5px'}}><b>Avatar</b></p>
                                <p style={{marginBottom: '5px'}}>PNG ou JPG de moins de 800px de large</p>
                                <Button type="primary" style={{
                                    color: colorHeaderText, backgroundColor:
                                    colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                    height: '40px', marginRight: '10px'
                                }}><UploadOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                <Button type="primary" style={{
                                    color: colorHeaderText, backgroundColor:
                                    colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                    height: '40px', marginRight: '10px'
                                }}><DeleteOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                            </Col>
                        </Row>
                    </div>
                    <Divider/>
                    <div>
                        <Row style={{marginBottom: '20px'}}>
                            <Col flex={1} style={{marginRight: '10px'}}>
                                <p style={{marginBottom: '10px'}}>Nom</p>
                                <Input name='lastname' placeholder="Nom" value={userInfos.lastname}/>
                            </Col>
                            <Col flex={1} style={{marginLeft: '10px'}}>
                                <p style={{marginBottom: '10px'}}>Prénom</p>
                                <Input name='firstname' placeholder="Prénom" value={userInfos.firstname}/>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                            <Col flex={1} style={{marginRight: '10px'}}>
                                <p style={{marginBottom: '10px'}}>Email</p>
                                <Input name='mail' placeholder="Email" value={userInfos.user_email}/>
                            </Col>
                            <Col flex={1} style={{marginLeft: '10px'}}>
                                <p style={{marginBottom: '10px'}}>Téléphone</p>
                                <Input name='phone' placeholder="+33"/>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                            <Col flex={1} style={{marginRight: '10px'}}>
                                <p style={{marginBottom: '10px'}}>Sélectionnez une de vos addresses enregistrées</p>
                                <Select
                                    showSearch
                                    placeholder="Sélectionner une adresse de livraison"
                                    optionFilterProp="children"
                                    style={{width: '100%'}}
                                    onChange={onChangeBilling}
                                    // onSearch={onSearch}
                                    options={options}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '20px'}}>
                            <Col style={{marginRight: '10px', flex: '1.18 1 auto'}}>
                                <p style={{marginBottom: '10px'}}>Pays</p>
                                <Select
                                    defaultValue="France"
                                    style={{ width: '100%' }}
                                    onChange={handleChange}
                                    options={[
                                        { value: 'fr', label: 'France' },
                                        { value: 'ge', label: 'Allemagne' },
                                        { value: 'en', label: 'Angleterre' },
                                        { value: 'ch', label: 'Suisse'},
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <p style={{marginBottom: '10px'}}>Profil</p>
                            <TextArea
                                placeholder="Autosize height with minimum and maximum number of lines"
                                autoSize={{ minRows: 2, maxRows: 6 }}
                            />
                        </Row>
                    </div>
                    <div style={{marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
                        <Button type="primary" style={{
                            color: colorHeaderText, backgroundColor:
                            colorProductBtn, border: '1px solid' + colorHeaderVerticalBar,
                            height: '40px', marginRight: '10px'
                        }}>Mettre à Jour</Button>
                        <Button type="primary" style={{
                            color: colorHeaderText, backgroundColor:
                            colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                            height: '40px', marginRight: '10px'
                        }}>Annuler</Button>
                    </div>
                </div>
            )
            }
        </div>
    )
}