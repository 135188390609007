import {Button, Col, Input, Row, Select, Space, Table, Pagination, InputNumber, Upload} from "antd";
import React, {useEffect, useState} from "react";
import {EditOutlined, SearchOutlined, DeleteOutlined, UploadOutlined} from "@ant-design/icons";
import {User, UserInterface} from "../../User";
import {getVendorProduct, GlobalProduct, ProductInterface} from "../../Products/Products";
import ImgCrop from "antd-img-crop";

interface dataSourceInterface {
    key: string,
    id: string,
    productName: string,
    sku: string,
    stock: string,
    price: number,
    promoPrice: number,
    categories: string,
    date: string,
}

export default function AskSearches() {

    const { TextArea } = Input;

    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"

    const [productInfos, setProductInfos] = useState({} as GlobalProduct[]);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);

    useEffect(() => {
        const fetchUserInfos = async () => {
            const products = await getVendorProduct();
            setProductInfos(products)
            try {
                const mappedOptions = products.map((product) => ({
                    key: product.product.id as unknown as string,
                    productName: product.product.product_name,
                    sku: product.product.product_sku,
                    date: new Date(product.product.creation_date * 1000).toDateString(),
                    categories: '',
                    id: product.product.id as unknown as string,
                    price: product.product.price,
                    promoPrice: product.product.price_promo,
                    stock: product.product.quantity > 0 ? "Stock" : "Hors stock",
                } as dataSourceInterface));
                setOptions(mappedOptions);
            } catch (error) {
                console.log("No user data found");
            }
            setDataFetched(true);
        };
        if (!dataFetched) {
            fetchUserInfos();
        }
    }, [dataFetched]);

    const onChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    const dataSource = options

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Recherche',
            dataIndex: 'search',
            key: 'search',
            width: '35%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'État',
            dataIndex: 'state',
            key: 'state',
            width: '15%',
            render: (text: any, record: any) => {
                if (record.state === 'delivered') {
                    return <span style={{
                        color: '#00A3FF',
                        backgroundColor: '#F1FAFF',
                        padding: '5px',
                        border: '1px solid #DCF2E6',
                        borderRadius: '5px'
                    }}>{text}</span>;
                } else if (record.state === 'shipping') {
                    return <span style={{
                        color: '#FFA800',
                        backgroundColor: '#FFF4DE',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{text}</span>;
                } else {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{text}</span>;
                }
            },
        },

        // {
        //     title: 'Lorem',
        //     dataIndex: 'lorem',
        //     key: 'lorem',
        //     width: '10%',
        //     render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        // },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    <Button style={{margin: '5px'}} icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}/>
                    <Button style={{margin: '5px'}} icon={<DeleteOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}/>
                </div>
            )
        },
    ];


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const totalItems = dataSource.length;
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(rangeStart + itemsPerPage - 1, totalItems);

    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (current: any, size: any) => {
        setItemsPerPage(size);
    };

    const customPaginationStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{color: colorHeaderText}}>
            {windowWidth < 768 ? (
                <div
                    style={{
                        margin: '0px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#F3F5F6",
                        borderRadius: '15px'
                    }}>
                    <h1 style={{fontSize: '24px'}}>Faire une demande de recherche</h1>
                    {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    <div style={{
                        marginTop: '20px',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #FFFFFF',
                        borderRadius: '5px',
                        width: '95%',
                        margin: '20px auto'
                    }}>
                        <div style={{padding: '20px'}}>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Nom & Prénom</p>
                                    <Input name='search' placeholder="Nom & Prénom" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Email</p>
                                    <Input name='email' placeholder="Email" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Nom du bateau</p>
                                    <Input name='boatName' placeholder="Nom du bateau" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Immatriculation</p>
                                    <Input name='plate' placeholder="Immatriculation" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Pays</p>
                                    <Input name='country' placeholder="Pays" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Code Postal</p>
                                    <Input name='zipcode' placeholder="Code Postal" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Adresse</p>
                                    <Input name='address' placeholder="Adresse" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Téléphone</p>
                                    <Input name='phone' placeholder="Téléphone" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <p style={{marginBottom: '10px'}}>Description de la recherche</p>
                                <TextArea rows={4} name='description'/>
                            </Row>
                            <Row>
                                <style>
                                    {`                                        
                                        .ant-upload-list-item-name {
                                          white-space: nowrap;
                                          overflow: hidden;
                                          text-overflow: ellipsis;
                                          max-width: 150px;
                                          margin: 4px 0;
                                        }
                                    `}
                                </style>
                                <ImgCrop>
                                    <Upload>
                                        <Button icon={<UploadOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}>Pièces jointes (Max: 5)</Button>
                                    </Upload>
                                </ImgCrop>
                            </Row>
                            <Row style={{ display: 'flex', justifyContent: 'end', marginTop: '20px'}}>
                                <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}>
                                    Faire une demande
                                </Button>
                            </Row>
                        </div>
                    </div>
                </div>
            ) : windowWidth >= 768 && windowWidth < 1100 ? (
                <div
                    style={{
                        margin: '0px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#F3F5F6",
                        borderRadius: '15px'
                    }}>
                    <div style={{margin: '15px'}}>
                        <h1 style={{fontSize: '24px'}}>Faire une demande de recherche</h1>
                        {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    </div>
                    <div style={{
                        marginTop: '20px',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #FFFFFF',
                        borderRadius: '5px',
                        width: '95%',
                        margin: '20px auto'
                    }}>
                        <div style={{padding: '20px'}}>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Nom & Prénom</p>
                                    <Input name='search' placeholder="Nom & Prénom" style={{width: '95%'}}/>
                                </Col>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Email</p>
                                    <Input name='email' placeholder="Email" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Nom du bateau</p>
                                    <Input name='boatName' placeholder="Nom du bateau" style={{width: '95%'}}/>
                                </Col>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Immatriculation</p>
                                    <Input name='plate' placeholder="Immatriculation" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Pays</p>
                                    <Input name='country' placeholder="Pays" style={{width: '95%'}}/>
                                </Col>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Code Postal</p>
                                    <Input name='zipcode' placeholder="Code Postal" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Adresse</p>
                                    <Input name='address' placeholder="Adresse" style={{width: '95%'}}/>
                                </Col>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Téléphone</p>
                                    <Input name='phone' placeholder="Téléphone" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <p style={{marginBottom: '10px'}}>Description de la recherche</p>
                                <TextArea rows={4} name='description'/>
                            </Row>
                            <Row>
                                <ImgCrop rotationSlider>
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        maxCount={5}
                                    >
                                        <Button icon={<UploadOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}>Pièces jointes (Max: 5)</Button>
                                    </Upload>
                                </ImgCrop>
                            </Row>
                            <Row style={{ display: 'flex', justifyContent: 'end'}}>
                                <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}>
                                    Faire une demande
                                </Button>
                            </Row>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div style={{margin: '15px'}}>
                        <h1 style={{fontSize: '24px'}}>Faire une demande de recherche</h1>
                        {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                    </div>
                    <div style={{
                        marginTop: '20px',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #FFFFFF',
                        borderRadius: '5px',
                        width: '95%',
                        margin: '20px auto'
                    }}>
                        <div style={{padding: '20px'}}>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Nom & Prénom</p>
                                    <Input name='search' placeholder="Nom & Prénom" style={{width: '95%'}}/>
                                </Col>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Email</p>
                                    <Input name='email' placeholder="Email" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Nom du bateau</p>
                                    <Input name='boatName' placeholder="Nom du bateau" style={{width: '95%'}}/>
                                </Col>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Immatriculation</p>
                                    <Input name='plate' placeholder="Immatriculation" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Pays</p>
                                    <Input name='country' placeholder="Pays" style={{width: '95%'}}/>
                                </Col>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Code Postal</p>
                                    <Input name='zipcode' placeholder="Code Postal" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Adresse</p>
                                    <Input name='address' placeholder="Adresse" style={{width: '95%'}}/>
                                </Col>
                                <Col flex={1}>
                                    <p style={{marginBottom: '10px'}}>Téléphone</p>
                                    <Input name='phone' placeholder="Téléphone" style={{width: '95%'}}/>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '20px'}}>
                                <p style={{marginBottom: '10px'}}>Description de la recherche</p>
                                <TextArea rows={4} name='description'/>
                            </Row>
                            <Row>
                                <ImgCrop rotationSlider>
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        maxCount={5}
                                    >
                                        <Button icon={<UploadOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}>Pièces jointes (Max: 5)</Button>
                                    </Upload>
                                </ImgCrop>
                            </Row>
                            <Row style={{ display: 'flex', justifyContent: 'end'}}>
                                <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}>
                                    Faire une demande
                                </Button>
                            </Row>
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    );
}