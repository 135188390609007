// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
const languages = ['en', 'fr'];
let lng
if(localStorage.getItem('lng') === "en"){
    lng = "en"
} else{
    lng = "fr"
}
i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng:lng,
        fallbackLng: 'fr',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            // Spécifie l'endroit où se trouvent les fichiers de traduction
            loadPath: '/locales/{{lng}}.json', // Assurez-vous que le chemin est correct
            // Ajoutez ces lignes pour ajouter des journaux
            onLanguageChanged: (language: any) => {
                // console.log(`Changement de langue détecté : ${language}`);
            },
            onMissingKey: (key: any) => {
                // console.warn(`Clé manquante : ${key}`);
            },
            onLoaded: (loaded: any) => {
                // console.log(`Chargement terminé : ${loaded}`);
            },
        },
    });

export default i18n;
