import {Button, Col, Input, Pagination, Progress, Row, Select, Space, Spin, Table} from 'antd';
import React, {useEffect, useState} from "react";
import {
    CheckSquareFilled,
    CloseCircleOutlined,
    CloseSquareFilled,
    DownloadOutlined,
    SearchOutlined
} from "@ant-design/icons";
import {getUserById, getUserMangoWallet, payout, User} from "../../User";
import {getProductByID, GlobalProduct} from "../../Products/Products";
import {DownloadOrders, DownloadOrdersNotRecupered, DownloadSpecificOrders} from "./downloadProducts";
import {getVendorOrders, getVendorOrdersByVendorId} from "../../Order";
import {getVendorPayouts} from "../../MangoPay";
import {useTranslation} from "react-i18next";
import {ToastContainer} from "react-toastify";

interface dataSourceInterface {
    key: string,
    type: any,
    state: string,
    id: string,
    date: string,
    status: string,
    price_vente_HT: string,
    commissionHT: string,
    livraisonHT: string,
    recuperable: true | number
}

function isOrderEligibleForFundsRecovery(apiTimestamp: number): string | number {
    const currentTimestamp = Math.floor(Date.now() / 1000);

    // Calculer la différence en secondes
    const timeDifference = currentTimestamp - apiTimestamp;

    // Vérifier si la différence est supérieure ou égale à 14 jours (en secondes)
    if (timeDifference >= 15 * 24 * 60 * 60) {
        return "done";
    }

    // Calculer le nombre de jours restants
    return Math.floor((15 * 24 * 60 * 60 - timeDifference) / (24 * 60 * 60));
}

export default function BankView() {
    const {t} = useTranslation();
    const [progress, setProgress] = useState(0);

    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"
    const [productInfos, setProductInfos] = useState({} as GlobalProduct[]);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);
    const [userWallet, setUserWallet] = useState(0);
    const [Total_des_ventes_HT, setTotal_des_ventes_HT] = useState(0);
    const [Total_des_commissions_HT, setTotal_des_commissions_HT] = useState(0);
    const [Total_Solde_HT, setTotal_des_solde_HT] = useState(0);
    const [searchText, setSearchText] = useState(''); // État pour le texte de recherche
    const [selectedType, setSelectedType] = useState('all'); // Initialisez le filtre à 'all'
    const [user_id, setUser_id] = useState(''); // Initialisez la wallet à
    const filterOptions = [
        {label: t('Toutes les commandes'), value: 'all'},
        {label: t('Recuperable'), value: 'recuperable'},
        {label: t('Récupéré'), value: 'recovered'},
        {label: t('Non Recuperable'), value: 'nonrecuperable'},
        {label: t('Payout'), value: 'payout'},
    ];
    useEffect(() => {
        const fetchOrderAndPayoutInfos = async () => {
            if (localStorage.getItem('user_id') == "1209") {
                const user_infos = await User()
                setUser_id(user_infos ? (await user_infos).MangoPayUser[0].wallet_id : "")
                const wallet_id = user_infos ? (await user_infos).MangoPayUser[0].wallet_id : undefined;
                if (wallet_id) {
                    setUserWallet(await getUserMangoWallet(wallet_id as unknown as number))
                    setUser_id("2765531030")
                }
            }
            if (localStorage.getItem('user_id') == "1781") {
                const user_infos = await User()
                setUser_id(user_infos ? (await user_infos).MangoPayUser[0].wallet_id : "")
                const wallet_id = user_infos ? (await user_infos).MangoPayUser[0].wallet_id : undefined;
                if (wallet_id) {
                    setUserWallet(await getUserMangoWallet(wallet_id as unknown as number))
                    setUser_id("2338770193")
                }
            }
            if (localStorage.getItem('user_id') == "1961") {
                const user_infos = await User()
                setUser_id(user_infos ? (await user_infos).MangoPayUser[0].wallet_id : "")
                const wallet_id = user_infos ? (await user_infos).MangoPayUser[0].wallet_id : undefined;
                if (wallet_id) {
                    setUserWallet(await getUserMangoWallet(wallet_id as unknown as number))
                    setUser_id("4530559365")
                }
            }

            if (localStorage.getItem('user_id') == "1732") {
                const user_infos = await User()
                setUser_id(user_infos ? (await user_infos).MangoPayUser[0].wallet_id : "")
                const wallet_id = user_infos ? (await user_infos).MangoPayUser[0].wallet_id : undefined;
                if (wallet_id) {
                    setUserWallet(await getUserMangoWallet(wallet_id as unknown as number))
                    setUser_id("3532471674")
                }
            }
            if (localStorage.getItem('user_id') == "1726") {
                const user_infos = await User()
                setUser_id(user_infos ? (await user_infos).MangoPayUser[0].wallet_id : "")
                const wallet_id = user_infos ? (await user_infos).MangoPayUser[0].wallet_id : undefined;
                if (wallet_id) {
                    setUserWallet(await getUserMangoWallet(wallet_id as unknown as number))
                    setUser_id("3362705808")
                }
            }
            if (localStorage.getItem('user_id') == "1703") {
                const user_infos = await User()
                setUser_id(user_infos ? (await user_infos).MangoPayUser[0].wallet_id : "")
                const wallet_id = user_infos ? (await user_infos).MangoPayUser[0].wallet_id : undefined;
                if (wallet_id) {
                    setUserWallet(await getUserMangoWallet(wallet_id as unknown as number))
                    setUser_id("3662227832")
                }
            }

            if (localStorage.getItem('user_id') == "1824") {
                const user_infos = await User()
                setUser_id(user_infos ? (await user_infos).MangoPayUser[0].wallet_id : "")
                const wallet_id = user_infos ? (await user_infos).MangoPayUser[0].wallet_id : undefined;
                if (wallet_id) {
                    setUserWallet(await getUserMangoWallet(wallet_id as unknown as number))
                    setUser_id("4000951760")
                }
            }
            let bankaccountid = 0
            if (localStorage.getItem('user_id') == "1209") {
                bankaccountid = 2765531030
            }
            if (localStorage.getItem('user_id') == "1781") {
                bankaccountid = 2338770193
            }

            if (localStorage.getItem('user_id') == "1961") {
                bankaccountid = 4530559365
            }
            if (localStorage.getItem('user_id') == "1732") {
                bankaccountid = 3532471674
            }
            if (localStorage.getItem('user_id') == "1726") {
                bankaccountid = 3362705808
            }
            if (localStorage.getItem('user_id') == "1703") {
                bankaccountid = 3662227832
            }
            if (localStorage.getItem('user_id') == "1824") {
                bankaccountid = 4000951760
            }
            const orders = await getVendorOrders()
            orders.reverse();
            const payouts = await getVendorPayouts(bankaccountid); // Remplacez bankAccountId par la valeur appropriée
            if (payouts) {
                payouts.sort((a, b) => a.ExecutionDate - b.ExecutionDate);
            }
            let nb_elements = orders.length;
            let nb_data_charged = 0;
            let cpt = 0;
            let mappedOptions: dataSourceInterface[] = [];
            let montant_total_des_ventes_HT = 0;
            let montant_total_des_commissions_HT = 0;
            let montant_total_solde_HT = 0;
            let payoutIndex = 0; // Indice pour suivre les payouts

            for (const order of orders) {
                const client = await getUserById(order.buyer_id);
                if (!client) continue;

                for (const subOrder of order.sub_orders) {
                    if (subOrder.state_id === 2 || subOrder.state_id === 3 || subOrder.state_id === 5 || subOrder.state_id === 4) {
                        const product = await getProductByID(subOrder.product_id);
                        if (!product) continue;
                        if (String(product.vendor_id) !== localStorage.getItem("user_id")) continue;

                        const actualPrice = subOrder.product_price_HT / subOrder.quantity;
                        let commissionHT = 0
                        if(order.creation_date > 1704063601){
                            commissionHT = (actualPrice * subOrder.quantity) - ((actualPrice * subOrder.quantity) / 1.15);
                        } else {
                            commissionHT = (actualPrice * subOrder.quantity) - ((actualPrice * subOrder.quantity) / 1.1);
                        }
                        const tvaCommission = (commissionHT * 1.2) - commissionHT;
                        const commissionTTC = commissionHT + tvaCommission;

                        // Vérifiez si un payout est associé à cette commande
                        if (payouts) {
                            while (payoutIndex < payouts.length) {
                                const payout = payouts[payoutIndex];
                                if (payout.ExecutionDate > order.creation_date) {
                                    break;
                                }

                                // Ajoutez les détails du payout à mappedOptions
                                mappedOptions.push({
                                    type: 'payout',
                                    key: `payout-${payout.Id}`,
                                    id: `Virement N°${payout.Id}`,
                                    date: new Date(payout.ExecutionDate * 1000).toDateString(),
                                    amount: payout.DebitedFunds.Amount,
                                    recuperable: ''
                                } as unknown as dataSourceInterface);

                                payoutIndex++;
                            }
                        }
                        let eligibity = order.MangoPayOrderTransaction[0].transfered ? "done" : isOrderEligibleForFundsRecovery(order.creation_date)
                        eligibity = order.MangoPayOrderTransaction[0].recovered ? "recovered" : eligibity
                        let type
                        if (eligibity === "done") {
                            type = "recuperable"
                        } else if (eligibity === 'recovered') {
                            type = "recovered"
                        } else {
                            type = "nonrecuperable"
                        }
                        // Ajoutez les détails de la commande à mappedOptions
                        mappedOptions.push({
                            key: `${order.id}-${subOrder.product_id}`,
                            type: type,
                            date: new Date(order.creation_date * 1000).toDateString(),
                            status: subOrder.state_id as unknown as string,
                            id: order.id as unknown as string,
                            price_vente_HT: (actualPrice * subOrder.quantity),
                            commissionHT: commissionHT,
                            livraisonHT: 5.83,
                            solde_HT: subOrder.product.has_tva ? ((actualPrice * subOrder.quantity) - (commissionHT)) : ((actualPrice * subOrder.quantity) - (commissionHT * 1.2)),
                            solde_TTC: subOrder.product.has_tva ? ((actualPrice * subOrder.quantity) - commissionHT) * 1.2 : ((actualPrice * subOrder.quantity) - (commissionHT * 1.2)),
                            recuperable: eligibity,
                            bon_commission: '',
                            recap_commande: '',
                            // ... Autres détails de la commande ...
                        } as unknown as dataSourceInterface);

                        montant_total_des_ventes_HT += (actualPrice * subOrder.quantity);
                        montant_total_des_commissions_HT += commissionHT;
                        montant_total_solde_HT += ((actualPrice * subOrder.quantity) - commissionHT);
                    }
                }

                nb_data_charged += 1;
                cpt = (nb_data_charged / nb_elements) * 100;
                setProgress(Math.round(cpt));
            }

            // Vérifiez s'il reste des payouts non ajoutés
            if (payouts) {
                while (payoutIndex < payouts.length) {
                    const payout = payouts[payoutIndex];
                    // Ajoutez les détails du payout à mappedOptions
                    mappedOptions.push({
                        type: 'payout',
                        key: `payout-${payout.Id}`,
                        state: 9,
                        date: new Date(payout.ExecutionDate * 1000).toDateString(),
                        amount: payout.DebitedFunds.Amount,
                        recuperable: "done",
                    } as unknown as dataSourceInterface);
                    payoutIndex++;
                }
            }

            // Triez mappedOptions par date du plus récent au plus ancien
            mappedOptions.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

            setTotal_des_ventes_HT(montant_total_des_ventes_HT);
            setTotal_des_commissions_HT(montant_total_des_commissions_HT);
            setTotal_des_solde_HT(montant_total_solde_HT);
            setDataFetched(true);

            setOptions(mappedOptions);
        };

        if (!dataFetched) {
            fetchOrderAndPayoutInfos();
        }
    }, [dataFetched]);

    const onChange = (value: string) => {
        setSelectedType(value);
        setCurrentPage(1)
    };

    const dataSource = options
    const filteredData = dataSource.filter((item) => {
        const matchFilter = selectedType === 'all' || item.type === selectedType;
        const matchSearch =
            item.id && true &&
            item.id.toString().toLowerCase().includes(searchText.toString().toLowerCase());

        return matchFilter && matchSearch;
    });
    const columns = [
        {
            title: 'Status',
            fontSize: '0.7vw',
            dataIndex: 'state',
            key: 'state',
            width: '5%',
            render: (text: any, record: any) => {
                if (record.state === 'delivered') {
                    return <span><CloseSquareFilled style={{color: '#F1416C', fontSize: '24px'}}
                                                    onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></span>;

                } else {
                    return <span><CheckSquareFilled style={{color: '#7ED957', fontSize: '24px'}}
                                                    onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></span>;
                }
            }
        },
        {
            title: 'ID',
            fontSize: '0.7vw',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText, fontSize: '0.7vw'}}>{text}</span>
        },
        {
            title: 'Date',
            fontSize: '0.7vw',
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            sorter: (a: dataSourceInterface, b: dataSourceInterface) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA.getTime() - dateB.getTime();
            },
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Status de la commande',
            fontSize: '0.7vw',
            dataIndex: 'status',
            key: 'status',
            width: '20%',
            render: (text: any, record: any) => {
                if (record.status === 1) {
                    return <span style={{
                        color: '#00A3FF',
                        backgroundColor: '#F1FAFF',
                        padding: '5px',
                        border: '1px solid #DCF2E6',
                        borderRadius: '5px'
                    }}>{t('En attente de paiement')}</span>;
                } else if (record.status === 3 || record.status === 2 || record.status === 4) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Paiement validé')}</span>;
                } /*else if (record.status === 3) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>En cours de préparation</span>;
                } */ else if (record.status === 8) {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Paiement refusé')}</span>;
                } else if (record.status === 5) {
                    return <span style={{
                        color: '#f1b041',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Remboursée')}</span>;
                } else {
                    return <span style={{
                        color: '#F1416C',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{text}</span>;
                }
            },
        },
        {
            title: t('Vente HT'),
            fontSize: '0.7vw',
            dataIndex: 'price_vente_HT',
            key: 'price_HT',
            width: '10%',
            render: (text: any) => {
                if (text && parseFloat(text) !== 0) {
                    return <span style={{color: colorHeaderText}}>{text.toFixed(2) + " €"}</span>;
                } else {
                    return null; // ou tout autre contenu que vous souhaitez afficher lorsque la valeur est vide
                }
            }

        },
        {
            title: t('Commission (10% HT)'),
            fontSize: '0.7vw',
            dataIndex: 'commissionHT',
            key: 'commission',
            width: '15%',
            render: (text: any) => {
                if (text && parseFloat(text) !== 0) {
                    return <span style={{color: colorHeaderText}}>{text.toFixed(2) + " €"}</span>;
                } else {
                    return null; // ou tout autre contenu que vous souhaitez afficher lorsque la valeur est vide
                }
            }

        },
        {
            title: t('Solde disponible HT'),
            fontSize: '0.7vw',
            dataIndex: 'solde_HT',
            key: 'cash_HT',
            width: '15%',
            render: (text: any) => {
                if (text && parseFloat(text) !== 0) {
                    return <span style={{color: colorHeaderText}}>{text.toFixed(2) + " €"}</span>;
                } else {
                    return null; // ou tout autre contenu que vous souhaitez afficher lorsque la valeur est vide
                }
            }

        },
        {
            title: t('Solde disponible TTC'),
            fontSize: '0.7vw',
            dataIndex: 'solde_TTC',
            key: 'cash_TTC',
            width: '15%',
            render: (text: any) => {
                if (text && parseFloat(text) !== 0) {
                    return <span style={{color: colorHeaderText}}>{text.toFixed(2) + " €"}</span>;
                } else {
                    return null; // ou tout autre contenu que vous souhaitez afficher lorsque la valeur est vide
                }
            }

        },
        {
            title: t('Récupérable'),
            fontSize: '0.7vw',
            dataIndex: 'recuperable',
            key: 'recuperable',
            width: '15%',
            render: (text: any, record: any) => {
                if (record.recuperable === "done") {
                    return (
                        <span style={{
                            color: '#00A3FF',
                            backgroundColor: '#F1FAFF',
                            padding: '5px',
                            border: '1px solid #DCF2E6',
                            borderRadius: '5px',
                            fontSize: '0.7vw'
                        }}>
                          <CheckSquareFilled style={{color: '#7ED957', fontSize: '24px'}}
                                             onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                            {t('Récupérable')}
                        </span>
                    );
                } else if (record.recuperable === 'recovered') {
                    return (
                        <span style={{
                            color: '#00A3FF',
                            backgroundColor: '#F1FAFF',
                            padding: '5px',
                            border: '1px solid #DCF2E6',
                            borderRadius: '5px',
                            fontSize: '0.7vw'
                        }}>
                      <CheckSquareFilled style={{color: '#7ED957', fontSize: '24px'}} onPointerEnterCapture={undefined}
                                         onPointerLeaveCapture={undefined}/>
                            {t('Récupéré')}
                    </span>)
                } else if (record.recuperable === '') {
                    return (<span style={{
                        color: '#F1416C',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px',
                        fontSize: '0.7vw'
                    }}>
        </span>)
                } else {
                    return (
                        <span style={{
                            color: '#F1416C',
                            padding: '5px',
                            border: '1px solid #FFF5F8',
                            borderRadius: '5px',
                            fontSize: '0.7vw'
                        }}>
                            <CloseCircleOutlined
                                style={{color: '#F1416C', fontSize: '24px'}} onPointerEnterCapture={undefined}
                                onPointerLeaveCapture={undefined}/> {text} {t('jours restants')}
        </span>
                    );
                }
            },
        },

        {
            title: t('Montant récupéré'),
            fontSize: '0.7vw',
            dataIndex: 'amount',
            key: 'amount',
            width: '10%',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    {text ? (text / 100).toString() + " €" : ""}
                </div>
            )
        },
        // {
        //     title: 'Bon de commission',
        //     fontSize: '0.7vw',
        //     dataIndex: 'bon_commission',
        //     key: 'bon_commission',
        //     width: '10%',
        //     render: (text: any, record: any) => (
        //         <div style={{display: 'flex'}}>
        //             {/*<Button style={{margin: '5px'}} icon={<DownloadOutlined/>}/>*/}
        //         </div>
        //     )
        // },
        // {
        //     title: 'Récapitulatif de la commande',
        //     fontSize: '0.7vw',
        //     key: 'recap_commande',
        //     dataIndex: 'recap_commande',
        //     width: '10%',
        //     render: (text: any, record: any) => (
        //         <div style={{display: 'flex'}}>
        //             {/*<Button style={{margin: '5px'}} icon={<DownloadOutlined/>}/>*/}
        //         </div>
        //     )
        // },
    ];

    const columnsTablet = [
        {
            title: t('Status'),
            fontSize: '9px',
            dataIndex: 'state',
            key: 'state',
            width: '10%',
            render: (text: any, record: any) => {
                if (record.state === 'delivered') {
                    return <span><CheckSquareFilled style={{color: '#7ED957', fontSize: '24px'}}
                                                    onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></span>;
                } else {
                    return <span><CloseSquareFilled style={{color: '#F1416C', fontSize: '24px'}}
                                                    onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></span>;
                }
            }
        },
        {
            title: t('ID'),
            fontSize: '0.7vw',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (text: any) => <span style={{color: colorHeaderText, fontSize: '0.7vw'}}>{text}</span>
        },
        {
            title: t('Date'),
            fontSize: '9px',
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText, fontSize: '0.7vw'}}>{text}</span>
        },
        {
            title: t('Status de la commande'),
            fontSize: '16px',
            dataIndex: 'status',
            key: 'status',
            width: '20%',
            render: (text: any) => <span style={{color: colorHeaderText, fontSize: '0.7vw'}}>{text}</span>
        },
        {
            title: t('Vente HT'),
            fontSize: '9px',
            dataIndex: 'price_HT',
            key: 'price_HT',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText, fontSize: '0.7vw'}}>{text}</span>
        },
        {
            title: t('Solde disponible HT'),
            fontSize: '16px',
            dataIndex: 'cash_HT',
            key: 'cash_HT',
            width: '15%',
            render: (text: any) => <span style={{color: colorHeaderText, fontSize: '0.7vw'}}>{text}</span>
        },
        {
            title: t('Récupérable'),
            fontSize: '9px',
            dataIndex: 'cashBack',
            key: 'cashBack',
            width: '15%',
            render: (text: any, record: any) => {
                if (record.state === 'delivered') {
                    return <span style={{
                        color: '#00A3FF',
                        backgroundColor: '#F1FAFF',
                        padding: '5px',
                        border: '1px solid #DCF2E6',
                        borderRadius: '5px',
                        fontSize: '0.7vw'
                    }}>{text}</span>;
                } else {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px',
                        fontSize: '0.7vw'
                    }}>{text}</span>;
                }
            },
        },
        {
            title: t('Récapitulatif de la commande'),
            fontSize: '9px',
            key: 'commandSheet',
            width: '10%',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    <Button style={{margin: '5px'}} icon={<DownloadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}/>
                </div>
            )
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    let totalItems = dataSource.length;
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(rangeStart + itemsPerPage - 1, totalItems);

    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (current: any, size: any) => {
        setItemsPerPage(size);
    };
    const handleSearch = (value: any) => {
        setSearchText(value);
    };


    const customPaginationStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const hoverStyle = {
        cursor: 'pointer',
        // Add your hover styles here, for example:
        backgroundColor: '#000000',
    };

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (

        <div style={{color: colorHeaderText}}>
            <ToastContainer/>
            {dataFetched ? (
                <div>
                            <div style={{margin: '15px'}}>
                                <h1 style={{fontSize: '24px'}}>{t('Aperçu Bancaire')}</h1>
                                <div style={{marginTop: '20px'}}>
                                    <Row>
                                        <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                            <div style={{
                                                backgroundColor: 'white',
                                                display: 'inline-block',
                                                padding: '20px',
                                                border: '2px solid' + colorHeaderText,
                                                borderRadius: '5px'
                                            }}>
                                                <p style={{
                                                    display: 'inline-block',
                                                    fontSize: '0.8vw'
                                                }}>{t('Montant total des ventes (HT)')}</p>
                                                <p style={{
                                                    display: 'inline-flex',
                                                    marginLeft: '15px',
                                                    fontSize: '1vw',
                                                    fontWeight: '500'
                                                }}>{Total_des_ventes_HT.toFixed(2) + " €"}</p>
                                            </div>
                                        </Col>
                                        <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                            <div style={{
                                                backgroundColor: 'white',
                                                display: 'inline-block',
                                                padding: '20px',
                                                border: '2px solid' + colorHeaderText,
                                                borderRadius: '5px'
                                            }}>
                                                <p style={{
                                                    display: 'inline-block',
                                                    fontSize: '0.8vw'
                                                }}>{t('Montant des commissions (HT)')}</p>
                                                <p style={{
                                                    display: 'inline-flex',
                                                    marginLeft: '15px',
                                                    fontSize: '1vw',
                                                    fontWeight: '500'
                                                }}>{Total_des_commissions_HT.toFixed(2) + " €"}</p>
                                            </div>
                                        </Col>
                                        <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                            <div style={{
                                                backgroundColor: 'white',
                                                display: 'inline-block',
                                                padding: '20px',
                                                border: '2px solid' + colorHeaderText,
                                                borderRadius: '5px'
                                            }}>
                                                <p style={{
                                                    display: 'inline-block',
                                                    fontSize: '0.8vw'
                                                }}>{t('Solde Final (HT)')}</p>
                                                <p style={{
                                                    display: 'inline-flex',
                                                    marginLeft: '15px',
                                                    fontSize: '12px',
                                                    fontWeight: '500'
                                                }}>{Total_Solde_HT.toFixed(2) + " €"}</p>
                                            </div>
                                        </Col>
                                        <Col flex={1} style={{justifyContent: 'center', display: 'flex'}}>
                                            <div style={{
                                                backgroundColor: 'white',
                                                display: 'inline-block',
                                                padding: '20px',
                                                border: '2px solid' + colorHeaderText,
                                                borderRadius: '5px'
                                            }}>
                                                <p style={{
                                                    display: 'inline-block',
                                                    fontSize: '0.8vw'
                                                }}>{t('Solde Récupérable (TTC)')}</p>
                                                <p style={{
                                                    display: 'inline-flex',
                                                    marginLeft: '15px',
                                                    fontSize: '1vw',
                                                    fontWeight: '500'
                                                }}>{userWallet + " €"}</p>
                                                {userWallet > 0 ?

                                                    <Button style={{
                                                        backgroundColor: colorProductBtn,
                                                        color: colorHeaderText
                                                    }}
                                                            onClick={() => {
                                                                payout(user_id as unknown as number, Math.round(userWallet * 100)).then(res => {
                                                                    setDataFetched(false)
                                                                })
                                                            }}>
                                                        {t('Récupérer')}
                                                    </Button> : <>{t('Chargement')}</>}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div style={{
                                marginTop: '20px',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #FFFFFF',
                                borderRadius: '5px',
                                width: '95%',
                                margin: '20px auto'
                            }}>
                                <div style={{padding: '20px'}}>
                                    <Row>
                                        <Col flex={2}>
                                            <Space.Compact style={{width: '95%'}}>
                                                <Input
                                                    name='search'
                                                    placeholder="Rechercher"
                                                    style={{width: '100%'}}
                                                    value={searchText} // Utiliser la valeur de l'état searchText
                                                    onChange={(e) => handleSearch(e.target.value)} // Mettre à jour l'état searchText avec la nouvelle valeur
                                                />

                                                {/*<Button type="primary" style={{*/}
                                                {/*    color: colorHeaderText, backgroundColor:*/}
                                                {/*    colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,*/}
                                                {/*}}><SearchOutlined/></Button>*/}
                                            </Space.Compact>
                                        </Col>
                                        <Col flex={2}>
                                            <Select
                                                showSearch
                                                placeholder="Statut"
                                                optionFilterProp="children"
                                                style={{width: '95%'}}
                                                onChange={onChange}

                                                options={filterOptions}
                                            />
                                        </Col>
                                        <Col flex={1} style={{display: 'contents'}}>
                                            {/*<Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}>*/}
                                            {/*    Exporter tout*/}
                                            {/*</Button>*/}
                                            {localStorage.getItem('user_id') === "1732" ?
                                                (
                                                    <DownloadSpecificOrders/>
                                                ) :
                                                (
                                                    <DownloadOrdersNotRecupered/>
                                                )
                                            }
                                        </Col>
                                        <Col flex={1} style={{display: 'contents'}}>
                                            {/*<Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}>*/}
                                            {/*    Exporter tout*/}
                                            {/*</Button>*/}
                                                <DownloadOrders/>
                                        </Col>

                                    </Row>
                                </div>
                                <div style={{marginTop: '10px'}}>
                                    <style>
                                        {`
                                  .custom-table tbody {
                                    height: 20px;
                                    overflow-y: auto;
                                  }
                                  
                                  /* Remove default padding on the table and related elements */
                                  .custom-table .ant-table-thead tr th,
                                  .custom-table .ant-table-tbody tr td {
                                    padding: 5px;
                                  }
                                  
                                  .ant-table-thead th {
                                      font-size: 0.7vw; /* Replace 16px with your desired font size for the table header cells */
                                  }
                                `}
                                    </style>
                                    <Table
                                        dataSource={filteredData.slice(
                                            (currentPage - 1) * itemsPerPage,
                                            currentPage * itemsPerPage
                                        )}
                                        columns={columns}
                                        bordered
                                        pagination={false}
                                        className="custom-table"
                                        style={{margin: '0 20px'}}
                                        scroll={{y: 500}}
                                    />
                                </div>
                                <div style={{margin: '20px'}}>
                                    <style>
                                        {`
                                  .customPaginationStyle {
                                     display: flex;
                                     justify-content: center;
                                     align-items: center;
                                  }
                                  
                                  .customPaginationStyle : hover {
                                     background-color : #000000
                                  }
                                `}
                                    </style>
                                    <Pagination
                                        total={totalItems}
                                        current={currentPage}
                                        showSizeChanger
                                        onShowSizeChange={handleItemsPerPageChange}
                                        onChange={handlePageChange}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} commandes`}
                                        pageSize={itemsPerPage}
                                        defaultCurrent={1}
                                        style={customPaginationStyle}
                                    />
                                </div>
                            </div>
                        </div>
            ) : (
                <div>
                    <div style={{margin: '15px'}}>
                        <h1 style={{fontSize: '24px'}}>{t('Aperçu Bancaire')}</h1>
                        <div style={{margin: 'auto', textAlign: 'center', marginTop: '10%'}}>
                            <span style={{marginRight: '10%'}}>{t('Chargement de votre aperçu en cours')} </span>
                            <Spin size="large"/>
                            <div style={{marginTop: '20px'}}>
                                <Progress percent={progress} status="active"/>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div>

    );

}