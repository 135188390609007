export default async function fetchAPI(url: string, method: string, auth: boolean, to_json: boolean, bodyData: any = null, timeoutMillis: number = 100000000) {
    let headers;
    if (auth) {
        headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        };
    } else {
        headers = {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'referrerPolicy': 'no-referrer',
        };
    }

    const options: any = {
        method,
        headers
    };

    if (bodyData) {
        options.body = JSON.stringify(bodyData);
    }

    try {
        const controller = new AbortController();
        const signal = controller.signal;

        // Start a timer to abort the request after the specified timeout
        const timeoutId = setTimeout(() => {
            controller.abort();
        }, timeoutMillis);

        const response = await fetch(`https://${process.env.REACT_APP_API_URL}/${url}`, {
            ...options,
            signal // Pass the signal to the fetch call
        });

        // Clear the timeout if the request completes before the timeout
        clearTimeout(timeoutId);

        if (to_json) {
            return await response.json();
        }
        return response;
    } catch (error) {
        // @ts-ignore
        if (error.name === 'AbortError') {
            console.log('Request timed out');
        } else {
            console.error('Error:', error);
        }
        return 404; // or handle the error as needed
    }
}
