import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {CacheProvider} from "./components/Misc/CacheContext";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);


root.render(
    <React.StrictMode>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <CacheProvider>
                <App/>
            </CacheProvider>
        </BrowserRouter>
    </React.StrictMode>
);
//
reportWebVitals();
