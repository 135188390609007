import fetchAPI from "../Fetch";
import {pay} from "../../pages/Cart/pay";
import {Promotion} from "../Promotions";
import {ProductInterface} from "../Products/Products";

export interface Order {
    id: number,
    creation_date: number,
    modification_date: number,
    buyer_id: number,
    buyer_billing_id: number,
    buyer_delivery_id: number,
    promotion_id: number | null,
    promotion: Promotion | null,
    sub_orders: SubOrders[],
    MangoPayOrderTransaction: MangoPayOrderTransaction[]
}

export interface SubOrders {
    id: number,
    order_id: number,
    expedition_id: number,
    product_id: number,
    product_price_HT: number,
    product_price_TTC: number,
    quantity: number,
    state_id: number,
    delivery_date: number | null,
    delivery_done: boolean,
    livraisonId: number | null,
    mangoUserId: number | null,
    expedition: Expedition
    product: ProductInterface
}

export interface Expedition {
    conditions: any,
    delais: any,
    id: number,
    max_weight: number,
    min_weight: number,
    price: number,
    name: string
    slug: string,
    vendor_id: number,
    zone_id: number
}

export interface MangoPayOrderTransaction {
    id: number,
    transactionID: string,
    created_at: number,
    order_id: number,
    done: boolean,
    transfered: number,
    recovered: boolean
}

async function createOrder(deliveryId: number, expeditionId: number, valuepay: number) {
    const promotionCode = localStorage.getItem("promotionCode")
    if (promotionCode !== null) {
        const order = await fetchAPI("order/", "POST", true, true, {
            deliveryId: deliveryId,
            expeditionId: expeditionId,
            codePromo: promotionCode
        })
        console.log(order)
        pay(order.response.id, valuepay)
    } else {
        const order = await fetchAPI("order/", "POST", true, true, {deliveryId: deliveryId, expeditionId: expeditionId})
        console.log(order.response)
        pay(order.response.id, valuepay)
    }
}

async function getValidatedOrder(transactionID: number|string) {
    const order = await fetchAPI("order/transaction/" + transactionID, "GET", true, true);
    // console.log("length",order.response)
    return order.response.myOrders
}

async function getAllOrders(): Promise<Order[]> {
    try {
        const order = await fetchAPI("order/all", "GET", true, true)
        return order.response.res as Order[]
    } catch (error) {
        return []
    }
}

async function getAllOrdersByModificationDate(): Promise<Order[]> {
    try {
        const order = await fetchAPI("order/allByUpdateDate", "GET", true, true)
        return order.response.res as Order[]
    } catch (error) {
        return []
    }
}


async function getOrder(orderId: number): Promise<Order> {
    try {
        const order = await fetchAPI("order/" + orderId, "GET", true, true)
        return order.response as Order

    } catch (error) {
        return {} as Order
    }
}


async function getVendorOrders(): Promise<Order[]> {
    try {
        const order = await fetchAPI("order/vendororders", "GET", true, true)

        console.log("ok", order.response.result)
        return order.response.result as Order[]
    } catch (error) {
        return {} as Order[]
    }
}

async function getVendorOrdersByVendorId(vendor_id: number): Promise<Order[]> {
    try {
        const order = await fetchAPI('order/vendor-orders/' + vendor_id, "GET", true, true)

        console.log("ok", order.response.result)
        return order.response.result as Order[]

    } catch (error) {
        return {} as Order[]
    }
}

async function getVendorSubOrders(): Promise<SubOrders[]> {
    try {
        const order = await fetchAPI("order/sub-orders", "GET", true, true)
        return order.response.subOrders as SubOrders[]

    } catch (error) {
        return {} as SubOrders[]
    }
}

async function getUserOrders(): Promise<SubOrders[]> {
    try {
        const order = await fetchAPI("order/sub-orders", "GET", true, true)
        return order.response.subOrders as SubOrders[]

    } catch (error) {
        return {} as SubOrders[]
    }
}

async function getMyOrders(): Promise<Order[]> {
    try {
        const order = await fetchAPI("order/myOrders", "GET", true, true)
        return order.response.myOrders as Order[]
    } catch (error) {
        return []
    }
}

async function deleteOrder(order_id: number) {
    try {
        const order = await fetchAPI("order/delete/" + order_id, "PUT", true, true)
        return order.status
    } catch (error) {
        return {} as Order
    }
}
async function changeOrderModificationDate(order_id: number, date: number) {
    try {
        const order = await fetchAPI("order/updateOrderModificationDate/" + order_id, "PUT", true, true, {
            modification_date: date
        })
        if(order.status === 200){
        }
        return order
    } catch (error) {
        return {} as Order
    }
}


async function changeSubOrderState(sub_order_id: number, state_id: number) {
    try {
        const order = await fetchAPI("order/updateSubOrderState/" + sub_order_id, "POST", true, true, {
            state_id: state_id
        })
        if(order.status === 200){
        }
        return order
    } catch (error) {
        return {} as Order
    }
}

async function changeOrderState(order_id: number, vendorIds:number[]){
    for (const vendorId of vendorIds) {
        try {
            const order = await getOrder(order_id)
            const suborders_ids: number[] = order.sub_orders.map(sub_order => sub_order.id);
            console.log(order)
            const result = await fetchAPI("order/updateOrderState/" + order_id, "POST", true, true, {
                "processing": suborders_ids,
                "cancelled": [],
                "vendor_id": vendorId
            })
            if (result.status === 200) {
                console.log(result);
            }
            console.log(result);
        } catch (error) {
           console.log(error)
        }
    }
}
async function createChronoLabel(order_id: number, vendorIds:number[]){
    for (const vendorId of vendorIds) {
        if(vendorId !== 1209 && vendorId !== 2432) {
            try {
                const order = await getOrder(order_id)
                const suborders_ids: number[] = order.sub_orders.map(sub_order => sub_order.id);
                console.log(order)
                const result = await fetchAPI("chrono/getOrderAdmin/" + order_id, "POST", true, true, {
                    "weight":100,
                    "vendor_id": vendorId
                })
                if (result.status === 200) {
                    console.log(result);
                }
                console.log(result);
            } catch (error) {
                console.log(error)
            }
        }
    }
}

async function changeOrdersToEnded(){
    try {
        return await fetchAPI("order/change_to_endend/", "GET", true, true)

    } catch (error) {
        return {}
    }
}

async function sendMailWithInvoiceToVendors(order_id: number){
        try {
            const response = await fetchAPI("media/sendMailOrdersWithInvoicesToVendors/"+ order_id, "GET", true, true)
            return response

        } catch (error) {
            return {}
        }

}

export {
    createOrder,
    getValidatedOrder,
    getAllOrders,
    getOrder,
    getVendorOrders,
    deleteOrder,
    getUserOrders,
    getMyOrders,
    getVendorSubOrders,
    changeSubOrderState,
    changeOrderState,
    getVendorOrdersByVendorId,
    createChronoLabel,
    sendMailWithInvoiceToVendors,
    getAllOrdersByModificationDate,
    changeOrderModificationDate,
    changeOrdersToEnded
}