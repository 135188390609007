import fetchAPI from "../Fetch";
import {UserAddress} from "../User";

export interface ChronoRelais {
    "accesPersonneMobiliteReduite": boolean,
    "actif": boolean,
    "adresse1": string,
    "adresse2": string,
    "adresse3": string,
    "codePays": string,
    "codePostal": string,
    "coordGeolocalisationLatitude": string,
    "coordGeolocalisationLongitude": string,
    "distanceEnMetre": string,
    "identifiant": string,
    "indiceDeLocalisation": string,
    listeHoraireOuverture: listeHoraireOuverture[],
    "localite": string,
    "nom": string,
    "poidsMaxi": string,
    "typeDePoint": string,
    "urlGoogleMaps": string
}

interface listeHoraireOuverture {
    "horairesAsString": string,
    "jour": string,
    "listeHoraireOuverture": [
        {
            "debut": string,
            "fin": string
        },
        {
            "debut": string,
            "fin": string
        }
    ]
}


async function getExpedition(shipping_id: number, chrono_type: number) {
    const cart_infos = await fetchAPI("cart", "POST", true, true, {deliveryId: shipping_id})
    return await cart_infos.response.cart.expeditionPrice.value[chrono_type]
}

// async function getExpeditionPrice(shipping_id: number, chrono_type:number) {
//     const cart_infos = await fetchAPI("cart", "POST", true, true, {deliveryId: shipping_id})
//     return await cart_infos.response.cart.expeditionPrice.value[chrono_type].price
// }

async function getExpeditionPriceById(expedition_id: number) {
    const cart_infos = await fetchAPI("expedition/getExpeditionPriceById/" + expedition_id, "GET", true, true)
    return cart_infos.response[0].price
}

// async function getExpeditionId(shipping_id: number, chrono_type:number) {
//     const cart_infos = await fetchAPI("cart", "POST", true, true, {deliveryId: shipping_id})
//     console.log('cart_info',cart_infos.response.cart)
//     return await cart_infos.response.cart.expeditionPrice.value[chrono_type].id
// }

async function chronopostLabel(order_id: number, state_id: number) {
    const api = await fetchAPI("chrono/getOrder/" + order_id, "GET", true, true, {state_id: state_id})
    return api.response
}

async function getChronopostLivraison(chrono_id: string) {
    const api = await fetchAPI("chrono/DeliveryStatus/" + chrono_id, "GET", true, true)
    return api.response
}

async function addChronopostLivraison(chrono_id: string, order_id: number) {
    const api = await fetchAPI("order/expedition/" + order_id, "POST", true, true, {
        "vendorID": 1209,
        "expeditionID": chrono_id
    })
    return api.response
}

async function getNormalExpeditionPriceForFreeDelivery(weight: number, name: string,zone_id:number) {
    const expedition = await fetchAPI("expedition/getAllExpeditionWithWeight", "POST", true, true, {
        "weight" : weight,
        "name" : name,
        "zone_id" : zone_id
    })
    return expedition.response[0].price
}


async function getPointsDeRelais(userAddress: UserAddress, weight: number) {
    const api = await fetchAPI("chrono/Relais", "POST", true, true, {
        address: userAddress.address,
        zipcode: userAddress.zipcode,
        city: userAddress.city,
        countryCode: userAddress.country_code,
        weight: weight.toString()
    })
    console.log("api aled", api,{
        address: userAddress.address,
        zipcode: userAddress.zipcode,
        city: userAddress.city,
        countryCode: userAddress.country_code,
        weight: weight.toString()
    })
    return api.response as ChronoRelais[]
}

export {
    getExpedition,
    getExpeditionPriceById,
    chronopostLabel,
    getChronopostLivraison,
    addChronopostLivraison,
    getPointsDeRelais,
    getNormalExpeditionPriceForFreeDelivery
}