import React, {useEffect, useState} from 'react';
import {getProductsAllPublishedCategory, real_newsletter, test_newsletter} from '../../Products/Products';

interface Product {
    id: number;
    name: string;
    price: number;
    pricePromo: number;
    slug: string;
    category: string;
}

interface CategoryWithProducts {
    category_name: string;
    products: Product[];
}

// Composant Spinner de Chargement
const LoadingSpinner: React.FC = () => (
    <div style={styles.spinnerContainer}>
        <div style={styles.spinner}></div>
        <p>Chargement des produits...</p>
    </div>
);

const GererTemplatesEmailSendGrid: React.FC = () => {
    const [categoriesAvecProduits, setCategoriesAvecProduits] = useState<CategoryWithProducts[]>([]);
    const [chargement, setChargement] = useState<boolean>(true);
    const [produitsSelectionnes, setProduitsSelectionnes] = useState<{ [category: string]: Product[] }>({});
    const [erreur, setErreur] = useState<string | null>(null);
    const [pagination, setPagination] = useState<{ [category: string]: number }>({}); // Suivi de la page actuelle pour chaque catégorie
    const [produitsParPage] = useState<number>(10); // Nombre de produits par page
    const [termeRecherche, setTermeRecherche] = useState<string>(''); // Terme de recherche
    const [sujetEmail, setSujetEmail] = useState<string>(''); // Sujet de l'email

    useEffect(() => {
        async function recupererProduits() {
            try {
                setChargement(true);
                const response = await getProductsAllPublishedCategory();
                const produitsData = response;

                const categoriesMap: { [key: string]: Product[] } = {};
                produitsData.forEach((item: any) => {
                    const produit = item;

                    if (produit.product_categories && produit.product_categories.length > 0) {
                        const categorie = produit.product_categories[0].category_name;

                        const produitData: Product = {
                            id: produit.id,
                            name: produit.product_name,
                            price: (produit.price * (1 + (produit.commission.value / 100))) * 1.2,
                            pricePromo: (produit.price_promo ? produit.price_promo * (1 + (produit.commission.value / 100)) * 1.2 : 0),
                            category: categorie,
                            slug: produit.slug,
                        };

                        if (!categoriesMap[categorie]) {
                            categoriesMap[categorie] = [];
                        }
                        categoriesMap[categorie].push(produitData);
                    }
                });

                const categoriesArray = Object.keys(categoriesMap).map(nomCategorie => ({
                    category_name: nomCategorie,
                    products: categoriesMap[nomCategorie],
                }));

                setCategoriesAvecProduits(categoriesArray);

                // Initialiser la pagination pour chaque catégorie
                const paginationInitiale: { [key: string]: number } = {};
                categoriesArray.forEach(categorie => {
                    paginationInitiale[categorie.category_name] = 1;
                });
                setPagination(paginationInitiale);
            } catch (erreur) {
                console.error('Erreur lors de la récupération des produits:', erreur);
            } finally {
                setChargement(false);
            }
        }

        recupererProduits();
    }, []);

    // Gérer la sélection des produits
    const gererSelectionProduit = (nomCategorie: string, produit: Product) => {
        setProduitsSelectionnes(precedentSelectionnes => {
            const selectionDansCategorie = precedentSelectionnes[nomCategorie] || [];
            const estSelectionne = selectionDansCategorie.find(p => p.id === produit.id);

            if (estSelectionne) {
                // Retirer le produit s'il est déjà sélectionné
                const selectionCategorieMiseAJour = selectionDansCategorie.filter(p => p.id !== produit.id);
                return {...precedentSelectionnes, [nomCategorie]: selectionCategorieMiseAJour};
            } else {
                // Ajouter le produit s'il n'est pas sélectionné
                const selectionCategorieMiseAJour = [...selectionDansCategorie, produit];
                return {...precedentSelectionnes, [nomCategorie]: selectionCategorieMiseAJour};
            }
        });
    };

    // Soumettre les produits sélectionnés
    const soumettreProduitsTest = () => {
        if (!sujetEmail) {
            setErreur('Le sujet de l\'email est obligatoire.');
            return;
        }

        const confirmation = window.confirm(
            'Êtes-vous sûr de vouloir envoyer la newsletter test ? Cette action ne peut pas être annulée.'
        );

        if (!confirmation) {
            return;
        }

        // Valider si tous les produits sélectionnés dans chaque catégorie sont par paire
        for (const categorie in produitsSelectionnes) {
            if (produitsSelectionnes[categorie].length % 2 !== 0) {
                setErreur(`Veuillez sélectionner 2 produits dans la catégorie "${categorie}".`);
                return;
            }
        }

        setErreur(null);

        const idsProduitsSelectionnes = Object.values(produitsSelectionnes).flat().map(produit => produit.id);

        console.log('IDs des produits sélectionnés:', idsProduitsSelectionnes);
        test_newsletter(idsProduitsSelectionnes, sujetEmail).then(r => console.log(r));
        alert(`IDs des produits sélectionnés: ${idsProduitsSelectionnes.join(', ')}`);
    };

    const soumettreProduitsReels = () => {
        if (!sujetEmail) {
            setErreur('Le sujet de l\'email est obligatoire.');
            return;
        }

        const confirmation = window.confirm(
            'Êtes-vous sûr de vouloir envoyer la newsletter à tous les utilisateurs ? Cette action ne peut pas être annulée.'
        );

        if (!confirmation) {
            return;
        }

        for (const categorie in produitsSelectionnes) {
            if (produitsSelectionnes[categorie].length % 2 !== 0) {
                setErreur(`Veuillez sélectionner 2 produits dans la catégorie "${categorie}".`);
                return;
            }
        }

        setErreur(null);

        const idsProduitsSelectionnes = Object.values(produitsSelectionnes).flat().map(produit => produit.id);

        console.log('IDs des produits sélectionnés:', idsProduitsSelectionnes);
        real_newsletter(idsProduitsSelectionnes, sujetEmail).then(r => console.log(r));

        alert(`IDs des produits sélectionnés: ${idsProduitsSelectionnes.join(', ')}`);
    };

    const indiceDernierProduit = (nomCategorie: string) => pagination[nomCategorie] * produitsParPage;
    const indicePremierProduit = (nomCategorie: string) => indiceDernierProduit(nomCategorie) - produitsParPage;

    const paginer = (nomCategorie: string, numeroPage: number) => {
        setPagination(precedent => ({...precedent, [nomCategorie]: numeroPage}));
    };

    const filtrerProduitsParRecherche = (produits: Product[]) => {
        return produits.filter(produit =>
            produit.name.toLowerCase().includes(termeRecherche.toLowerCase())
        );
    };

    const afficherTableProduits = (categorie: CategoryWithProducts) => {
        const produitsFiltres = filtrerProduitsParRecherche(categorie.products);
        const produitsActuels = produitsFiltres.slice(indicePremierProduit(categorie.category_name), indiceDernierProduit(categorie.category_name));

        const totalPages = Math.ceil(produitsFiltres.length / produitsParPage);
        const maxPagesVisibles = 5;
        const pageActuelle = pagination[categorie.category_name];

        const pageDebut = Math.max(1, pageActuelle - Math.floor(maxPagesVisibles / 2));
        const pageFin = Math.min(totalPages, pageDebut + maxPagesVisibles - 1);

        return (
            <>
                <table style={styles.tableProduits}>
                    <thead>
                    <tr>
                        <th>Sélectionner</th>
                        <th>Nom du produit</th>
                        <th>Prix (€)</th>
                        <th>Prix promo (€)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {produitsActuels.map(produit => (
                        <tr key={produit.id}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={produitsSelectionnes[categorie.category_name]?.some(p => p.id === produit.id) || false}
                                    onChange={() => gererSelectionProduit(categorie.category_name, produit)}
                                />
                            </td>
                            <td>
                                <a href={"https://www.poseidonshop.fr/product/" + produit.slug} target="_blank"
                                   rel="noopener noreferrer">
                                    {produit.name}
                                </a>
                            </td>
                            <td>{produit.price.toFixed(2)}</td>
                            <td>{produit.pricePromo > 0 ? produit.pricePromo.toFixed(2) : '-'}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div style={styles.pagination}>
                    <button
                        onClick={() => paginer(categorie.category_name, pageActuelle - 1)}
                        disabled={pageActuelle === 1}
                        style={styles.boutonPage}
                    >
                        Précédent
                    </button>
                    {Array.from({length: pageFin - pageDebut + 1}, (_, i) => (
                        <button
                            key={pageDebut + i}
                            onClick={() => paginer(categorie.category_name, pageDebut + i)}
                            style={styles.boutonPage}
                            disabled={pageActuelle === pageDebut + i}
                        >
                            {pageDebut + i}
                        </button>
                    ))}
                    <button
                        onClick={() => paginer(categorie.category_name, pageActuelle + 1)}
                        disabled={pageActuelle === totalPages}
                        style={styles.boutonPage}
                    >
                        Suivant
                    </button>
                </div>
            </>
        );
    };

    return (
        <div style={styles.app}>
            <h1>Sélectionner des produits par paire</h1>
            <input
                type="text"
                placeholder="Sujet de l'email (obligatoire)"
                value={sujetEmail}
                onChange={e => setSujetEmail(e.target.value)}
                style={styles.inputSujet}
            />
            <input
                type="text"
                placeholder="Rechercher des produits..."
                value={termeRecherche}
                onChange={e => setTermeRecherche(e.target.value)}
                style={styles.inputRecherche}
            />
            {chargement ? (
                <LoadingSpinner/>
            ) : (
                <>
                    {categoriesAvecProduits.map(categorie => (
                        <div key={categorie.category_name} style={styles.sectionCategorie}>
                            <h2>{categorie.category_name}</h2>
                            {afficherTableProduits(categorie)}
                        </div>
                    ))}
                    {erreur && <p style={styles.texteErreur}>{erreur}</p>}
                    <div>
                        <button onClick={soumettreProduitsTest} style={styles.boutonSoumettre} disabled={chargement}>
                            Envoyer un mail de test
                        </button>
                    </div>
                    <div>
                        <button onClick={soumettreProduitsReels} style={styles.boutonSoumettre} disabled={chargement}>
                            Envoyer la newsletter (ATTENTION, MAIL ENVOYÉ À TOUS LES UTILISATEURS)
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

// Styles en utilisant du CSS inline
const styles: { [key: string]: React.CSSProperties } = {
    app: {
        padding: '20px',
    },
    spinnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    tableProduits: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
    boutonPage: {
        margin: '0 5px',
        padding: '5px 10px',
        cursor: 'pointer',
    },
    boutonSoumettre: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#3498db',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
    },
    texteErreur: {
        color: 'red',
        fontWeight: 'bold',
    },
    inputSujet: {
        padding: '10px',
        marginBottom: '20px',
        fontSize: '16px',
        width: '100%',
    },
    inputRecherche: {
        padding: '10px',
        marginBottom: '20px',
        fontSize: '16px',
        width: '100%',
    },
};

// Keyframes pour l'animation du spinner (ajouter à votre fichier CSS ou utiliser des styles inline)
const spinnerKeyframes = `
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;

const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = spinnerKeyframes;
document.head.appendChild(styleSheet);

export default GererTemplatesEmailSendGrid;
