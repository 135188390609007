import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {getUserMangoWallet, User} from "../../../components/User";
import {BankView, Commands, CreateProduct, Products, Settings} from "../../../components/Dashboard";
import {Avatar, Col, Layout, Menu, Row, Typography} from "antd";
import {
    PoweroffOutlined,
    RightOutlined,
    SettingOutlined,
    ShopOutlined,
    TagsOutlined,
    UserOutlined
} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWallet} from "@fortawesome/free-solid-svg-icons";
import TransactionView from "../../../components/Dashboard/Admin/TransactionView";
import CreateProductAdmin from "../../../components/Dashboard/Admin/CreateProduct";
import Logout from "../../../components/Misc/Logout";
const { Content } = Layout;
const { Title, Text } = Typography;


const DashboardMobile: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [dataFetched, setDataFetched] = useState(false);
    const [userWallet, setUserWallet] = useState<number>(0);
    const [userInfo, setUserInfo] = useState<any>({});
    const [selectedComponent, setSelectedComponent] = useState<string>('home');


    useEffect(() => {
        const fetchUserInfos = async () => {
            try {
                const user_infos = await User();
                const wallet_id = user_infos ? user_infos.MangoPayUser[0].wallet_id : undefined;
                if (wallet_id) {
                    setUserWallet(await getUserMangoWallet(wallet_id as unknown as number));
                }
                setUserInfo(user_infos);
            } catch (error) {
                console.log(error);
            }
            setDataFetched(true);
        };
        if (!dataFetched) {
            fetchUserInfos();
        }

        // Vérifie si l'utilisateur souhaite createProduct
        if (localStorage.getItem('createProduct')) {
            setSelectedComponent('createProduct')
        }

    }, [dataFetched]);

    useEffect(() => {
        if (location.pathname === '/dashboard') {
            setSelectedComponent('home');
        } else {
            const pathComponent = location.pathname.split('/').pop();
            if (pathComponent) {
                setSelectedComponent(pathComponent);
            }
        }
    }, [location.pathname]);

    const handleItemClick = (component: string) => {
        setSelectedComponent(component);
        if (component !== 'home') {
            navigate(`/dashboard/${component}`);
        } else {
            navigate('/dashboard');
        }
    };

    const renderContent = () => {
        switch (selectedComponent) {
            case 'products':
                return <Products />;
            case 'commands':
                return <TransactionView />;
            case 'settings':
                return <Settings />;
            case 'bankView':
                return <BankView />;
            case 'createProduct':
                return <CreateProduct />;
            case 'logout':
                return <Logout />;
            default:
                return null;
        }
    };

    if (selectedComponent !== 'home') {
        return (
            <Layout>
                <Content style={{margin: '0px 16px', padding: 24, minHeight: '100vh', background: "#F3F5F6", borderRadius: '15px'}}>
                    {renderContent()}
                </Content>
            </Layout>
        );
    }


    return (
        <Layout style={{ backgroundColor: '#fff' }}>
            <style>
                {`
                      :where(.css-dev-only-do-not-override-txh9fw).ant-avatar.ant-avatar-icon {
                        font-size: 18px;
                      }    
                
                      .anticon svg { display: block !important;}
                `}
            </style>
            <Content style={{ padding: '20px' }}>
                <Title level={3} style={{ fontSize: 24, fontWeight: 'bold',marginBottom: '25px' }}>Mon compte</Title>
                <div style={{padding: '22px', background: '#fff', borderRadius: '8px', marginBottom: '30px', boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)'}}
                     onClick={() => handleItemClick('settings')}>
                    <Row align="middle">
                        <Col>
                            <Avatar shape="circle" size="large" icon={<UserOutlined  onPointerEnterCapture={undefined}
                                                                                     onPointerLeaveCapture={undefined} />} />
                        </Col>
                        <Col flex="auto" style={{ paddingLeft: '20px' }}>
                            <Title level={4} style={{ marginBottom: '0', fontWeight: 'bold' }}>{userInfo?.firstname}</Title>
                        </Col>
                        <Col>
                            <RightOutlined style={{ fontSize: '23px', color: '#ccd2dd' }}  onPointerEnterCapture={undefined}
                                           onPointerLeaveCapture={undefined} />
                        </Col>
                    </Row>
                </div>

                <div style={{padding: '20px', background: '#fff', borderRadius: '8px', marginBottom: '10px', boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)'}}
                     onClick={() => handleItemClick('bankView')}>
                    <Row align="middle">
                        <Col>
                            <FontAwesomeIcon icon={faWallet} style={{ fontSize: '28px' }} />
                        </Col>
                        <Col flex="auto" style={{ paddingLeft: '30px' }}>
                            <Title level={4} style={{ fontWeight: 'bold' }}>Porte-monnaie</Title>
                            <Text strong style={{ fontSize: '24px' }}>{userWallet} €</Text>
                            <Text type="secondary" style={{ display: 'block' }}>Solde disponible</Text>
                        </Col>
                        <Col>
                            <RightOutlined style={{ fontSize: '23px', color: '#ccd2dd' }} onPointerEnterCapture={undefined}
                                           onPointerLeaveCapture={undefined}  />
                        </Col>
                    </Row>
                </div>
            </Content>


            <Menu style={{borderInlineEnd: '0px', background: '#fff', marginBottom: '10px'}}
                  theme="light"
                  mode="inline"
                  defaultSelectedKeys={['1']}
                  onClick={({ key }) => handleItemClick(key)}
                  items={[
                      {
                          key: 'products',
                          icon: <TagsOutlined style={{ fontSize: '24px' }}  onPointerEnterCapture={undefined}
                                              onPointerLeaveCapture={undefined} />,
                          label: <span style={{ fontSize: '19px',fontWeight: 'bold' }}>{t('Produits')}</span>,
                          style: {
                              borderBottom: '1px solid #ccd2dd',
                              borderRadius: '0px',
                              marginBottom: '10px'
                          },
                      },
                      {
                          key: 'commands',
                          icon: <ShopOutlined style={{ fontSize: '24px' }}  onPointerEnterCapture={undefined}
                                              onPointerLeaveCapture={undefined} />,
                          label: <span style={{ fontSize: '19px',fontWeight: 'bold' }}>{t('Commandes')}</span>,
                          style: {
                              borderBottom: '1px solid #ccd2dd',
                              borderRadius: '0px',
                              marginBottom: '10px'
                          },
                      },
                      {
                          key: 'settings',
                          icon: <SettingOutlined style={{fontSize: '24px'}} onPointerEnterCapture={undefined}
                                                 onPointerLeaveCapture={undefined} />,
                          label: <span style={{ fontSize: '19px',fontWeight: 'bold' }}>{t('Paramètres')}</span>,
                          style: {
                              borderBottom: '1px solid #ccd2dd',
                              borderRadius: '0px',
                              marginBottom: '10px'
                          },
                      },
                      {
                          key: 'logout',
                          icon: <PoweroffOutlined style={{fontSize: '24px'}} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>,
                          label: <span style={{ fontSize: '19px',fontWeight: 'bold' }}>{t('Déconnexion')}</span>,
                          style: {
                              borderBottom: '1px solid #ccd2dd',
                              borderRadius: '0px',
                              marginBottom: '10px'
                          },
                      }
                  ]}
            />

        </Layout>
    );
};

export default DashboardMobile;