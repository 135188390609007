import React, {useEffect, useRef, useState} from "react";
import Chart from 'chart.js/auto';
import {
    Timeline,
    Divider,
    Steps,
    Button,
    Card,
    Col,
    Input,
    Pagination,
    Row,
    Select,
    Space,
    Table,
    Statistic, Tooltip
} from "antd";
import {ClockCircleOutlined} from '@ant-design/icons';
import "@fontsource/montserrat";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/400-italic.css";
import {DeleteOutlined, EditOutlined, SearchOutlined} from "@ant-design/icons";
import {getProductByID, getVendorProduct, GlobalProduct} from "../../Products/Products";
import {ArrowDownOutlined, ArrowUpOutlined} from '@ant-design/icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChartLine,
    faSackDollar,
    faMoneyBillWave,
    faUsers,
    faBoxOpen,
    faUserGroup,
    faWallet,
    faUser,
    faBagShopping,
    faEnvelope,
    faTruckFast
} from "@fortawesome/free-solid-svg-icons";
import {getMyOrders, Order} from "../../Order";
import {getExpeditionPriceById} from "../../Expedition";
import {t} from "i18next";


interface dataSourceInterface {
    key: string,
    id: string,
    date: string,
    state: string,
    priceTotal: number,
    productName: string,
    livraison_id: string,
}


export default function HomeClient() {


    const {Meta} = Card;
    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"

    const [ordersInfos, setOrdersInfos] = useState({} as Order[]);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);

    useEffect(() => {
        const fetchOrdersInfos = async () => {
            const orders = await getMyOrders();
            setOrdersInfos(orders)
            setDataFetched(true);
            let newOption: dataSourceInterface;
            try {
                let mappedOptions: dataSourceInterface[] = [];
                for (const order of orders) {

                    let productName = ""
                    let expeditionPrice = 0
                    let priceTotal = 0;
                    for (const subOrder of order.sub_orders) {
                        const product = await getProductByID(subOrder.product_id)
                        productName = product.product_name
                        priceTotal += subOrder.product_price_TTC
                        expeditionPrice = await getExpeditionPriceById(subOrder.expedition_id) * 1.2
                    }
                    if (order.sub_orders[0].state_id !== 7) {
                        newOption = {
                            key: order.id as unknown as string,
                            id: order.id as unknown as string,

                            date: new Date(order.creation_date * 1000).toDateString(),
                            state: order.sub_orders[0].state_id as unknown as string,
                            productName: productName,
                            priceTotal: (expeditionPrice + priceTotal).toFixed(2) as unknown as number,
                            livraison_id: order.sub_orders[0].livraisonId as unknown as string,
                        };
                        mappedOptions.push(newOption);

                        // Update the state immediately with the new option
                        setOptions(() => [...mappedOptions]);

                    }


                }
            } catch (error) {
                console.log(error, "No user data found");
            }
        };
        if (!dataFetched) {
            fetchOrdersInfos();
        }
    }, [dataFetched]);


    const dataSource = options

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Produit(s) commandé(s)',
            dataIndex: 'productName',
            key: 'productName',
            width: '15%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text + "..."}</span>
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            sorter: (a: dataSourceInterface, b: dataSourceInterface) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA.getTime() - dateB.getTime();
            },
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Status',
            dataIndex: 'state',
            key: 'state',
            width: '15%',
            render: (text: any, record: any) => {
                if (record.state === 1) {
                    return <span style={{
                        color: '#00A3FF',
                        backgroundColor: '#F1FAFF',
                        padding: '5px',
                        border: '1px solid #DCF2E6',
                        borderRadius: '5px'
                    }}>En attente de paiement</span>;
                } else if (record.state === 2) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>Paiement réussi</span>;
                } else if (record.state === 3) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>En cours de préparation chez le vendeur</span>;
                } else if (record.state === 5) {
                    return <span style={{
                        color: '#f1b041',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Remboursée')}</span>;
                } else if (record.state === 8) {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>Paiement refusé</span>;
                }else if (record.state === 9) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>
                        <Tooltip title={record.details}>
                          <span>En cours de livraison</span>
                        </Tooltip>
                    </span>;
                } else {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{text}</span>;
                }
            },
        },

        {
            title: 'Total',
            dataIndex: 'priceTotal',
            key: 'priceTotal',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    <Button style={{margin: '5px', backgroundColor: colorProductBtn, color: colorHeaderText}}
                            onClick={() => {
                                window.location.pathname = "/viewOrderInfos/" + record.id
                            }}>
                        Détails
                    </Button>
                    {record.livraison_id !== null ?
                        <Button onClick={() => {
                            window.open("https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=" + record.livraison_id + "&langue=fr_FR")
                        }}>Suivre mon colis</Button>
                        : <></>
                    }

                </div>
            )
        },
    ];


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const totalItems = dataSource.length;
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;

    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (current: any, size: any) => {
        setItemsPerPage(size);
    };

    const customPaginationStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };


    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // page affiché
    return (
        <div>
            {windowWidth < 768 ? (
                <div
                    style={{
                        margin: '0px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#F3F5F6",
                        borderRadius: '15px'
                    }}>
                    {/*<Row gutter={16}>*/}
                    {/*    <Col style={{width: '90%', margin: '10px auto'}}>*/}
                    {/*        <Card style={{width: '100%'}}>*/}
                    {/*            <Row>*/}
                    {/*                <Col style={{width: '75%'}}>*/}
                    {/*                    <Row>*/}
                    {/*                        <p style={{*/}
                    {/*                            width: '100%', fontSize: '16px', fontFamily: 'Montserrat, sans-serif',*/}
                    {/*                            color: colorHeaderText*/}
                    {/*                        }}><b>Mon profil</b></p>*/}
                    {/*                    </Row>*/}
                    {/*                </Col>*/}
                    {/*                <Col style={{*/}
                    {/*                    width: '25%',*/}
                    {/*                    justifyContent: 'center',*/}
                    {/*                    alignItems: 'center',*/}
                    {/*                    display: "flex"*/}
                    {/*                }}>*/}
                    {/*                    <FontAwesomeIcon icon={faUser} size={'2x'}/>*/}
                    {/*                </Col>*/}
                    {/*            </Row>*/}
                    {/*        </Card>*/}
                    {/*    </Col>*/}
                    {/*    <Col style={{width: '90%', margin: '10px auto'}}>*/}
                    {/*        <Card>*/}
                    {/*            <Row>*/}
                    {/*                <Col style={{width: '75%'}}>*/}
                    {/*                    <p style={{*/}
                    {/*                        fontSize: '16px', width: '100%', fontFamily: 'Montserrat, sans-serif',*/}
                    {/*                        color: colorHeaderText*/}
                    {/*                    }}><b>Transactions</b></p>*/}
                    {/*                </Col>*/}
                    {/*                <Col style={{*/}
                    {/*                    width: '25%',*/}
                    {/*                    justifyContent: 'center',*/}
                    {/*                    alignItems: 'center',*/}
                    {/*                    display: "flex"*/}
                    {/*                }}>*/}
                    {/*                    <FontAwesomeIcon icon={faBagShopping} size={'2x'}/>*/}
                    {/*                </Col>*/}
                    {/*            </Row>*/}
                    {/*        </Card>*/}
                    {/*    </Col>*/}
                    {/*    <Col style={{width: '90%', margin: '10px auto'}}>*/}
                    {/*        <Card>*/}
                    {/*            <Row>*/}
                    {/*                <Col style={{width: '75%'}}>*/}
                    {/*                    <p style={{*/}
                    {/*                        fontSize: '16px', width: '100%', fontFamily: 'Montserrat, sans-serif',*/}
                    {/*                        color: colorHeaderText*/}
                    {/*                    }}><b>Messagerie</b></p>*/}
                    {/*                </Col>*/}
                    {/*                <Col style={{*/}
                    {/*                    width: '25%',*/}
                    {/*                    justifyContent: 'center',*/}
                    {/*                    alignItems: 'center',*/}
                    {/*                    display: "flex"*/}
                    {/*                }}>*/}
                    {/*                    <FontAwesomeIcon icon={faEnvelope} size={'2x'}/>*/}
                    {/*                </Col>*/}
                    {/*            </Row>*/}
                    {/*        </Card>*/}
                    {/*    </Col>*/}
                    {/*    <Col style={{width: '90%', margin: '10px auto'}}>*/}
                    {/*        <Card>*/}
                    {/*            <Row>*/}
                    {/*                <Col style={{width: '75%'}}>*/}
                    {/*                    <p style={{*/}
                    {/*                        width: '100%', fontSize: '16px', fontFamily: 'Montserrat, sans-serif',*/}
                    {/*                        color: colorHeaderText*/}
                    {/*                    }}><b>Commandes</b></p>*/}
                    {/*                </Col>*/}
                    {/*                <Col style={{*/}
                    {/*                    width: '25%',*/}
                    {/*                    justifyContent: 'center',*/}
                    {/*                    alignItems: 'center',*/}
                    {/*                    display: "flex"*/}
                    {/*                }}>*/}
                    {/*                    <FontAwesomeIcon icon={faTruckFast} size={'2x'}/>*/}
                    {/*                </Col>*/}
                    {/*            </Row>*/}
                    {/*        </Card>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </div>
            ) : windowWidth >= 768 && windowWidth < 1100 ? (
                <div
                    style={{
                        margin: '0px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: "#F3F5F6",
                        borderRadius: '15px'
                    }}>
                    {/*<Row gutter={16}>*/}
                    {/*    <Col style={{width: '45%', margin: '10px auto'}}>*/}
                    {/*        <Card style={{width: '100%'}}>*/}
                    {/*            <Row>*/}
                    {/*                <Col style={{width: '75%'}}>*/}
                    {/*                    <Row>*/}
                    {/*                        <p style={{*/}
                    {/*                            width: '100%', fontSize: '16px', fontFamily: 'Montserrat, sans-serif',*/}
                    {/*                            color: colorHeaderText*/}
                    {/*                        }}><b>Mon profil</b></p>*/}
                    {/*                    </Row>*/}
                    {/*                </Col>*/}
                    {/*                <Col style={{*/}
                    {/*                    width: '25%',*/}
                    {/*                    justifyContent: 'center',*/}
                    {/*                    alignItems: 'center',*/}
                    {/*                    display: "flex"*/}
                    {/*                }}>*/}
                    {/*                    <FontAwesomeIcon icon={faUser} size={'2x'}/>*/}
                    {/*                </Col>*/}
                    {/*            </Row>*/}
                    {/*        </Card>*/}
                    {/*    </Col>*/}
                    {/*    <Col style={{width: '45%', margin: '10px auto'}}>*/}
                    {/*        <Card>*/}
                    {/*            <Row>*/}
                    {/*                <Col style={{width: '75%'}}>*/}
                    {/*                    <p style={{*/}
                    {/*                        fontSize: '16px', width: '100%', fontFamily: 'Montserrat, sans-serif',*/}
                    {/*                        color: colorHeaderText*/}
                    {/*                    }}><b>Transactions</b></p>*/}
                    {/*                </Col>*/}
                    {/*                <Col style={{*/}
                    {/*                    width: '25%',*/}
                    {/*                    justifyContent: 'center',*/}
                    {/*                    alignItems: 'center',*/}
                    {/*                    display: "flex"*/}
                    {/*                }}>*/}
                    {/*                    <FontAwesomeIcon icon={faBagShopping} size={'2x'}/>*/}
                    {/*                </Col>*/}
                    {/*            </Row>*/}
                    {/*        </Card>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row gutter={16}>*/}
                    {/*    <Col style={{width: '45%', margin: '10px auto'}}>*/}
                    {/*        <Card>*/}
                    {/*            <Row>*/}
                    {/*                <Col style={{width: '75%'}}>*/}
                    {/*                    <p style={{*/}
                    {/*                        fontSize: '16px', width: '100%', fontFamily: 'Montserrat, sans-serif',*/}
                    {/*                        color: colorHeaderText*/}
                    {/*                    }}><b>Messagerie</b></p>*/}
                    {/*                </Col>*/}
                    {/*                <Col style={{*/}
                    {/*                    width: '25%',*/}
                    {/*                    justifyContent: 'center',*/}
                    {/*                    alignItems: 'center',*/}
                    {/*                    display: "flex"*/}
                    {/*                }}>*/}
                    {/*                    <FontAwesomeIcon icon={faEnvelope} size={'2x'}/>*/}
                    {/*                </Col>*/}
                    {/*            </Row>*/}
                    {/*        </Card>*/}
                    {/*    </Col>*/}
                    {/*    <Col style={{width: '45%', margin: '10px auto'}}>*/}
                    {/*        <Card>*/}
                    {/*            <Row>*/}
                    {/*                <Col style={{width: '75%'}}>*/}
                    {/*                    <p style={{*/}
                    {/*                        width: '100%', fontSize: '16px', fontFamily: 'Montserrat, sans-serif',*/}
                    {/*                        color: colorHeaderText*/}
                    {/*                    }}><b>Commandes</b></p>*/}
                    {/*                </Col>*/}
                    {/*                <Col style={{*/}
                    {/*                    width: '25%',*/}
                    {/*                    justifyContent: 'center',*/}
                    {/*                    alignItems: 'center',*/}
                    {/*                    display: "flex"*/}
                    {/*                }}>*/}
                    {/*                    <FontAwesomeIcon icon={faTruckFast} size={'2x'}/>*/}
                    {/*                </Col>*/}
                    {/*            </Row>*/}
                    {/*        </Card>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*TODO : dernières recherches du client*/}
                    {/*<div style={{display: 'flex'}}>*/}
                    {/*    <Card className={"test12"} style={{*/}
                    {/*        borderRadius: '12px',*/}
                    {/*        width: '95%',*/}
                    {/*        margin: '15px auto',*/}
                    {/*        padding: '15px',*/}
                    {/*        height: '50vh'*/}
                    {/*    }} bordered={false}>*/}
                    {/*        <h2 style={{*/}
                    {/*            fontFamily: 'Montserrat, sans-serif',*/}
                    {/*            color: colorHeaderText,*/}
                    {/*            fontSize: '18px'*/}
                    {/*        }}>Dernières recherches</h2>*/}
                    {/*        <div style={{*/}
                    {/*            marginTop: '20px',*/}
                    {/*            backgroundColor: '#FFFFFF',*/}
                    {/*            border: '1px solid #FFFFFF',*/}
                    {/*            borderRadius: '5px'*/}
                    {/*        }}>*/}
                    {/*            <div style={{marginTop: '10px', overflow: "hidden"}}>*/}
                    {/*                <style>*/}
                    {/*                    {`*/}
                    {/*      .custom-table tbody {*/}
                    {/*        height: 20px;*/}
                    {/*        overflow: hidden;*/}
                    {/*      }*/}
                    {/*    */}
                    {/*      .custom-table .ant-table-thead tr th,*/}
                    {/*      .custom-table .ant-table-tbody tr td {*/}
                    {/*        padding: 5px;*/}
                    {/*      }*/}
                    {/*    `}*/}
                    {/*                </style>*/}
                    {/*                <Table*/}
                    {/*                    dataSource={dataSource.slice(-5, dataSource.length)}*/}
                    {/*                    columns={columns}*/}
                    {/*                    bordered*/}
                    {/*                    pagination={false}*/}
                    {/*                    className="custom-table"*/}
                    {/*                    style={{margin: '0 20px', overflow: "hidden"}}*/}
                    {/*                    // Set the desired height here (e.g., 400px)*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div style={{margin: '20px'}}>*/}
                    {/*                <style>*/}
                    {/*                    {`*/}
                    {/*      .customPaginationStyle {*/}
                    {/*         display: flex;*/}
                    {/*         justify-content: center;*/}
                    {/*         align-items: center;*/}
                    {/*      }*/}
                    {/*      */}
                    {/*      .customPaginationStyle : hover {*/}
                    {/*         background-color : #000000*/}
                    {/*      }*/}
                    {/*    */}
                    {/*    `}*/}
                    {/*                </style>*/}

                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </Card>*/}
                    {/*</div>*/}
                    <div style={{display: 'flex'}}>
                        <Card className={"test12"} style={{
                            borderRadius: '12px',
                            width: '95%',
                            margin: '15px auto',
                            padding: '15px',
                            height: '50vh'
                        }} bordered={false}>
                            <h2 style={{
                                fontFamily: 'Montserrat, sans-serif',
                                color: colorHeaderText,
                                fontSize: '18px'
                            }}>Dernières commandes</h2>
                            <div style={{
                                marginTop: '20px',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #FFFFFF',
                                borderRadius: '5px'
                            }}>
                                <div style={{marginTop: '10px', overflow: "hidden"}}>
                                    <style>
                                        {`
                          .custom-table tbody {
                            height: 20px;
                            overflow: hidden;
                          }
                        
                          .custom-table .ant-table-thead tr th,
                          .custom-table .ant-table-tbody tr td {
                            padding: 5px;
                          }
                        `}
                                    </style>
                                    <Table
                                        dataSource={dataSource.slice(-5, dataSource.length)}
                                        columns={columns}
                                        bordered
                                        pagination={false}
                                        className="custom-table"
                                        style={{margin: '0 20px', overflow: "hidden"}}
                                        // Set the desired height here (e.g., 400px)
                                    />
                                </div>
                                <div style={{margin: '20px'}}>
                                    <style>
                                        {`
                          .customPaginationStyle {
                             display: flex;
                             justify-content: center;
                             align-items: center;
                          }
                          
                          .customPaginationStyle : hover {
                             background-color : #000000
                          }
                        
                        `}
                                    </style>

                                </div>
                            </div>
                        </Card>
                    </div>

                    {/*Graphique sur tablette*/}
                    {/*<Card className={"test10"} style={{*/}
                    {/*    borderRadius: '12px',*/}
                    {/*    width: '90%',*/}
                    {/*    margin: '20px auto',*/}
                    {/*    padding: '15px',*/}
                    {/*    height: '435px'*/}
                    {/*}} bordered={false}>*/}
                    {/*    <div style={{display: 'flex'}}>*/}
                    {/*        <h2 style={{*/}
                    {/*            fontFamily: 'Montserrat, sans-serif',*/}
                    {/*            color: colorHeaderText,*/}
                    {/*            fontSize: '18px'*/}
                    {/*        }}>Statistiques des Gains</h2>*/}
                    {/*        <Col flex={1} style={{justifyContent: 'end', marginRight: '20px', display: 'flex'}}>*/}
                    {/*            <Button style={{*/}
                    {/*                fontFamily: 'Montserrat, sans-serif',*/}
                    {/*                backgroundColor: '#F3F5F6',*/}
                    {/*                color: colorHeaderText*/}
                    {/*            }}>*/}
                    {/*                Exporter Rapport*/}
                    {/*            </Button>*/}
                    {/*        </Col>*/}
                    {/*        <Col flex={1} style={{display: 'contents'}}>*/}
                    {/*            <Select value={"Cette Semaine"}*/}
                    {/*                    style={{*/}
                    {/*                        fontFamily: 'Montserrat, sans-serif',*/}
                    {/*                        color: colorHeaderText,*/}
                    {/*                        height: 'fit-content',*/}
                    {/*                        fontSize: '12px'*/}
                    {/*                    }}>*/}
                    {/*            </Select>*/}
                    {/*        </Col>*/}
                    {/*    </div>*/}
                    {/*    <div style={{height: '350px', justifyContent: 'center', display: 'flex'}}>*/}
                    {/*        <canvas style={{fontFamily: 'Montserrat, sans-serif'}} ref={chartRef1}></canvas>*/}
                    {/*    </div>*/}
                    {/*</Card>*/}
                </div>
            ) : (
                <div style={{
                    fontFamily: 'Montserrat, sans-serif',
                    color: colorHeaderText,
                    fontWeight: "500",
                    marginTop: '24px'
                }}>
                    <h1 style={{fontSize: '21px'}}>Accueil</h1>
                    <h2 style={{fontSize: '14px', marginTop: '10px'}}>Bienvenue sur votre Dashboard !</h2>
                    <div className={"test5"} style={{marginTop: '30px', display: 'none'}}>
                        <div>
                            <Row gutter={16}>
                                <Col style={{width: '25%'}}>
                                    <Card style={{width: '100%'}}>
                                        <Row>
                                            <Col style={{width: '75%'}}>
                                                <Row>
                                                    <p style={{
                                                        width: '100%',
                                                        fontSize: '16px',
                                                        fontFamily: 'Montserrat, sans-serif',
                                                        color: colorHeaderText
                                                    }}><b>Mon profil</b></p>
                                                </Row>
                                            </Col>
                                            <Col style={{
                                                width: '25%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: "flex"
                                            }}>
                                                <FontAwesomeIcon icon={faUser} size={'2x'}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col style={{width: '25%'}}>
                                    <Card>
                                        <Row>
                                            <Col style={{width: '75%'}}>
                                                <p style={{
                                                    fontSize: '16px',
                                                    width: '100%',
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}><b>Transactions</b></p>
                                            </Col>
                                            <Col style={{
                                                width: '25%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: "flex"
                                            }}>
                                                <FontAwesomeIcon icon={faBagShopping} size={'2x'}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col style={{width: '25%'}}>
                                    <Card>
                                        <Row>
                                            <Col style={{width: '75%'}}>
                                                <p style={{
                                                    fontSize: '16px',
                                                    width: '100%',
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}><b>Messagerie</b></p>
                                            </Col>
                                            <Col style={{
                                                width: '25%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: "flex"
                                            }}>
                                                <FontAwesomeIcon icon={faEnvelope} size={'2x'}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col style={{width: '25%'}}>
                                    <Card>
                                        <Row>
                                            <Col style={{width: '75%'}}>
                                                <p style={{
                                                    width: '100%',
                                                    fontSize: '16px',
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    color: colorHeaderText
                                                }}><b>Commandes</b></p>
                                            </Col>
                                            <Col style={{
                                                width: '25%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: "flex"
                                            }}>
                                                <FontAwesomeIcon icon={faTruckFast} size={'2x'}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                    </div>
                    <div style={{display: 'flex'}}>
                        <Card className={"test12"} style={{
                            borderRadius: '12px',
                            width: '100%',
                            margin: '20px 0',
                            padding: '15px',
                            height: '50vh'
                        }} bordered={false}>
                            <h2 style={{
                                fontFamily: 'Montserrat, sans-serif',
                                color: colorHeaderText,
                                fontSize: '18px'
                            }}>Dernières commandes</h2>
                            <div style={{
                                marginTop: '20px',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #FFFFFF',
                                borderRadius: '5px'
                            }}>
                                <div style={{marginTop: '10px', overflow: "hidden"}}>
                                    <style>
                                        {`
                          .custom-table tbody {
                            height: 20px;
                            overflow: hidden;
                          }
                        
                          .custom-table .ant-table-thead tr th,
                          .custom-table .ant-table-tbody tr td {
                            padding: 5px;
                          }
                        `}
                                    </style>
                                    <Table
                                        dataSource={dataSource.slice(-5, dataSource.length)}
                                        columns={columns}
                                        bordered
                                        pagination={false}
                                        className="custom-table"
                                        style={{margin: '0 20px', overflow: "hidden"}}
                                        // Set the desired height here (e.g., 400px)
                                    />
                                </div>
                                <div style={{margin: '20px'}}>
                                    <style>
                                        {`
                          .customPaginationStyle {
                             display: flex;
                             justify-content: center;
                             align-items: center;
                          }
                          
                          .customPaginationStyle : hover {
                             background-color : #000000
                          }
                        
                        `}
                                    </style>

                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            )
            }
        </div>
    );
}

