import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { getUserById, UserInterface } from '../User';
import { getProductByID } from '../Products/Products';
import { getExpeditionPriceById } from './index';
import { getAllOrders, Order } from '../Order';

interface dataSourceInterface {
    key: string;
    id: string;
    buyerName: string;
    date: string;
    state: string;
    buyerEmail: string;
    vendorName: string;
    priceTotal: number;
    productName: string;
}

function generateXLSXFile(data: dataSourceInterface[]) {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');
    const arrayBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'orders.xlsx';
    a.click();
}

function AllVendorOrders() {
    const [ordersInfos, setOrdersInfos] = useState({} as Order[]);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);

    useEffect(() => {
        const fetchOrdersInfos = async () => {
            const orders = await getAllOrders();
            setOrdersInfos(orders);
            setDataFetched(true);
            let newOption: dataSourceInterface;
            try {
                let mappedOptions: dataSourceInterface[] = [];
                for (const order of orders) {
                    const orderDate = new Date(order.creation_date * 1000);

                    // console.log(orders)
                    const buyerName = await getUserById(order.buyer_id);
                    if (buyerName) {
                        let vendorName = {} as UserInterface;
                        let vendorNames = '';
                        let productName = '';
                        let expeditionPrice = 0;
                        let priceTotal = 0;
                        for (const subOrder of order.sub_orders) {
                            const product = await getProductByID(subOrder.product_id);
                            productName = product.product_name;
                            const vendorinfo = await getUserById(product.vendor_id);
                            if (vendorinfo && !vendorNames.includes(vendorinfo.firstname + ' ' + vendorinfo.lastname)) {
                                vendorNames += vendorinfo.firstname + ' ' + vendorinfo.lastname + ', ';
                            }
                            priceTotal += subOrder.product_price_TTC;
                            expeditionPrice = await getExpeditionPriceById(subOrder.expedition_id) * 1.2;
                        }
                        if (order.sub_orders[0].state_id !== 7) {
                            newOption = {
                                key: order.id as unknown as string,
                                id: order.id as unknown as string,
                                buyerName: buyerName.firstname + ' ' + buyerName.lastname,
                                buyerEmail: buyerName.user_email,
                                date: new Date(order.creation_date * 1000).toDateString(),
                                state: order.sub_orders[0].state_id as unknown as string,
                                vendorName: vendorNames as unknown as string,
                                productName: productName,
                                priceTotal: (expeditionPrice).toFixed(2) as unknown as number,
                            };
                            mappedOptions.push(newOption);
                            setOptions(() => [...mappedOptions]);
                        }
                    }
                }
            } catch (error) {
                console.log(error, 'No user data found');
            }
        };

        if (!dataFetched) {
            fetchOrdersInfos();
        }
    }, [dataFetched]);

    const colorProductBtn = "#7ED957";
    const colorHeaderText = "#09154C";

    return (
        <div>
            <button style={{backgroundColor: colorProductBtn, color: colorHeaderText,padding:'10px',borderRadius:'5px'}} onClick={() => dataFetched ? generateXLSXFile(options) : ''}>Télécharger les livraisons du mois précédent</button>
        </div>
    );
}

export default AllVendorOrders;
