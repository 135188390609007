import React, { useState } from 'react';
import { Button, Divider, Form, Input, Modal } from 'antd';
import {toast, ToastContainer} from 'react-toastify';
import {ResetPassword, ResetPasswordWithOld} from "../../ForgottenPassword"; // Assuming toast is available for notifications

export default function Password() {
    const [form] = Form.useForm();
    const colorProductBtn = "#7ED957";
    const colorHeaderText = "#09154C";

    const user_id = localStorage.getItem("user_id")

    const validateCharacters = (value : any) => {
        return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value);
    };

    const handleSubmit = async (values : any) => {

        const { oldpassword, newpassword1, newpassword2 } = values;

        if (!validateCharacters(newpassword1) || !validateCharacters(newpassword2)) {
            toast.error('Le mot de passe doit contenir au moins 8 caractères avec une majuscule, une minuscule, un caractère spécial et un chiffre.');
            return;
        }

        if (newpassword1 !== newpassword2) {
            toast.error('Les deux mots de passe saisis ne sont pas identiques.');
            return;
        }

        try {
            if (user_id === null) {
                toast.error('Une erreur est survenue');
            } else {
                // Replace this with your API call
                await ResetPasswordWithOld(parseFloat(user_id),oldpassword, newpassword1);
            }
        } catch (error) {
            toast.error('Une erreur est survenue, veuillez réessayer plus tard.');
        }
    };



    return (
        <div>
            <ToastContainer />
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                style={{ maxWidth: '500px', margin: 'auto' }}
            >
                <Form.Item
                    label="Ancien mot de passe"
                    name="oldpassword"
                    rules={[{ required: true, message: 'Veuillez entrer votre ancien mot de passe.' }]}
                >
                    <Input.Password placeholder="Ancien mot de passe" />
                </Form.Item>

                <Form.Item
                    label="Nouveau mot de passe"
                    name="newpassword1"
                    rules={[{ required: true, message: 'Veuillez entrer un nouveau mot de passe.' }]}
                >
                    <Input.Password placeholder="Nouveau mot de passe" />
                </Form.Item>

                <Form.Item
                    label="Confirmation du nouveau mot de passe"
                    name="newpassword2"
                    rules={[{ required: true, message: 'Veuillez confirmer votre nouveau mot de passe.' }]}
                >
                    <Input.Password placeholder="Confirmation du nouveau mot de passe" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ backgroundColor: colorProductBtn, color: colorHeaderText }}>
                        Mettre à jour
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
