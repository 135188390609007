import * as XLSX from 'xlsx';
import { getAllUsers } from "../User";
import { getAddressesByUserId } from "../Address";

const ExportUsers = async () => {
    async function generateExcelFile(users: any[]) {
        const wsDataPromises = users.map(async (user) => {
            const addresses = await getAddressesByUserId(user.id);
            const phoneNumber = addresses.length > 0 ? addresses[0].phone_number : '';

            if (user.id && user.user_email && user.firstname && user.lastname) {
                return {
                    id: user.id,
                    user_email: user.user_email,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    phone_number: phoneNumber,
                    birthdate: new Date(user.birthdate * 1000).toLocaleDateString('fr-FR', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    }),
                };
            }
        });

        // Attendre que toutes les promesses soient résolues
        const wsData = await Promise.all(wsDataPromises);

        const ws = XLSX.utils.json_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Users");
        XLSX.writeFile(wb, "users.xlsx");
    }

    try {
        const users = await getAllUsers();
        console.log(users);
        await generateExcelFile(users);
    } catch (error) {
        console.error("Error exporting users:", error);
    }
};

export { ExportUsers };
