import {Col, Input, Row, message, Upload, Button, Divider, Checkbox, Form} from "antd";
import React, {useEffect, useState} from 'react';
import {UploadChangeParam} from 'antd/lib/upload';
import {UploadFile} from 'antd/lib/upload/interface';
import {createProduct} from "../../Products/Products";

const {Dragger} = Upload;

interface NumericInputProps {
    onChange?: (value: string) => void;
}

export default function Company() {
    const colorProductBtn = "#7ED957"
    const colorHeaderText = "#09154C"

    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [input3, setInput3] = useState('');
    const [input4, setInput4] = useState('');
    const [fileStatus, setFileStatus] = useState<'done' | 'error' | 'empty'>('empty');

    const beforeUpload = (file: UploadFile<any>): boolean => {
        const isPDF = file.type === 'application/pdf';
        if (!isPDF) {
            message.error('Seul les fichiers au format pdf sont autorisés !');
        }
        return isPDF;
    };

    const onChange = (info: UploadChangeParam<UploadFile<any>>) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} fichier enregistré avec succès.`);
            setFileStatus('done');
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} enregistrement du fichier échoué.`);
            setFileStatus('error');
        }
    };

    const handleInput1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput1(e.target.value);
    };


    const handleInput2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        // Remove any non-numeric characters
        const numericValue = value.replace(/\D/g, '');

        // Truncate the value to a maximum of 16 characters
        const truncatedValue = numericValue.slice(0, 14);

        // Update the state with the truncated value
        setInput2(truncatedValue);
    };

    const handleButtonClick = () => {
        if (!input1.trim()) {
            message.error('Veuillez saisir le nom de l\'entreprise.');
            return;
        }

        if (!input2.trim()) {
            message.error('Veuillez saisir le Siret.');
            return;
        }

        // Check if input2 contains exactly 14 numbers
        if (input2.trim().length !== 14) {
            message.error('Le Siret doit contenir exactement 14 chiffres.');
            return;
        }

        if (fileStatus === 'empty') {
            message.error('Veuillez sélectionner un fichier PDF.');
            return;
        }
    };

    const handleInput3Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput3(e.target.value);
    };


    const handleInput4Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        // Remove any non-numeric characters
        const numericValue = value.replace(/\D/g, '');

        // Truncate the value to a maximum of 16 characters
        const truncatedValue = numericValue.slice(0, 14);

        // Update the state with the truncated value
        setInput4(truncatedValue);
    };

    const handleButtonClick2 = () => {
        // Email validation regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!input3.trim()) {
            message.error('Veuillez saisir votre adresse email.');
            return;
        }

        if (!emailRegex.test(input3.trim())) {
            message.error('Veuillez saisir une adresse email valide.');
            return;
        }

        if (!input4.trim()) {
            message.error('Veuillez saisir le Siret de l\'entreprise.');
            return;
        }

        // Check if input2 contains exactly 14 numbers
        if (input4.trim().length !== 14) {
            message.error('Le Siret doit contenir exactement 14 chiffres.');
            return;
        }
    };

    let compteur = 0

    // Use useState to keep track of checkbox states
    const [checkboxValues, setCheckboxValues] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        checkbox7: false,
        checkbox8: false,
    });

    // Function to update checkbox states
    const handleCheckboxChange = (name: string, value: boolean) => {
        setCheckboxValues({...checkboxValues, [name]: value});
    };

    // Check if all checkboxes are checked
    const allCheckboxesChecked = checkboxValues.checkbox1 && checkboxValues.checkbox2 && checkboxValues.checkbox3 && checkboxValues.checkbox4;
    const allCheckboxesChecked2 = checkboxValues.checkbox5 && checkboxValues.checkbox6 && checkboxValues.checkbox7 && checkboxValues.checkbox8;

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{color: colorHeaderText}}>
            {windowWidth < 768 ? (
                <div>
                    {compteur === 0 ? (
                        <div>
                            <h3>Créer une entreprise</h3>
                            <p style={{marginTop: '15px'}}>Vous pouvez réaliser une demande pour créer une
                                entreprise.</p>
                            <Divider/>
                            <div style={{marginTop: '20px'}}>
                                <Row>
                                    <Col flex={1}>
                                        <p style={{marginBottom: '10px'}}>Nom de l'entreprise</p>
                                        <Input placeholder={'Nom de l\'entreprise'} name='companyName' value={input1}
                                               onChange={handleInput1Change}/>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '20px'}}>
                                    <Col flex={1}>
                                        <p style={{marginBottom: '10px'}}>Siret</p>
                                        <Input placeholder={'Siret'} value={input2} name='companySiretCreate'
                                               onChange={handleInput2Change}/>
                                    </Col>
                                </Row>
                                <div style={{marginTop: "15px"}}>
                                    <p style={{marginBottom: '10px'}}>Kbis</p>
                                    <Dragger
                                        beforeUpload={beforeUpload}
                                        onChange={onChange}
                                        showUploadList={false}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <i className="icons8-pdf"></i>
                                        </p>
                                        <p className="ant-upload-text">Cliquez ou déposez un fichier PDF</p>
                                        <p className="ant-upload-hint">Format PDF seulement autorisé</p>
                                    </Dragger>
                                </div>
                                <div style={{marginTop: "15px", justifyContent: 'right', display: 'flex'}}>
                                    <Button onClick={handleButtonClick}
                                            style={{backgroundColor: colorProductBtn, color: colorHeaderText}}>Faire une
                                        demande</Button>
                                </div>
                            </div>
                            <Divider/>
                            <h3>Rejoindre une entreprise</h3>
                            <p style={{marginTop: '15px'}}>Vous pouvez réaliser une demande pour rejoindre une
                                entreprise.</p>
                            <Divider/>
                            <div style={{marginTop: '20px'}}>
                                <Row>
                                    <Col flex={1}>
                                        <p style={{marginBottom: '10px'}}>Email de votre compte</p>
                                        <Input placeholder={'Email de votre compte'} name='mail' value={input3}
                                               onChange={handleInput3Change}/>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '20px'}}>
                                    <Col flex={1}>
                                        <p style={{marginBottom: '10px'}}>Siret de l'entreprise</p>
                                        <Input placeholder={'Siret de l\'entreprise'} name='siretCompanyJoin'
                                               value={input4} onChange={handleInput4Change}/>
                                    </Col>
                                </Row>
                                <div style={{marginTop: "15px", justifyContent: 'right', display: 'flex'}}>
                                    <Button onClick={handleButtonClick2}
                                            style={{backgroundColor: colorProductBtn, color: colorHeaderText}}>Faire une
                                        demande</Button>
                                </div>
                            </div>
                        </div>
                    ) : compteur === 1 ? (
                        <div>
                            <div style={{marginTop: '20px'}}>
                                <h2 style={{textAlign: 'center'}}>Vous avez une demande en attente, veuillez attendre
                                    une réponse d'un administrateur</h2>
                                <h3 style={{textAlign: 'center', marginTop: '10px'}}>Vous recevrez un mail dès lors
                                    qu'une réponse vous sera donnée</h3>
                            </div>
                        </div>
                    ) : compteur === 2 ? (
                        <div>
                            <div style={{marginTop: '20px'}}>
                                <h2 style={{textAlign: 'center'}}>Vous avez une demande en attente, veuillez attendre
                                    une réponse du gérant de l'entreprise</h2>
                                <h3 style={{textAlign: 'center', marginTop: '10px'}}>Vous recevrez un mail dès lors
                                    qu'une réponse vous sera donnée</h3>
                            </div>
                        </div>
                    ) : compteur === 3 ? (
                        <div>
                            <h3>Supprimer une entreprise</h3>
                            <p style={{marginTop: '15px'}}>Vous pouvez supprimer votre entreprise en acceptant les
                                conditions ci-dessous.</p>
                            <Divider/>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={1}
                                          onChange={(e) => handleCheckboxChange('checkbox1', e.target.checked)}>
                                    <p style={{width: '90%'}}>J'accepte de quitter mon entreprise en cliquant sur ce
                                        bouton.</p>
                                </Checkbox>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={2}
                                          onChange={(e) => handleCheckboxChange('checkbox2', e.target.checked)}>
                                    <p style={{width: '90%'}}>J'accepte que tous mes collaborateurs actuellement
                                        présents dans mon entreprise soit également supprimés de cette entreprise.</p>
                                </Checkbox>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={3}
                                          onChange={(e) => handleCheckboxChange('checkbox3', e.target.checked)}>
                                    <p style={{width: '90%'}}>J'accepte que tous mes produits ainsi que ceux de mes
                                        collaborateurs passent en statut "privé".</p>
                                </Checkbox>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={4}
                                          onChange={(e) => handleCheckboxChange('checkbox4', e.target.checked)}>
                                    <p style={{width: '90%'}}>J'accepte de perdre le statut "professionnel" ainsi que
                                        tous ses avantages.</p>
                                </Checkbox>
                            </Row>
                            {/* Disable the button if all checkboxes are not checked */}
                            <Button
                                disabled={!allCheckboxesChecked}
                                style={{
                                    backgroundColor: allCheckboxesChecked ? colorProductBtn : "#E5E5E5",
                                    color: allCheckboxesChecked ? colorHeaderText : "#BFBFBF",
                                    marginLeft: '-17px'
                                }}
                            >
                                <p>Supprimer mon entreprise</p>
                            </Button>
                        </div>
                    ) : compteur === 4 ? (
                        <div>
                            <h3>Quitter une entreprise</h3>
                            <p style={{marginTop: '15px'}}>Vous pouvez quitter votre entreprise en acceptant les
                                conditions ci-dessous.</p>
                            <Divider/>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={1}
                                          onChange={(e) => handleCheckboxChange('checkbox5', e.target.checked)}>
                                    <p style={{width: '90%'}}>J'accepte de quitter mon entreprise en cliquant sur ce
                                        bouton.</p>
                                </Checkbox>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={2}
                                          onChange={(e) => handleCheckboxChange('checkbox6', e.target.checked)}>
                                    <p style={{width: '90%'}}>J'accepte que mon supérieur soit mentionné par mail de mon
                                        retrait dans cette entreprise.</p>
                                </Checkbox>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={3}
                                          onChange={(e) => handleCheckboxChange('checkbox7', e.target.checked)}>
                                    <p style={{width: '90%'}}>J'accepte que tous mes produits passent en statut
                                        "privé".</p>
                                </Checkbox>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={4}
                                          onChange={(e) => handleCheckboxChange('checkbox8', e.target.checked)}>
                                    <p style={{width: '90%'}}>J'accepte de perdre le statut "professionnel" ainsi que
                                        tous ses avantages.</p>
                                </Checkbox>
                            </Row>
                            {/* Disable the button if all checkboxes are not checked */}
                            <Button
                                disabled={!allCheckboxesChecked2}
                                style={{
                                    backgroundColor: allCheckboxesChecked2 ? colorProductBtn : "#E5E5E5",
                                    color: allCheckboxesChecked2 ? colorHeaderText : "#BFBFBF",
                                    marginLeft: '-17px'
                                }}
                            >
                                Supprimer mon entreprise
                            </Button>
                        </div>
                    ) : null
                    }
                </div>
            ) : (
                <div>
                    {compteur === 0 ? (
                        <div>
                            <h3>Créer une entreprise</h3>
                            <p style={{marginTop: '15px'}}>Vous pouvez réaliser une demande pour créer une
                                entreprise.</p>
                            <Divider/>
                            <div style={{marginTop: '20px'}}>
                                <Form id={"form_mobile_style"}>
                                    <Row>
                                        <Col flex={1} style={{marginRight: '10px'}}>
                                            <p style={{marginBottom: '10px'}}>Nom de l'entreprise</p>
                                            <Input placeholder={'Nom de l\'entreprise'} name='companyName'
                                                   value={input1} onChange={handleInput1Change}/>
                                        </Col>
                                        <Col flex={1} style={{marginLeft: '10px'}}>
                                            <p style={{marginBottom: '10px'}}>Siret</p>
                                            <Input placeholder={'Siret'} value={input2} name='companySiretCreate'
                                                   onChange={handleInput2Change}/>
                                        </Col>
                                    </Row>
                                    <div style={{marginTop: "15px"}}>
                                        <p style={{marginBottom: '10px'}}>Kbis</p>
                                        <Dragger
                                            beforeUpload={beforeUpload}
                                            onChange={onChange}
                                            showUploadList={false}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <i className="icons8-pdf"></i>
                                            </p>
                                            <p className="ant-upload-text">Cliquez ou déposez un fichier PDF</p>
                                            <p className="ant-upload-hint">Format PDF seulement autorisé</p>
                                        </Dragger>
                                    </div>
                                    <div style={{marginTop: "15px", justifyContent: 'right', display: 'flex'}}>
                                        <Button onClick={handleButtonClick}
                                                style={{backgroundColor: colorProductBtn, color: colorHeaderText}}>Faire
                                            une demande</Button>
                                    </div>
                                </Form>
                            </div>
                            <Divider/>
                            <h3>Rejoindre une entreprise</h3>
                            <p style={{marginTop: '15px'}}>Vous pouvez réaliser une demande pour rejoindre une
                                entreprise.</p>
                            <Divider/>
                            <div style={{marginTop: '20px'}}>
                                <Row>
                                    <Col flex={1} style={{marginRight: '10px'}}>
                                        <p style={{marginBottom: '10px'}}>Email de votre compte</p>
                                        <Input placeholder={'Email de votre compte'} name='mail' value={input3}
                                               onChange={handleInput3Change}/>
                                    </Col>
                                    <Col flex={1} style={{marginLeft: '10px'}}>
                                        <p style={{marginBottom: '10px'}}>Siret de l'entreprise</p>
                                        <Input placeholder={'Siret de l\'entreprise'} name='siretCompanyJoin'
                                               value={input4} onChange={handleInput4Change}/>
                                    </Col>
                                </Row>
                                <div style={{marginTop: "15px", justifyContent: 'right', display: 'flex'}}>
                                    <Button onClick={handleButtonClick2}
                                            style={{backgroundColor: colorProductBtn, color: colorHeaderText}}>Faire une
                                        demande</Button>
                                </div>
                            </div>
                        </div>
                    ) : compteur === 1 ? (
                        <div>
                            <div style={{marginTop: '20px'}}>
                                <h2 style={{textAlign: 'center'}}>Vous avez une demande en attente, veuillez attendre
                                    une réponse d'un administrateur</h2>
                                <h3 style={{textAlign: 'center', marginTop: '10px'}}>Vous recevrez un mail dès lors
                                    qu'une réponse vous sera donnée</h3>
                            </div>
                        </div>
                    ) : compteur === 2 ? (
                        <div>
                            <div style={{marginTop: '20px'}}>
                                <h2 style={{textAlign: 'center'}}>Vous avez une demande en attente, veuillez attendre
                                    une réponse du gérant de l'entreprise</h2>
                                <h3 style={{textAlign: 'center', marginTop: '10px'}}>Vous recevrez un mail dès lors
                                    qu'une réponse vous sera donnée</h3>
                            </div>
                        </div>
                    ) : compteur === 3 ? (
                        <div>
                            <h3>Supprimer une entreprise</h3>
                            <p style={{marginTop: '15px'}}>Vous pouvez supprimer votre entreprise en acceptant les
                                conditions ci-dessous.</p>
                            <Divider/>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={1}
                                          onChange={(e) => handleCheckboxChange('checkbox1', e.target.checked)}>
                                    J'accepte de quitter mon entreprise en cliquant sur ce bouton.
                                </Checkbox>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={2}
                                          onChange={(e) => handleCheckboxChange('checkbox2', e.target.checked)}>
                                    J'accepte que tous mes collaborateurs actuellement présents dans mon entreprise soit
                                    également supprimés de cette entreprise.
                                </Checkbox>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={3}
                                          onChange={(e) => handleCheckboxChange('checkbox3', e.target.checked)}>
                                    J'accepte que tous mes produits ainsi que ceux de mes collaborateurs passent en
                                    statut "privé".
                                </Checkbox>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={4}
                                          onChange={(e) => handleCheckboxChange('checkbox4', e.target.checked)}>
                                    J'accepte de perdre le statut "professionnel" ainsi que tous ses avantages.
                                </Checkbox>
                            </Row>
                            {/* Disable the button if all checkboxes are not checked */}
                            <Button
                                disabled={!allCheckboxesChecked}
                                style={{
                                    backgroundColor: allCheckboxesChecked ? colorProductBtn : "#E5E5E5",
                                    color: allCheckboxesChecked ? colorHeaderText : "#BFBFBF",
                                }}
                            >
                                Supprimer mon entreprise
                            </Button>
                        </div>
                    ) : compteur === 4 ? (
                        <div>
                            <h3>Quitter une entreprise</h3>
                            <p style={{marginTop: '15px'}}>Vous pouvez quitter votre entreprise en acceptant les
                                conditions ci-dessous.</p>
                            <Divider/>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={1}
                                          onChange={(e) => handleCheckboxChange('checkbox5', e.target.checked)}>
                                    J'accepte de quitter mon entreprise en cliquant sur ce bouton.
                                </Checkbox>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={2}
                                          onChange={(e) => handleCheckboxChange('checkbox6', e.target.checked)}>
                                    J'accepte que mon supérieur soit mentionné par mail de mon retrait dans cette
                                    entreprise.
                                </Checkbox>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={3}
                                          onChange={(e) => handleCheckboxChange('checkbox7', e.target.checked)}>
                                    J'accepte que tous mes produits passent en statut "privé".
                                </Checkbox>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Checkbox value={4}
                                          onChange={(e) => handleCheckboxChange('checkbox8', e.target.checked)}>
                                    J'accepte de perdre le statut "professionnel" ainsi que tous ses avantages.
                                </Checkbox>
                            </Row>
                            {/* Disable the button if all checkboxes are not checked */}
                            <Button
                                disabled={!allCheckboxesChecked2}
                                style={{
                                    backgroundColor: allCheckboxesChecked2 ? colorProductBtn : "#E5E5E5",
                                    color: allCheckboxesChecked2 ? colorHeaderText : "#BFBFBF",
                                }}
                            >
                                Supprimer mon entreprise
                            </Button>
                        </div>
                    ) : null
                    }
                </div>
            )
            }
        </div>
    )
}
