import React, {useEffect, useState} from 'react';
import {Statistic, Row, Col, Card, MenuProps, Layout, Menu, Breadcrumb, theme} from 'antd';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DesktopOutlined, FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Chart, ChartOptions, registerables} from 'chart.js';
import {Bar, Doughnut} from "react-chartjs-2";
import {getAllOrders, Order, SubOrders} from "../Order";
import Sider from "antd/es/layout/Sider";
import {Content, Footer, Header} from "antd/es/layout/layout";

Chart.register(...registerables);
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

const items: MenuItem[] = [
    getItem('Global', '1', <PieChartOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>),
    getItem('Vendeurs', 'sub1', <UserOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>, [
        getItem('Fairbanks', '2'),
    ]),
];

interface OldOrder {
    DATE: string;
    'NÂ° commande': number;
    NOM: string;
    EMAIL: string;
    REFERENCE: string;
    DESIGNATION: string;
    'QUANTITÃ‰': number;
    'PRIX DE VENTE U HT': number;
    'PRIX DE VENTE TOTAL HT': number;
    'COMMISSION HT': number;
    'TVA 20% SUR LA COMMISSION': number;
    'COMMISSION TTC': number;
    'SOLDE RECUPERABLE HT': number;
    'SOLDE RECUPERABLE TTC': number;
}

const KPI: React.FC = () => {
    const [orders, setOrders] = useState<OldOrder[]>([]);
    const [topProducts2022, setTopProducts2022] = useState<{ name: string, quantity: number }[]>([]);
    const [topProducts2023, setTopProducts2023] = useState<{ name: string, quantity: number }[]>([]);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [totalProductsSold2022, setTotalProductsSold2022] = useState<number>(0);
    const [totalProductsSold2023, setTotalProductsSold2023] = useState<number>(0);

    const [dataFetched, setDataFetched] = useState(false)
    const formatDate = (date: Date): string => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        if (!dataFetched) {

            fetch('http://localhost:3000/kpi.json')
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                // .then(data => {
                //     const reformattedOrders = data.map((data: { DESIGNATION: string; }) => ({
                //         ...data,
                //         DESIGNATION: data.DESIGNATION.replace(/Ã/g, 'à').replace(/¨/g, 'è').replace(/©/g, 'é').replace(/´/g, 'ô').replace(/¾/g, 'û').replace(/½/g, 'û').replace(//g, 'ô').replace(/Œ/g, 'œ').replace(/™/g, 'ô'),
                //     }));
                //     setOrders(reformattedOrders);
                // })
                .then(data => {

                    const orders2022 = data.filter((order: OldOrder) => order.DATE.includes('2023'));
                    const orders2023 = data.filter((order: OldOrder) => order.DATE.includes('2024'));

                    const calculateTotalProductsSold = (orders: OldOrder[]) => {
                        return orders.reduce((acc, order) => acc + parseFloat(order['QUANTITÃ‰'] as unknown as string), 0);
                    };

                    setOrders(data);
                    setTotalOrders(data.length);
                    setTotalProductsSold2022(calculateTotalProductsSold(orders2022));
                    setTotalProductsSold2023(calculateTotalProductsSold(orders2023));
                    // Récupérer les nouvelles commandes et les mapper vers le même format que les commandes existantes
                    getAllOrders().then((newOrders: Order[]) => {
                        const filteredNewOrders: Order[] = newOrders.filter((newOrder: Order) => {
                            return newOrder.sub_orders.some((subOrder: SubOrders) => {
                                return (subOrder.state_id === 3 || subOrder.state_id === 4);
                            });
                        });
                        const mappedNewOrders: OldOrder[] = filteredNewOrders.map((newOrder: Order) => {
                            // Mapper chaque sous-commande pour obtenir les détails du produit
                            const subOrdersDetails = newOrder.sub_orders.map((subOrder: SubOrders) => ({
                                name: subOrder.product.product_name,
                                quantity: subOrder.quantity,
                                price: subOrder.product_price_HT,
                                // Ajoutez d'autres détails du produit si nécessaire
                            }));

                            // Calculez le total des quantités et des prix des sous-commandes
                            const totalQuantity = subOrdersDetails.reduce((acc, subOrder) => acc + subOrder.quantity, 0);
                            const totalPrice = subOrdersDetails.reduce((acc, subOrder) => acc + subOrder.price, 0);
                            const formattedDate = formatDate(new Date(newOrder.creation_date * 1000));

                            // Retournez les détails de la commande mappée
                            return {
                                DATE: formattedDate, // Adapter la date au format requis
                                'NÂ° commande': newOrder.id,
                                NOM: '', // Complétez ces champs avec des valeurs appropriées si nécessaire
                                EMAIL: '',
                                REFERENCE: '',
                                DESIGNATION: subOrdersDetails[0].name, // Remplissez ces champs si nécessaire
                                'QUANTITÃ‰': totalQuantity,
                                'PRIX DE VENTE U HT': totalPrice / totalQuantity, // Calculez le prix de vente unitaire
                                'PRIX DE VENTE TOTAL HT': totalPrice,
                                'COMMISSION HT': totalPrice - (totalPrice / 1.1), // Ajoutez d'autres calculs si nécessaire
                                'TVA 20% SUR LA COMMISSION': (totalPrice - (totalPrice / 1.1)) - ((totalPrice - (totalPrice / 1.1)) / 1.2),
                                'COMMISSION TTC': (totalPrice - (totalPrice / 1.1)) * 1.2,
                                'SOLDE RECUPERABLE HT': (totalPrice / 1.1),
                                'SOLDE RECUPERABLE TTC': (totalPrice / 1.1) * 1.2,
                            };
                        });

                        // Concaténez les nouvelles commandes mappées avec les commandes existantes
                        const allOrders = [...data, ...mappedNewOrders.reverse()];
                        setOrders(allOrders);
                    });

                    setDataFetched(true);
                }).catch(error => {
                console.error('There was a problem fetching the data:', error);
            });
        }
    }, []);

    const calculateTotal = (data: OldOrder[], key: keyof OldOrder) => {
        return data.reduce((acc, order) => {
            const value = parseFloat(order[key] as string);
            return acc + value;
        }, 0);
    };

    const calculatePercentageChange = (value2022: number, value2023: number) => {
        if (value2022 === 0 && value2023 === 0) {
            return 0;
        } else if (value2022 === 0) {
            return 100;
        } else {
            return ((value2023 - value2022) / value2022) * 100;
        }
    };

    const orders2022 = orders.filter(order => order.DATE.includes('2023'));
    const orders2023 = orders.filter(order => order.DATE.includes('2024'));
    const ordersPercentageChange = ((orders2023.length - orders2022.length) / orders2022.length) * 100
    const totalSoldeRecuperableHT2022 = calculateTotal(orders2022, 'SOLDE RECUPERABLE HT').toFixed(2) as unknown as number;
    const totalSoldeRecuperableHT2023 = calculateTotal(orders2023, 'SOLDE RECUPERABLE HT').toFixed(2) as unknown as number;
    const soldeRecuperableHTPercentageChange = calculatePercentageChange(totalSoldeRecuperableHT2022, totalSoldeRecuperableHT2023).toFixed(2) as unknown as number;

    const totalCommissionHT2022 = calculateTotal(orders2022, 'COMMISSION HT').toFixed(2) as unknown as number;
    const totalCommissionHT2023 = calculateTotal(orders2023, 'COMMISSION HT').toFixed(2) as unknown as number;
    const commissionHTPercentageChange = calculatePercentageChange(totalCommissionHT2022, totalCommissionHT2023).toFixed(2) as unknown as number;


    // Grouper les données par mois
    const groupedData2022: { [month: string]: { 'COMMISSION HT': number, 'SOLDE RECUPERABLE HT': number } } = {};
    orders2022.forEach(order => {
        const [day, month, year] = order.DATE.split('/'); // Découper la date en jour, mois et année
        const formattedMonth = `${year}-${month.padStart(2, '0')}`; // Formatage du mois (YYYY-MM)
        if (!groupedData2022[formattedMonth]) {
            groupedData2022[formattedMonth] = {'COMMISSION HT': 0, 'SOLDE RECUPERABLE HT': 0};
        }
        groupedData2022[formattedMonth]['COMMISSION HT'] += order['COMMISSION HT'];
        groupedData2022[formattedMonth]['SOLDE RECUPERABLE HT'] += order['SOLDE RECUPERABLE HT'];
    });

    // Préparer les données pour le graphique
    const chartData2022 = {
        labels: Object.keys(groupedData2022),
        datasets: [{
            label: 'Commission HT',
            data: Object.values(groupedData2022).map(data => data['COMMISSION HT']),
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
        }, {
            label: 'Solde Récupérable HT',
            data: Object.values(groupedData2022).map(data => data['SOLDE RECUPERABLE HT']),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
        }]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
    };
    const donutOptions: ChartOptions<'doughnut'> = {
        plugins: {
            legend: {
                position: 'right',
            },
        },
    };


    // Grouper les données par mois
    const groupedData2023: { [month: string]: { 'COMMISSION HT': number, 'SOLDE RECUPERABLE HT': number } } = {};
    orders2023.forEach(order => {
        const [day, month, year] = order.DATE.split('/'); // Découper la date en jour, mois et année
        const formattedMonth = `${year}-${month.padStart(2, '0')}`; // Formatage du mois (YYYY-MM)
        if (!groupedData2023[formattedMonth]) {
            groupedData2023[formattedMonth] = {'COMMISSION HT': 0, 'SOLDE RECUPERABLE HT': 0};
        }
        groupedData2023[formattedMonth]['COMMISSION HT'] += order['COMMISSION HT'];
        groupedData2023[formattedMonth]['SOLDE RECUPERABLE HT'] += order['SOLDE RECUPERABLE HT'];
    });

    // Préparer les données pour le graphique
    const chartData2023 = {
        labels: Object.keys(groupedData2023),
        datasets: [{
            label: 'Commission HT',
            data: Object.values(groupedData2023).map(data => data['COMMISSION HT']),
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
        }, {
            label: 'Solde Récupérable HT',
            data: Object.values(groupedData2023).map(data => data['SOLDE RECUPERABLE HT']),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
        }]
    };
    useEffect(() => {
        // Compter le nombre d'occurrences de chaque produit
        const productCounts: { [productName: string]: number } = {};
        orders2022.forEach(order => {
            if(order.DESIGNATION) {
                const productName = order.DESIGNATION;
                if (!productCounts[productName]) {
                    productCounts[productName] = 0;
                }
                productCounts[productName]++;
            }
        });

        // Trier les produits par nombre d'occurrences et sélectionner les dix premiers
        const sortedProducts = Object.entries(productCounts)
            .sort(([, countA], [, countB]) => countB - countA)
            .slice(0, 10)
            .map(([name, quantity]) => ({name, quantity}));

        setTopProducts2022(sortedProducts);
    }, [orders2022]);

    const data2022 = {
        labels: topProducts2022.map(product => product.name),
        datasets: [
            {
                label: 'Top Ten Products Purchased in 2023',
                data: topProducts2022.map(product => product.quantity),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                    'rgba(231, 233, 237, 0.5)',
                    'rgba(166, 202, 240, 0.5)',
                    'rgba(161, 198, 198, 0.5)',
                    'rgba(176, 214, 219, 0.5)'
                ],
                borderWidth: 1,
            },
        ],
    };
    useEffect(() => {
        // Compter le nombre d'occurrences de chaque produit
        const productCounts: { [productName: string]: number } = {};
        orders2023.forEach(order => {
            if(order.DESIGNATION){
            const productName = order.DESIGNATION;
            if (!productCounts[productName]) {
                productCounts[productName] = 0;
            }
            productCounts[productName]++;
            }
        });

        // Trier les produits par nombre d'occurrences et sélectionner les dix premiers
        const sortedProducts = Object.entries(productCounts)
            .sort(([, countA], [, countB]) => countB - countA)
            .slice(0, 10)
            .map(([name, quantity]) => ({name, quantity}));

        setTopProducts2023(sortedProducts);
    }, [orders2023]);

    const data2023 = {
        labels: topProducts2023.map(product => product.name),
        datasets: [
            {
                label: 'Top Ten Products Purchased in 2024',
                data: topProducts2023.map(product => product.quantity),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                    'rgba(231, 233, 237, 0.5)',
                    'rgba(166, 202, 240, 0.5)',
                    'rgba(161, 198, 198, 0.5)',
                    'rgba(176, 214, 219, 0.5)'
                ],
                borderWidth: 1,
            },
        ],
    };

    const nbUser2022 = 10339
    const engamement2022 = "1min34"
    const tauxRebond2022 = 40.5
    const nbUser2023 = 62798
    const engamement2023 = "2min04"
    const tauxRebond2023 = 38.1
    const duration2022 = 1 * 60 + 34; // 1 minute 34 secondes
    const duration2023 = 2 * 60 + 4; // 2 minutes 04 secondes

// Calcul de la variation en secondes
    const differenceInSeconds = duration2023 - duration2022;

// Calcul de la variation relative en pourcentage
    const relativeChange = differenceInSeconds / duration2022;

// Conversion en pourcentage
    const percentageChange = relativeChange * 100;
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();
    const [selectedMenuKey, setSelectedMenuKey] = useState<string>('1'); // État pour stocker la clé de l'élément de menu sélectionné

    const handleMenuSelect = ({key}: { key: string }) => {
        setSelectedMenuKey(key);
    };

    const getContentForMenuKey = (key: string) => {
        switch (key) {
            case '1':
                return <Row gutter={4}><Col span={12}>
                    <Card title="Statistiques globales 2023">
                        <Card title="Nombre de visiteurs">
                            <Statistic
                                value={nbUser2022}
                            />
                            <Statistic
                                value={0}
                                suffix="%"
                                valueStyle={{color: "black", fontSize: 13}}
                            />
                        </Card>
                        <Card title="Durée d'engagement moyenne">
                            <Statistic
                                value={engamement2022}
                                precision={2}
                            />
                            <Statistic
                                value={0}
                                suffix="%"
                                valueStyle={{color: "black", fontSize: 13}}
                            />
                        </Card>
                        <Card title="Taux de rebond">
                            <Statistic
                                value={tauxRebond2022}
                                precision={2}
                            />
                            <Statistic
                                value={0}
                                suffix="%"
                                valueStyle={{color: "black", fontSize: 13}}
                            />
                        </Card>
                    </Card>
                </Col>
                    <Col span={12}>
                        <Card title="Statistiques globales 2024">
                            <Card title="Nombre de visiteurs">
                                <Statistic
                                    value={nbUser2023}
                                />
                                <Statistic
                                    value={((nbUser2023 - nbUser2022) / nbUser2023) * 100}
                                    suffix="%"
                                    prefix={<ArrowUpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                                    precision={2}
                                    valueStyle={{color: "green", fontSize: 13}}
                                />
                            </Card>
                            <Card title="Nombre d'utilisateurs">
                                <Statistic
                                    value={3540}
                                />
                                <Statistic
                                    value={0}
                                    suffix="%"
                                    valueStyle={{color: "black", fontSize: 13}}
                                />
                            </Card>

                            <Card title="Taux de conversion">
                                <Statistic
                                    value={((62798/3540)) }
                                    precision={2}
                                />
                            </Card>
                            <Card title="Nombre de vendeurs">
                                <Statistic
                                    value={57}
                                />
                                <Statistic
                                    value={0}
                                    suffix="%"
                                    valueStyle={{color: "black", fontSize: 13}}
                                />
                            </Card>
                            <Card title="Nombre de sessions par utilisateurs">
                                <Statistic
                                    value={1.48}
                                />
                                <Statistic
                                    value={0}
                                    suffix="%"
                                    valueStyle={{color: "black", fontSize: 13}}
                                />
                            </Card>
                            <Card title="Nombre d'affichages">
                                <Statistic
                                    value={316700}
                                />
                                <Statistic
                                    value={0}
                                    suffix="%"
                                    valueStyle={{color: "black", fontSize: 13}}
                                />
                            </Card>
                            <Card title="Durée d'engagement moyenne">
                                <Statistic
                                    value={engamement2023}
                                    precision={2}
                                />
                                <Statistic
                                    value={percentageChange}
                                    suffix="%"
                                    prefix={<ArrowUpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                                    precision={2}
                                    valueStyle={{color: "green", fontSize: 13}}
                                />
                            </Card>
                            <Card title="Taux de rebond">
                                <Statistic
                                    value={tauxRebond2023}
                                    precision={2}
                                />
                                <Statistic
                                    value={((tauxRebond2023 - tauxRebond2022) / tauxRebond2023) * 100}
                                    suffix="%"
                                    precision={2}
                                    valueStyle={{color: "red", fontSize: 13}}
                                />
                            </Card>
                            <Card title="Chiffre d'affaires">
                                <Statistic
                                    value={103000}
                                    precision={2}
                                />
                                <Statistic
                                    value={0}
                                    suffix="%"
                                    precision={2}
                                    valueStyle={{color: "black", fontSize: 13}}
                                />
                            </Card>
                        </Card>
                    </Col>
                </Row>;
            case '2':
                return  <Row gutter={4}><Col span={12}>
                    <Card title="F. 2023">
                        <Card title="Nombre de commandes">
                            <Statistic
                                value={orders2022.length}
                                precision={2}
                            />
                            <Statistic
                                value={0}
                                suffix="%"
                                valueStyle={{color: "black", fontSize: 13}}
                            />
                        </Card>
                        <Card title="Nombre de produits vendus">
                            <Statistic
                                value={totalProductsSold2022}
                                precision={2}
                            />
                            <Statistic
                                value={0}
                                suffix="%"
                                valueStyle={{color: "black", fontSize: 13}}
                                precision={2}
                            />
                        </Card>
                        <Card title="Total revenues">
                            <Statistic
                                value={totalSoldeRecuperableHT2022}
                                precision={2}
                                suffix="€"
                            />
                            <Statistic
                                value={0}
                                suffix="%"
                                valueStyle={{color: "black", fontSize: 13}}
                                precision={2}
                            />
                        </Card>
                        <Card title="Volume d'affaires">
                            <Statistic
                                value={parseFloat(String(totalSoldeRecuperableHT2022)) + parseFloat(String(totalCommissionHT2022))}
                                suffix="€"
                                precision={2}
                            />
                            <Statistic
                                value={0}
                                suffix="%"
                                valueStyle={{color: "black", fontSize: 13}}
                                precision={2}
                            />
                        </Card>
                        <Card title="Panier moyen">
                            <Statistic
                                value={totalSoldeRecuperableHT2022 / orders2022.length}
                                precision={2}
                                suffix="€"
                            />
                            <Statistic
                                value={0}
                                suffix="%"
                                valueStyle={{color: "black", fontSize: 13}}
                                precision={2}
                            />
                        </Card>
                        <Card title="Total commission">
                            <Statistic
                                value={totalCommissionHT2022}
                                precision={2}
                                suffix="€"
                            />
                            <Statistic
                                value={0}
                                suffix="%"
                                valueStyle={{color: "black", fontSize: 13}}
                                precision={2}
                            />
                        </Card>
                        <Bar data={chartData2022} options={options}/>
                        <Doughnut data={data2022} options={donutOptions}/>

                    </Card>
                </Col>
                    <Col span={12}>
                        <Card title="Fairbanks 2024">
                            <Card title="Nombre de commandes">
                                <Statistic
                                    value={orders2023.length}
                                />
                                <Statistic
                                    value={ordersPercentageChange}
                                    suffix="%"
                                    prefix={<ArrowUpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                                    valueStyle={{color: "green", fontSize: 13}}
                                    precision={2}
                                />
                            </Card>
                            <Card title="Nombre de produits vendus">
                                <Statistic
                                    value={totalProductsSold2023}
                                />
                                <Statistic
                                    value={((totalProductsSold2023 - totalProductsSold2022) / totalProductsSold2022) * 100}
                                    suffix="%"
                                    prefix={<ArrowUpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                                    valueStyle={{color: "green", fontSize: 13}}
                                    precision={2}
                                />
                            </Card>
                            <Card title="Total revenues">
                                <Statistic
                                    value={totalSoldeRecuperableHT2023}
                                    suffix="€"
                                    precision={2}
                                />
                                <Statistic
                                    value={soldeRecuperableHTPercentageChange}
                                    suffix="%"
                                    prefix={<ArrowUpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                                    valueStyle={{color: "green", fontSize: 13}}
                                    precision={2}
                                />
                            </Card>
                            <Card title="Volume d'affaires">
                                <Statistic
                                    value={parseFloat(String(totalSoldeRecuperableHT2023)) + parseFloat(String(totalCommissionHT2023))}
                                    suffix="€"
                                    precision={2}
                                />
                                <Statistic
                                    value={((totalSoldeRecuperableHT2023-totalSoldeRecuperableHT2022)/totalSoldeRecuperableHT2023)*100}
                                    suffix="%"
                                    prefix={<ArrowUpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                                    valueStyle={{color: "green", fontSize: 13}}
                                    precision={2}
                                />
                            </Card>
                            <Card title="Panier moyen">
                                <Statistic
                                    value={totalSoldeRecuperableHT2023 / orders2023.length}
                                    precision={2}
                                    suffix="€"
                                />
                                <Statistic
                                    value={((totalSoldeRecuperableHT2023 / orders2023.length - totalSoldeRecuperableHT2022 / orders2022.length) / (totalSoldeRecuperableHT2023 / orders2023.length)) * 100}
                                    suffix="%"
                                    prefix={<ArrowDownOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                                    valueStyle={{color: "red", fontSize: 13}}
                                    precision={2}
                                />
                            </Card>
                            <Card title="Total commission">
                                <Statistic
                                    value={totalCommissionHT2023}
                                    suffix="€"
                                    precision={2}
                                />
                                <Statistic
                                    value={commissionHTPercentageChange}
                                    suffix="%"
                                    prefix={<ArrowUpOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                                    valueStyle={{color: "green", fontSize: 13}}
                                    precision={2}
                                />
                            </Card>
                            <Bar data={chartData2023} options={options}/>
                            <Doughnut data={data2023} options={donutOptions}/>

                        </Card>
                    </Col>
                </Row>;
            case '3':
                return <div>Contenu pour Tom</div>;
            default:
                return <div>Aucun contenu sélectionné</div>;
        }
    };
    return (
        <>
            <Layout style={{minHeight: '100vh'}}>
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                    <div className="demo-logo-vertical"/>
                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} onSelect={handleMenuSelect}/>
                </Sider>
                <Layout>
                    <Header style={{padding: 0}}/>
                    <Content style={{margin: '0 16px'}}>
                        {getContentForMenuKey(selectedMenuKey)}
                    </Content>
                </Layout>
            </Layout>

        </>

    );
}


export default KPI;
